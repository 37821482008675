import React, { useContext, useEffect, useState } from 'react'
import { useGetProjectDetailsQuery, usePostTocDataMutation, useUpdateTocIsSavedMutation } from '../redux/slices/ProjectPageAPISlice';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ProjectStatusContext } from '../Providers/ProjectStatusProvider';
import { ProgressContext } from '../Providers/ProgressProvider';
import { getUserDetailsFromLocalStorage } from '../Utils/userUtils';
import { useParams } from 'react-router-dom';
import Footer from './TocChildren/Footer';
import PreviewHeader from './PreviewHeader';
import PreviewFooter from './PreviewFooter';
function StaticSlide(props) {
    const { projectId } = useParams()
    const { data: ProjectData, isLoading: isProjectDataLoading, isSuccess: isProjectDataSuccess } = useGetProjectDetailsQuery({ projectId: projectId });
    const [sector, setSector] = useState({})
    const getSectorData = async (id) => {
        try {
            var res = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/getsectorbyid/${id}`);
            setSector(res?.data?.data?.[0] || {})
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        if (ProjectData?.data) {
            getSectorData(ProjectData?.data?.sector_id)
        }
    }, [ProjectData])
    return (
        <>
            <div class="gp-preview-slide">
                <PreviewHeader sectorName={props?.sector?.name} from={props.projectData?.data?.study_from} to={props.projectData?.data?.study_to} />
                <div class="gp-preview-slide-body">
                    <img src={props?.slide}
                        alt="ck-edited-image" />
                    <p class="gp-mt-2 gp-sourceTxt">
                        Source: Frost & Sullivan</p>
                </div>
                <PreviewFooter pageNo={props?.pageNo} wipCode={props.projectData?.data?.wipcode} updatedAt={props.projectData?.data?.updated_at} />
            </div>
            
        </>
    )
}

export default StaticSlide