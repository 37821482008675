import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Button } from '@mui/material';

const DialogueBox = ({ 
    openDialog, 
    setOpenDialog, 
    dialogTitle, 
    dialogContentText, 
    handleConfirmation,
    handleClose,
    infoDialog=false
}) => {
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEnforceFocus 
      sx={{ '& .MuiDialog-paper': { width: '50%', maxWidth: '60%' } }} //
    >
      <DialogTitle id="alert-dialog-title" sx={{ fontSize: '2.5rem' }}>
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{ fontSize: '1.5rem' }}>
          {dialogContentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {infoDialog?null:<Button onClick={handleClose} color="primary" sx={{ fontSize: '1.5rem' }}>No</Button>}
        <Button onClick={() => {
          handleConfirmation();
          setOpenDialog(false); // Ensure dialog closes after confirmation
        }} color="primary" sx={{ fontSize: '1.5rem' }} autoFocus>
          {infoDialog?'Okay':'Yes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogueBox;