import React, { useEffect, useState, useContext } from "react"
import { useNavigate, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import './Styles/createProject.css'
import './Styles/Slides.css'
import axios from "axios";
import GrowthOpportunityUniverse from "./TocChildren/GrowthOpportunityUniverse";
import BestPracticeRecognition from "./TocChildren/BestPracticeRecognition";
import FrostRadar from "./TocChildren/FrostRadar";
import AddContributors from "./AddContributors";
import IndexSlide from "./IndexSlide";
import GrowthOppAnalysysSlide from "./GrowthOppAnalysysSlide";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
 faCircleInfo, faExpand, faLock, faLockOpen,faCheck
} from "@fortawesome/free-solid-svg-icons";
import DriversRestraints from '../DriversAndRestraints/DriversRestraints'
import 'bootstrap/dist/js/bootstrap.min.js';
import AddCustomers from "./AddCustomers";
import './Styles/OrgChart.css'
import { buildTree, getUserDetailsFromLocalStorage } from "../Utils/userUtils"
import ScopeAnalysis from "./TocChildren/ScopeAnalysis";
import StrategicImperative from "./TocChildren/StrategicImperative";
import CompetitiveEnvironment from "./TocChildren/CompetitiveEnvironment";
import KeyCompetitors from "./TocChildren/KeyCompetitors";
import GrowthMetrics from "./TocChildren/GrowthMetrics";
import GrowthUnitDrivers from "./TocChildren/GrowthUnitDrivers";
import GrowthUnitRestraints from "./TocChildren/GrowthUnitRestraints";
import GrowthPriceDrivers from "./TocChildren/GrowthPriceDrivers";
import GrowthPriceRestraints from "./TocChildren/GrowthPriceRestraints";
import GrowthRevenueDrivers from "./TocChildren/GrowthRevenueDrivers ";
import GrowthRevenueRestraints from "./TocChildren/GrowthRevenueRestraints";
import ChartsList from "./TocChildren/ChartsList";
import GoInBestPracticeRecognition from "./TocChildren/GoInBestPracticeRecognition";
import BenefitsImpacts from "./TocChildren/BenefitsImpacts";
import NextStepChild from "./TocChildren/NextStepChild";
import LegalDisclaimer from "./TocChildren/LegalDisclaimer";
import { useGetProjectDetailsQuery, useGetTocDataQuery, usePostTocDataMutation, usePostUpdateProjectStatusMutation } from "../redux/slices/ProjectPageAPISlice";
import AnalysisTocChild from "./TocChildren/AnalysisTocChild";
import SSOLoader from './SSOLoader';
import indexslide from "../images/staticslides/Slide_01.png";
import ecoslide from "../images/staticslides/Slide_08.jpg";
import transformslide from "../images/staticslides/Slide_03.jpg";
import ctoaslide from "../images/staticslides/Slide_12.jpg";
import segmentslide from "../images/staticslides/Slide_35.jpg";
import oppuniverseslide from "../images/staticslides/Slide_41.jpg";
import bestpracticeslide from "../images/staticslides/Slide_44.jpg";
import radarslide from "../images/staticslides/Slide_47.jpg";
import strategic_static_1 from "../images/staticslides/Slide_04.png";
import strategic_static_2 from "../images/staticslides/Slide_05.jpg";
import frostRadar from "../images/staticslides/Slide_48.jpg";
import BPRform from "../images/staticslides/Slide_05.jpg";
import benefitsImpacts from "../images/staticslides/Slide_50.jpg";
import nextStepChild from "../images/staticslides/Slide_51.jpg";
import legalDisclaimer from "../images/staticslides/Slide_53.jpg";
import takeNextStep from "../images/staticslides/Slide_52.jpg";
import { ProgressContext, ProgressProvider } from "../Providers/ProgressProvider";
import ProjectStatusBar from "./ProjectPage/ProjectStatusBar";
import { ProjectStatusContext, ProjectStatusProvider } from "../Providers/ProjectStatusProvider";
import StaticSlide from "./StaticSlide";
import ProjectDetails from "./ProjectPage/ProjectDetails";

function Notification() {

  const [btTocDatset, setBtTocDatset] = useState([])
  const { projectId } = useParams();
  const [headerIsEdit, setHeaderIsEdit] = useState(null);
  const [header, setHeader] = useState("");
  const [postTocData] = usePostTocDataMutation();
  const [loader, setLoader] = useState(false);
  const [initialSections, setInitialSections] = useState([])
  const [contributorsList, setContributorsList] = useState([])

  const { data, isLoading, isSuccess, isError, error } = useGetTocDataQuery({ projectId: projectId })
  useEffect(() => {
    if (isSuccess) {
      const tocData = (data?.data);
      setInitialSections(tocData.filter(item => item.isDeleted !== 1))
      const btTocData = buildTree(tocData.filter(item => item.isDeleted !== 1))
      setBtTocDatset(btTocData)
    }
    else if (isError) console.error(error)
  }, [isLoading, isSuccess, data])
  const { data: ProjectData, isLoading: isProjectDataLoading, isSuccess: isProjectDataSuccess } = useGetProjectDetailsQuery({ projectId: projectId });

  const [activeProjectStatus, setActiveProjectStatus] = useState(ProjectData?.data.status)
  const [returnProject, setReturnProject] = useState(null);
  useEffect(() => {
    if (isProjectDataSuccess) {
      setActiveProjectStatus(ProjectData?.data.status)
      setReturnProject(ProjectData?.data.return_status)
    }
  }, [isProjectDataLoading, isProjectDataSuccess, ProjectData])

  const headers = {
    Authorization: `Bearer ${document?.cookie?.split(';')?.find(token => token.startsWith(' $access_token'))?.split('=')[1]}`,
  }
  const [progressDetails, setProgressDetails] = useState({
    progress: 40,
    showPercentage: true,
    indeterminate: false
  })
  function CheckAllKeyValuePairs(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if ((typeof value === 'string' && value.trim().length === 0) || (typeof value === 'number' && isNaN(value))) {
          return false;
        }
      }
    }
    return true;
  }
  let initialData = {
    sector_id: '',
    title_name: '',
    wipcode: '',
    baseyear: '',
    study_from: '',
    study_to: '',
    currency: '',
    user_id: getUserDetailsFromLocalStorage()?.user_id,
    go_in_type: ''
  }
  const [createProject, setCreateProject] = useState(initialData)
  const [requiredError, setRequiredError] = useState(false)
  const [TOCAcc, setTOCAcc] = useState(false)

  const navigate = useNavigate();


  const [currentOpenIndex, setCurrentOpenIndex] = useState(0);
  const [lockTOC, setLockTOC] = useState(false)


  const componentMap = {
    "index": (props) => <IndexSlide slide={indexslide} {...props} />,
    "growth_env_eco": (props) => <IndexSlide slide={ecoslide} {...props} />,
    "tree_segment": (props) => <DriversRestraints  {...props} />,
    "growth_env_transformation": (props) => <IndexSlide slide={transformslide} {...props} />,
    "growth_env_comp_to_action": (props) => <IndexSlide slide={ctoaslide} {...props} />,
    "unknown": (props) => <GrowthOppAnalysysSlide  {...props} />,
    "segment_analysis_parent": (props) => <IndexSlide slide={segmentslide} {...props} />,
    "growth_opportunity_universe_head": (props) => <IndexSlide slide={oppuniverseslide} {...props} />,
    "best_practice_recognition": (props) => <IndexSlide slide={bestpracticeslide} {...props} />,
    "frost_radar_parent": (props) => <IndexSlide slide={radarslide} {...props} />,
    "next_step_index": (props) => <IndexSlide slide={oppuniverseslide} {...props} />,
    "": (props) => <IndexSlide slide={oppuniverseslide} {...props} />
  };

  const btTocChildMap = {
    "scope_analysis": (props) => <ScopeAnalysis  {...props} />,
    "strategic_imperative": (props) => <StrategicImperative  {...props} />,
    "competitive_env": (props) => <CompetitiveEnvironment  {...props} />,
    "key_comp": (props) => <KeyCompetitors  {...props} />,
    "segment_analysis_parent_growth_metrics": (props) => <GrowthMetrics  {...props} />,
    "segment_analysis_parent_growth_unit_drivers": (props) => <GrowthUnitDrivers  {...props} />,
    "segment_analysis_parent_growth_unit_driver_analysis": (props) => <AnalysisTocChild  {...props} />,
    "segment_analysis_parent_growth_unit_restraints": (props) => <GrowthUnitRestraints  {...props} />,
    "segment_analysis_parent_growth_unit_restraints_analysis": (props) => <AnalysisTocChild  {...props} />,
    "segment_analysis_parent_growth_price_driver": (props) => <GrowthPriceDrivers  {...props} />,
    "segment_analysis_parent_growth_price_driver_analysis": (props) => <AnalysisTocChild  {...props} />,
    "segment_analysis_parent_growth_price_restraints": (props) => <GrowthPriceRestraints  {...props} />,
    "segment_analysis_parent_growth_price_analysis": (props) => <AnalysisTocChild  {...props} />,
    "segment_analysis_parent_growth_revenue_driver": (props) => <GrowthRevenueDrivers  {...props} />,
    "segment_analysis_parent_growth_revenue_driver_analysis": (props) => <AnalysisTocChild  {...props} />,
    "segment_analysis_parent_growth_revenue_restaints": (props) => <GrowthRevenueRestraints  {...props} />,
    "segment_analysis_parent_growth_revenue_restaints_analysis": (props) => <AnalysisTocChild  {...props} />,
    "segment_analysis_parent_forecast_consideration": (props) => <AnalysisTocChild  {...props} />,
    "segment_analysis_parent_charts": (props) => <ChartsList  {...props} />,
    "go_in_award": (props) => <GoInBestPracticeRecognition  {...props} />,
    "frost_radar": (props) => <FrostRadar  {...props} />,
    "benefits_impacts": (props) => <BenefitsImpacts  {...props} />,
    "next_step_child": (props) => <NextStepChild  {...props} />,
    "legal_disclaimer": (props) => <LegalDisclaimer  {...props} />,
    "growth_opportunity_universe": (props) => <GrowthOpportunityUniverse {...props} />,
    "segment_analysis_growth_metrics": (props) => <GrowthMetrics  {...props} />,
    "segment_analysis_charts": (props) => <ChartsList  {...props} />,
    "segment_analysis_forecast_analysis": (props) => <AnalysisTocChild  {...props} />,
    "strategic_static_1":(props)=> <StaticSlide slide={strategic_static_1} {...props}  />,
    "strategic_static_2":(props)=> <StaticSlide slide={strategic_static_2} {...props}  />,
    "best_practice_recognition_form":(props)=> <StaticSlide slide={BPRform} {...props}  />,
    "frost_radar":(props)=> <StaticSlide slide={frostRadar} {...props}  />,
    "benefits_impacts":(props)=> <StaticSlide slide={benefitsImpacts} {...props}  />,
    "next_step_child":(props)=> <StaticSlide slide={nextStepChild} {...props}  />,
    "legal_disclaimer":(props)=> <StaticSlide slide={legalDisclaimer} {...props}  />,
    "take_next_step":(props)=> <StaticSlide slide={takeNextStep} {...props}  />,
  }

  const btTocDatsetComponent = (ele, index) => {

    return (
      <>
        {
          (componentMap[ele?.page_type]) ? <div key={index} id={`slide-${index}`}>{(componentMap[ele?.page_type])(ele)}</div> : null
        }
        {ele?.children?.map((val) => {
          if (btTocChildMap[val?.page_type]) {
            if (val?.page_type != "key_comp" || (val?.page_type == "key_comp" && val?.isView != 1)) {
              return (<div key={val?.id} id={`children-${val?.id}`}>{(btTocChildMap[val?.page_type] || null)(val)}</div>);
            }
          }
        })}
      </>
    )

  };


  const handleNextSlide = () => {
    if (currentOpenIndex < btTocDatset.length - 1) {
      setCurrentOpenIndex(currentOpenIndex + 1);
    }
  };

  const handlePreviousSlide = () => {
    if (currentOpenIndex > 0) {
      setCurrentOpenIndex(currentOpenIndex - 1);
    }
  };

  const handleScroll = (ele) => {
    const element = document.getElementById(`children-${ele?.id}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }


  const handleTOCClick = (index) => {
    setCurrentOpenIndex(index);
    TOCAccordian()
    setTOCAcc(true)
  };
  const TOCAccordian = () => {
    setTOCAcc(!TOCAcc)
  }

  const handleHeaderSave = async (ele) => {
    try {
      setLoader(true)
      await postTocData({
        projectId: ele?.go_in_id,
        propsId: ele?.id,
        body: {
          "userid": getUserDetailsFromLocalStorage()?.user_id,
          "description": ele?.description,
          "name": header,
        }
      }).then((res) => console.log(res))
      setHeaderIsEdit(null)
      setLoader(false)
      toast.success('Saved Successfully')
    } catch (error) {
      console.log(error);
      setLoader(false)
      toast.error("Something went wrong")
    }
  }
  const handleHeaderReset = () => {
    setHeader("")
    setHeaderIsEdit(null)
  }
  const HeaderEditMode = (ele, index) => {
    setHeader(ele?.name)
    setHeaderIsEdit(index)
  }
  const [postUpdateProjectStatus] = usePostUpdateProjectStatusMutation()
  const [openAddContributorsModal, setOpenAddContributorsModal] = useState(false);
  async function HandleUpdateForwardStatus(status, 
    selectedTab, fileUploaded,progressPercentage) {
    
    if (selectedTab === 1 && !fileUploaded) {
      toast.error("You selected Other models. Please upload atleast one Excel")
      return;
    }
    if(progressPercentage<100){
      toast.error("Complete all the sections before you send it to next stage")
      return;
    }
    try {
      postUpdateProjectStatus({
        projectId: projectId,
        body: {
          user_id: getUserDetailsFromLocalStorage().user_id,
          status: status,
          return_status: "",
        }
      })
      toast.success(`Project sent to the next stage`)
    } catch (error) {
      console.error(error)
      toast.error("Something went wrong")
    }
  }
  async function handleUpdateSendBackStatus(status, currentRole) {
    try {
      postUpdateProjectStatus({
        projectId: projectId,
        body: {
          user_id: getUserDetailsFromLocalStorage().user_id,
          status: status,
          return_status: currentRole,
        }
      })
      toast.success(`Project sent back to Analyst`)
    } catch (error) {
      console.error(error)
      toast.error("Something went wrong")
    }
  }

  const navigateToPPT = () => {
    navigate(`/pptview/${projectId}`, { replace: true })
  }

  useEffect(() => {
    const element = document.getElementById(`slide-${currentOpenIndex}`);
    if (element) {
      const elementTop = element.getBoundingClientRect().top;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const targetScroll = elementTop + scrollTop - window.innerHeight / 2 + 100;

      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth'
      });
    }
  }, [currentOpenIndex])

  const openModal = () => {
    setOpenAddContributorsModal(true);
  };

  // const fullScreen = ()=>{
  //   console.error('jjj');
    
  // }

  const closeModal = () => {
    setOpenAddContributorsModal(false);
  };

  return (
    <>
      <ProjectStatusProvider 
        activeProjectStage={activeProjectStatus} 
        returnStatus={returnProject} 
        role_type={ProjectData?.data?.role_type}
        >
        <ProjectStatusContext.Consumer>
          {
            ({ stageInfo, isProjectEditable, validations, selectedTab }) => {

              return <ProgressProvider initialSections={initialSections}>

                <ProgressContext.Consumer>
                  {
                    ({ calculateProgressPercentage }) => {
                      return <React.Fragment>
                        <div className='row'>
                          {loader ? <SSOLoader /> : null}
                          <div className="col-md-12 mb-5">
                            <div style={{ top: '51px' }} className="sticky-top">

                              <div className="bg-white py-2 d-flex justify-content-between align-items-center">
                                <h6 style={{ fontSize: '18px' }} className="mb-0">{ProjectData?.data?.title_name} &nbsp;--&nbsp;<span className="font" style={{ color: '#F7913D' }}>{btTocDatset[currentOpenIndex]?.name}</span></h6>
                                <div className="d-flex justify-content-between align-items-center">
                                  <button
                                    type="button"
                                    onClick={navigateToPPT}
                                    className="btn btn-sm btn-outline-primary mx-2 ">Full Screen &nbsp;
                                    <FontAwesomeIcon style={{ fontSize: '15px', color: '#0d6efd' }} icon={faExpand}  className="mt-1" />
                                  </button>
                                  <FontAwesomeIcon style={{ fontSize: '20px', color: '#0d6efd' }} icon={faCircleInfo} data-bs-toggle="modal" data-bs-target="#settingModal" className="" />
                                  {stageInfo?.roles.find((item) => item === ProjectData?.data?.role_type) &&
                                    <>
                                      <button
                                        type="button"
                                        onClick={() => HandleUpdateForwardStatus(stageInfo?.nextStage, selectedTab, validations?.fileUploaded,calculateProgressPercentage())}
                                        className="btn btn-sm btn-outline-primary mx-2 ">
                                        <i className="fa fa-send-o"></i> {stageInfo?.forwardStatusText}
                                      </button>

                                      {stageInfo?.canSendBack &&
                                        <>
                                          <button
                                            type="button"
                                            onClick={() => handleUpdateSendBackStatus(stageInfo?.sendBackKeyword, stageInfo?.value)}
                                            className="btn btn-sm btn-outline-primary mx-2">

                                            <i className="fa fa-send-o"></i> {stageInfo?.sendBackText}
                                          </button>
                                        </>
                                      }

                                    </>
                                  }
                                  <button
                                    type="button"
                                    onClick={() => navigate(`/projectview/${projectId}`, { replace: true })}
                                    className="btn btn-sm btn-outline-primary mx-2">

                                    <i className="fa fa-eye" aria-hidden="true"></i> Preview
                                  </button>
                                  <div className="progress-circle me-2" style={{ '--percentage': calculateProgressPercentage() * 3.6 + 'deg' }}>
                                    <span className="percentage">{calculateProgressPercentage()}%</span>
                                  </div>
                                </div>

                              </div>
                            </div>
                            <div className="accordion mb-3 custom-form" id="accordionExample">


                              {btTocDatset.map((ele, index) => (
                                <div className="accordion-item" key={index}>
                                  <h2 className="accordion-header" id={`heading${index}`}>
                                    <button
                                      className={`accordion-button ${currentOpenIndex === index ? "" : "collapsed"
                                        }`}
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={`#collapse${index}`}
                                      aria-expanded={currentOpenIndex === index ? "true" : "false"}
                                      aria-controls={`collapse${index}`}
                                      onClick={() => setCurrentOpenIndex(index)}
                                    >
                                      {
                                        (headerIsEdit == index && isProjectEditable) ?
                                          <div className='input-group input-group-sm w-50'>
                                            <input type='text' className='form-control rounded-0' value={header} onInput={(e) => setHeader(e.target.value)} />
                                            <button type='button' className='btn btn-warning mx-2 rounded-0' onClick={() => { handleHeaderSave(ele) }}><i style={{ color: 'unset' }} className='fa fa-save'></i> Save</button>
                                            <button type='button' className='btn btn-danger rounded-0' onClick={() => { handleHeaderReset(false) }}><i className='fa fa-refresh'></i> Reset</button>
                                          </div>
                                          :
                                          <p className="mb-0">{ele?.name}<img className="ms-2 align-text-top" onClick={() => { HeaderEditMode(ele, index) }} src={require('../images/action_icon/edit.png')} /></p>
                                      }
                                    </button>
                                  </h2>
                                  <div
                                    id={`collapse${index}`}
                                    className={`accordion-collapse collapse ${currentOpenIndex === index ? "show" : ""
                                      }`}
                                    aria-labelledby={`heading${index}`}
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="accordion-body">
                                      {btTocDatsetComponent(ele, index)}
                                      <div className="slidelr">
                                        <i onClick={handlePreviousSlide} className="fa fa-chevron-left"></i>
                                        <i onClick={handleNextSlide} className="fa fa-chevron-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>

                        </div>
                        <div title="Table of Contents" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" className="fixeddiv">
                          TOE
                        </div>

                        <div className="modal fade" id="settingModal">
                          <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                              <div className="modal-header py-2">
                                <h5 className="modal-title">Project Info</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                              </div>
                              <div className="modal-body">
                                <ProjectStatusBar />
                                <ProjectDetails ProjectData={ProjectData}/>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={`modal fade ${openAddContributorsModal ? 'show' : ''}`} id="ContributorModal" style={{ display: openAddContributorsModal ? 'block' : 'none' }}>
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header py-2 d-flex justify-content-between" style={{ backgroundColor: '#0E4C8B', color: "white" }}>
                                <h5 className="modal-title">Add Contributors</h5>
                                <p className="" onClick={closeModal} style={{ color: 'white', cursor: 'pointer', marginTop: '13px' }}><span className="">&#x2715;</span></p>
                              </div>
                              <div className="modal-body">
                                <AddContributors contributorsList={contributorsList} setContributorsList={setContributorsList} />
                              </div>
                            </div>
                          </div>
                        </div>
                        {openAddContributorsModal && <div className="modal-backdrop fade show"></div>}
                        <div className="modal fade" id="CustomerModal">
                          <div className="modal-dialog ">
                            <div className="modal-content">
                              <div className="modal-header py-2 d-flex justify-content-between" style={{ backgroundColor: '#0E4C8B', color: "white" }}>
                                <h5 className="modal-title">Add Customers</h5>
                                <p className="" data-bs-dismiss="modal" style={{ color: 'white', cursor: 'pointer', marginTop: '13px' }}><span className="" >&#x2715;</span></p>
                              </div>
                              <div className="modal-body">
                                <AddCustomers />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" >
                          {lockTOC ? <FontAwesomeIcon title="Floating TOC" style={{ cursor: 'pointer' }} icon={faLock} className="mt-2" onClick={() => setLockTOC(!lockTOC)} /> : <FontAwesomeIcon icon={faLockOpen} style={{ cursor: 'pointer' }} className="mt-2" title="Fixed TOC" onClick={() => setLockTOC(!lockTOC)} />}
                          <div className="offcanvas-header">
                            <h5 id="offcanvasRightLabel">Table of Contents</h5>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                          </div>
                          <div className="offcanvas-body pt-0">


                            <div className="toeDiv">
                              {btTocDatset.map((e, index) =>
                                <div className="card" key={index}>
                                  <div title="Click to expand" className={` card-header  d-flex align-items-center  ${currentOpenIndex === index ? 'active' : ''}`} >
                                    {
                                      e?.children.length ? (<i className={`me-2 fa fa-${currentOpenIndex === index && TOCAcc ? 'minus' : 'plus'}`} onClick={() => { handleTOCClick(index) }}></i>) : <i className="mx-2"></i>
                                    }
                                    <span onClick={() => handleTOCClick(index)}>{e?.name}</span>
                                  </div>
                                  <div className={`card-body ${currentOpenIndex === index && TOCAcc ? '' : 'd-none'}`}>
                                    <ul>
                                      {e?.children.map((child,index) => {
                                        if (child?.page_type !== "key_comp" || (child?.page_type === "key_comp" && child?.isView !== 1)) {
                                          console.log("child",child)
                                          return (
                                            <li 
                                              className="cursor-pointer flex items-center justify-between p-2 hover:bg-gray-100 rounded-md transition-colors duration-200"
                                              key={index} 
                                              onClick={() => handleScroll(child)}
                                            >
                                              <span className="text-gray-700 font-medium">{child?.name}</span>
                                              {child?.isMandatory ? (
                                                 <span className="text-red-500 ml-1" aria-label="Mandatory">*</span>
                                              ):null}
                                              {child?.isSaved ? (
                                                <span className="flex items-center text-green-500">
                                                  <FontAwesomeIcon icon={faCheck} className="mr-1" />
                                                  
                                                </span>
                                              ):null}
                                            </li>
                                          );
                                        }
                                        return null;
                                      })}
                                    </ul>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    }}
                </ProgressContext.Consumer>
              </ProgressProvider>
            }
          }
        </ProjectStatusContext.Consumer>
      </ProjectStatusProvider>
    </>
  )
}

export default Notification
