import React, { useContext, useEffect, useState } from 'react'
import { useDeleteGouDataMutation, useGetGrowthUniverseDataByIdQuery, usePostCriteriaDataMutation } from '../../redux/slices/ProjectPageAPISlice';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { range } from '../../Utils/CalculationUtils'
import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider';
import CkEditorBase from '../../CkEditorReact/CkEditorBase';
import './Style.css'

function GrowthOpportunityUniverse({ goInid, growthUniverseData }) {

    const [postCriteriaData] = usePostCriteriaDataMutation();
    const [deleteGouData] = useDeleteGouDataMutation()
    const { projectId } = useParams();
    const { data, isLoading } = useGetGrowthUniverseDataByIdQuery({ projectId: projectId, goInId: goInid })
    const { isProjectEditable } = useContext(ProjectStatusContext)
    const [selectedUserIndustries, setSelectedUserIndustries] = useState([]);
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [selectedGrowthItems, setSelectedGrowthItems] = useState([]);
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const [goInTitle, setGoInTitle] = useState('');
    const [baseYear, setBaseYear] = useState(new Date().getUTCFullYear());
    const [timeLine, setTimeLine] = useState(0);
    const [opportunitySize, setOpportunitySize] = useState(0);
    const [candDone, setCandDOne] = useState('');
    const [candDTwo, setCandDTwo] = useState('');
    const [goInidReload, setGoInIdReload] = useState("");
    const [validationErrors, setValidationErrors] = useState({
        title: false,
        opportunitySize: false,
        timeLine: false,
        baseYear: false,
        userIndustries: false,
        regions: false,
        growthItems: false
    });

    useEffect(() => {
        if (data?.data?.context != undefined && data?.data?.call_to_action != undefined) {
            setCandDOne(data?.data?.context);
            setGoInIdReload(goInid);
            setCandDTwo(data?.data?.call_to_action);
        }
    }, [data])

    const handleCk1 = async (e, type) => {
        let strippedText = e.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ');
        const characterCount = strippedText.length;
        // const wordCount = strippedText.split(/\s+/).filter(word => word.length > 0).length;

        if (characterCount < 1200) {
            if (type == '1') {
                setCandDOne(e)
                if (debounceTimeout) clearTimeout(debounceTimeout);
                const timeout = setTimeout(() => {
                    if (goInid) {
                        handleEditGou(e, '')
                    }
                }, 10000);

                setDebounceTimeout(timeout);
            } else {
                setCandDTwo(e)
                if (debounceTimeout) clearTimeout(debounceTimeout);
                const timeout = setTimeout(() => {
                    if (goInid) {
                        handleEditGou('', e)
                    }
                }, 10000);

                setDebounceTimeout(timeout);
            }
        } else {
            toast.error('Only 1200 Characters allowed')
        }

    }
    const handleCk2 = async () => {
        if (debounceTimeout) clearTimeout(debounceTimeout);
        const timeout = setTimeout(() => {
            // handleSave(false, false, e)
        }, 10000);

        setDebounceTimeout(timeout);
    }

    useEffect(() => {
        if (data?.data) {
            setGoInTitle(data?.data.name || '')
            setBaseYear(data?.data.base_year || new Date().getFullYear());
            setTimeLine(data?.data.timeline_for_action_from || 0);
            setOpportunitySize(data?.data.opp_size_from || 0);
            setSelectedUserIndustries(data?.data?.criteria?.industry?.map((item) => item.relevant_id) || [])
            setSelectedRegions(data?.data?.criteria?.region?.map((item) => item.relevant_id) || [])
            setSelectedGrowthItems(data?.data?.criteria?.growth?.map((item) => item.relevant_id) || [])
        }

    }, [data, isLoading])


    const validateGOU = () => {
        let isValid = true;
        let errors = [];
        let newValidationErrors = {
            title: false,
            opportunitySize: false,
            timeLine: false,
            baseYear: false,
            userIndustries: false,
            regions: false,
            growthItems: false
        };

        if (!goInTitle.trim()) {
            errors.push('Title is required.');
            newValidationErrors.title = true;
            isValid = false;
        }

        if (opportunitySize === "") {
            errors.push('Opportunity Size in 5 Years must be selected.');
            newValidationErrors.opportunitySize = true;
            isValid = false;
        }

        if (timeLine === "") {
            errors.push('Timeline for Action must be selected.');
            newValidationErrors.timeLine = true;
            isValid = false;
        }

        if (baseYear === "") {
            errors.push('Base Year must be selected.');
            newValidationErrors.baseYear = true;
            isValid = false;
        }

        if (selectedUserIndustries.length === 0) {
            errors.push('At least one User Industry must be selected.');
            newValidationErrors.userIndustries = true;
            isValid = false;
        }

        if (selectedRegions.length === 0) {
            errors.push('At least one Region must be selected.');
            newValidationErrors.regions = true;
            isValid = false;
        }

        if (selectedGrowthItems.length === 0) {
            errors.push('At least one Growth Item must be selected.');
            newValidationErrors.growthItems = true;
            isValid = false;
        }

        setValidationErrors(newValidationErrors);
        return { isValid, errors };
    };

    const handlePostGOU = (data1 = '', data2 = '') => {
        const { isValid, errors } = validateGOU();
        if (!isValid) {
            // Loop over the array and show the errors
            errors.forEach(error => {
                toast.error(error);
            });
            return;
        }

        const dataObj = {
            userid: getUserDetailsFromLocalStorage().user_id,
            title_go: goInTitle,
            opp_size: opportunitySize,
            timeline: timeLine,
            baseyear: baseYear,
            industry: selectedUserIndustries,
            regions: selectedRegions,
            growth: selectedGrowthItems,
            context: data1 != '' ? data1 : candDone,
            call_to_action: data2 != '' ? data2 : candDTwo
        }

        postCriteriaData({ body: dataObj, eco_project_id: projectId })
            .then((res) => console.log(res)).then(() => {
                setSelectedUserIndustries([]);
                setSelectedRegions([]);
                setSelectedGrowthItems([]);
                setGoInTitle('');
                setBaseYear(new Date().getFullYear());
                setTimeLine(0);
                setOpportunitySize(0);
            }).catch((err) => console.log(err));
    }
    const handleEditGou = async (data1 = '', data2 = '') => {
        const { isValid, errors } = validateGOU();
        if (!isValid) {
            // Loop over the array and show the errors
            errors.forEach(error => {
                toast.error(error);
            });
            return;
        }


        try {
            const dataObj = {
                go_id: goInid,
                userid: getUserDetailsFromLocalStorage().user_id,
                title_go: goInTitle,
                opp_size: opportunitySize,
                timeline: timeLine,
                baseyear: baseYear,
                industry: selectedUserIndustries,
                regions: selectedRegions,
                growth: selectedGrowthItems,
                context: data1 !== '' ? data1 : candDone,
                call_to_action: data2 !== '' ? data2 : candDTwo
            }
            postCriteriaData({ body: dataObj, eco_project_id: projectId })
                .then((res) => console.log(res)).then(() => {
                    toast.success('GOU data updated successfully')
                })
        } catch (error) {
            console.log(error)
        }
    }
    const handleDelete = async () => {
        try {
            await deleteGouData({ projectId: projectId, goInId: goInid }).unwrap();
            toast.success('GOU data deleted successfully')

        } catch (error) {
            toast.error('Error deleting GOU data:', error);
        }
    };
    useEffect(() => {
        return () => {
            if (debounceTimeout) clearTimeout(debounceTimeout);
        };
    }, [debounceTimeout]);
    return (

        <React.Fragment>


            <div className='row mb-2'>
                <div className='col-md-4'>
                    <div className='gp-select-wrapper'>
                        <label className="gou-title">Title</label>
                        <input type="text"
                            required
                            disabled={!isProjectEditable}
                            value={goInTitle}
                            onChange={(e) => {
                                setGoInTitle(e.target.value);
                                setValidationErrors(prev => ({ ...prev, title: false }));
                            }}
                            name='title_name'
                            className={`gou-title-input ${validationErrors.title ? 'is-invalid' : ''}`}
                        />
                    </div>
                </div>


            </div>


            <div class="gp-form-body">
                <div class="h-auto gp-form-header mb-4">
                    <div class="gp-select-wrapper">
                        <label for="OppSize">Opp. Size In 5 Years</label>
                        <select
                            disabled={!isProjectEditable}
                            required value={opportunitySize}
                            onChange={(e) => {
                                setOpportunitySize(e.target.value);
                                setValidationErrors(prev => ({ ...prev, opportunitySize: false }));
                            }}
                            className={`${validationErrors.opportunitySize ? 'is-invalid' : ''}`}>
                            {growthUniverseData?.oppsize?.map((item, i) => {
                                return <option key={i} value={item.id}>{item.name}</option>
                            })}
                        </select>
                    </div>
                    <div class="gp-select-wrapper">
                        <label for="timelineAction">Timeline For Action</label>

                        <select
                            disabled={!isProjectEditable}
                            required value={timeLine}
                            onChange={(e) => {
                                setTimeLine(e.target.value)
                                setValidationErrors(prev => ({ ...prev, timeLine: false }));
                            }}
                            className={` ${validationErrors.timeLine ? 'is-invalid' : ''}`}>
                            {growthUniverseData?.timeline?.map((item, i) => {
                                return <option key={i} value={item.id}>{item.name}</option>
                            })}
                        </select>
                    </div>
                    <div class="gp-select-wrapper">
                        <label for="baseYear">Base Year</label>
                        <select

                            disabled={!isProjectEditable}
                            required
                            className={` ${validationErrors.baseYear ? 'is-invalid' : ''}`}
                            value={baseYear}
                            onChange={(e) => {
                                setBaseYear(e.target.value)
                                setValidationErrors(prev => ({ ...prev, baseYear: false }));
                            }}>
                            {range(new Date().getFullYear(), new Date().getFullYear() + 5, 1).map((item) => {
                                return <option key={item}>{item}</option>
                            })}
                        </select>
                    </div>
                </div>
                <div class="gp-award">
                    <div class="gp-label">
                        <span>Applicable Regions</span>
                    </div>
                    <div className={`wrapper ${validationErrors.regions ? 'is-invalid' : ''}`}>
                        {growthUniverseData?.region?.map((item, i) => {
                            return (
                                <div
                                    className={`gp-input-wrapper`}
                                    disabled={!isProjectEditable}
                                    onClick={() => {
                                        console.log("Div clicked");

                                        if (selectedRegions?.includes(item.id)) {
                                            console.log(selectedRegions);
                                            setSelectedRegions((prev) => prev.filter((i) => item.id !== i));
                                        } else {
                                            setSelectedRegions((prev) => [...prev, item.id]);
                                            setValidationErrors((prev) => ({ ...prev, regions: false }));
                                        }
                                    }}
                                    key={i}
                                >
                                    <input
                                        type="checkbox"
                                        id={item?.name}
                                        onClick={(e) => e.stopPropagation()} // Prevent checkbox click from bubbling up to the div
                                    />
                                    <label htmlFor={item?.name} className={selectedRegions?.includes(item.id) ? "active" : ""}>{item?.name}</label>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div class="gp-award">
                    <div class="gp-label">
                        <span>Relevant End User Industries for this Growth
                            Opportunity</span>
                    </div>
                    <div class={`wrapper ${validationErrors.userIndustries ? 'is-invalid' : ''}`}>


                        {growthUniverseData?.industry?.map((item, i) => {
                            return <div disabled={!isProjectEditable} onClick={() => {

                                if (selectedUserIndustries?.includes(item.id)) {
                                    setSelectedUserIndustries((prev) => prev.filter((i) => item.id !== i))
                                }
                                else setSelectedUserIndustries((prev) => [...prev, item.id]);
                                setValidationErrors(prev => ({ ...prev, userIndustries: false }));
                            }} key={i}
                                className="gp-input-wrapper gp-col-3">
                                <input type="checkbox" id={item?.id} onClick={(e) => e.stopPropagation()} />
                                <label for={item?.id} className={selectedUserIndustries?.includes(item.id) ? "active" : ""}>
                                    <div class="gp-icon">
                                        <img src={require(`../../images/industries/icon_grey/${item.icon_name}`)}
                                            alt="manufacturing" />
                                    </div>
                                    <span>{item?.name}</span>
                                </label>
                            </div>
                        })}


                    </div>
                </div>
                <p class="gp-info-text">Frost & Sullivan has identified 10 Growth
                    Processes that serve as
                    levers for determining and evaluating new Growth Opportunities.</p>
                <div class="gp-award">
                    <div class="gp-label">
                        <span>Growth
                            Process</span>
                    </div>
                    <div class={`wrapper ${validationErrors.growthItems ? 'is-invalid' : ''}`}>


                        {growthUniverseData?.growth.map((item, i) => {
                            return <div
                                disabled={!isProjectEditable}
                                onClick={() => {
                                    if (selectedGrowthItems?.includes(item.id)) {
                                        setSelectedGrowthItems((prev) => prev.filter((i) => item.id !== i))
                                    }
                                    else setSelectedGrowthItems((prev) => [...prev, item.id]);
                                    setValidationErrors(prev => ({ ...prev, growthItems: false }));
                                }}
                                key={i} className='gp-input-wrapper gp-col-2' style={{ cursor: 'pointer' }}>

                                <input
                                    type="checkbox"
                                    id={item?.name}
                                    onClick={(e) => e.stopPropagation()} // Prevent checkbox click from bubbling up to the div
                                />
                                <label htmlFor={item?.name} className={selectedGrowthItems?.includes(item.id) ? "active" : ""}>{item?.name}</label>
                            </div>
                        })}

                    </div>

                </div>
                <div class="gp-ck-editor-wrapper">
                    <div class="gp-cdk-editor">
                        <div class='gp-label'>
                            <span>Context & Definition</span>
                        </div>
                        <div class="p-2">
                            {
                                isProjectEditable ?
                                    <CkEditorBase onChange={(data) => handleCk1(data, '1')} data={candDone} editorId={`context-1-${goInidReload}`} tocId={goInid} Limits={[250, 1200, true]} />
                                    : <textarea rows={10} className='form-control form-control-sm '></textarea>
                            }
                        </div>

                    </div>
                    <div class="gp-cdk-editor">
                        <div class='gp-label'>
                            <span>Call to Action</span>
                        </div>
                        <div class="p-2">
                            {
                                isProjectEditable ?

                                    <CkEditorBase onChange={(data) => handleCk1(data, '2')} data={candDTwo} editorId={`call_to_action-2-${goInidReload}`} tocId={goInid} Limits={[250, 1200, true]} />
                                    : <textarea rows={10} className='form-control form-control-sm '></textarea>
                            }
                        </div>
                    </div>
                </div>

            </div>
            <div className="d-flex justify-content-end mt-4 me-4">

                <div className='d-flex justify-content-end'>
                    <div class="gp-action-btn-part mt-1">
                        <button disabled={!isProjectEditable} type="button" onClick={() => { goInid ? handleEditGou() : handlePostGOU() }} class="gp-btn-fill"><i className="fa fa-save me-1"></i>Save</button>
                        <button disabled={!isProjectEditable} type="button" onClick={handleDelete} ><i className="fa fa-close me-1"></i>Delete</button>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default GrowthOpportunityUniverse