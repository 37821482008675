import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { useGetDNRDataQuery } from '../../DriversAndRestraints/Store/dataSlices/DriverAndRestraintAPISlice';
import OthersEditableDandR from './Components/OtherEditableD&R';
import DisplayDandR from './Components/DisplayD&R';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import Footer from './Footer';
import { useGetDrDataQuery } from '../../redux/slices/ProjectPageAPISlice';
function GrowthRevenueRestraints(props) {
    const [revenueRestraints, setrevenueRestraints] = useState({});
    const [years, setYears] = useState([]);
    const projectId = props?.go_in_id;
    const [type, setType] = useState("dr");
    const { data: DnrData, error, isError, isLoading, isSuccess, } = useGetDNRDataQuery({ projectId, userId: getUserDetailsFromLocalStorage().user_id });
    const { data: DrData, refetch } = useGetDrDataQuery({ id: props?.go_in_id, seg_id: DnrData?.initialNodes[0]?.id, user_id: getUserDetailsFromLocalStorage().user_id });
    const getDnrData = async (seg_id) => {
        const id = props?.go_in_id;
        let params = {
            seg_id: seg_id,
            user_id: getUserDetailsFromLocalStorage()?.user_id
        }
        try {
            let data = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${id}/getdrdata`, { params })
            if (data?.data?.drivers_restrainsts_data?.revenueRestraints) {
                setrevenueRestraints(data?.data?.drivers_restrainsts_data?.revenueRestraints)
            }
            else { setrevenueRestraints({}) }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if (DnrData?.initialNodes[0]?.id) {
            getDnrData(DnrData?.initialNodes[0]?.id)
        }
    }, [DnrData])

    useEffect(() => {
        if (typeof (DrData) != "undefined") {
            setType(DrData?.data?.dr_type)
            if (DrData?.drivers_restrainsts_data?.revenueRestraints) {
                setrevenueRestraints(DrData?.drivers_restrainsts_data?.revenueRestraints)
            } else { setrevenueRestraints({}) }
        }
    }, [DrData])
    useEffect(() => {
        if (Object.keys(revenueRestraints).length !== 0) {
            setYears(Object.keys(Object.values(revenueRestraints)[0]))
        }
    }, [revenueRestraints])

    
    return (
        <>
         <div class="h-100 d-flex flex-column gp-editor-wrapper w-100">
                <div class="w-100 h-100 flex-grow-1 position-relative gp-editor">
                    <div class="w-100 h-100 gp-competitorSection gp-editing-content">
                        <div class="gp-metric-body my-4" >

                        {
                        type == "other" ? <OthersEditableDandR allData={DrData} years={years} refetch={() => { refetch() }} toc={props} type="revenueRestraints"/> :
                            <DisplayDandR unitDrivers={revenueRestraints} years={years} type="restraints"/>
                    }
                        </div>

                    </div>
                </div>

                <Footer />
            </div>
        </>

    )
}

export default GrowthRevenueRestraints
