import React, { useRef, useEffect, useState } from "react";
import { DrawIoEmbed } from 'react-drawio';
import { Box, Modal } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";


const DrawioComponent = () => {
    const drawIoContainerRef = useRef(null);
    const [isIframeLoaded, setIframeLoaded] = useState(false);
    const [saveOpen, setSaveOpen] = useState(false)
    const [exitOpen, setExitOpen] = useState(false)
    const [title, setTitle] = useState('')
    const [error, setError] = useState(false)
    const [pngData, setPngData] = useState('')
    const [xmlData, setXmlData] = useState('')
    const [dummy, setDummy] = useState(1)

    const inputChange = (e) => {
        setTitle(e.target.value)
        setError(false)
        setDummy(dummy + 1)
    }


    // Direct XML content
    const xmlString = ` ${sessionStorage.getItem('xmlInfo')}`
    console.log(xmlString);


    // Send XML data to the iframe once it loads
    useEffect(() => {
        if (isIframeLoaded) {
            const iframe = drawIoContainerRef.current.querySelector('iframe');
            if (iframe) {
                iframe.contentWindow.postMessage(JSON.stringify({
                    action: 'load',   // Action to load XML directly
                    autosave: 1,
                    xml: sessionStorage.getItem('xmlInfo')  // Pass the raw XML string directly
                }), '*');
                iframe.contentWindow.postMessage(JSON.stringify({
                    action: 'setBackgroundColor',
                    color: '#FFFFFF'  // Set background color to white
                }), '*');

            }
        }
    }, [isIframeLoaded]);

    const handleIframeLoad = () => {
        setIframeLoaded(true);
    };

    const handleDownloadXml = () => {

        if (title.length <= 0) {
            setError(true)
        } else {
            const iframe = drawIoContainerRef.current.querySelector('iframe');
            if (iframe) {
                iframe.contentWindow.postMessage(JSON.stringify({
                    action: 'export',
                    format: 'xml',
                    titleName: title
                }), '*');
            }
        }

    };

    const style2 = {
        position: 'absolute',
        top: '25%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        boxShadow: 24,
        bgcolor: 'white',
        width: '50%',
        borderRadius: '0.6rem',

    };

    // function base64ToBlob(base64, mimeType) {
    //     const byteCharacters = atob(base64);
    //     const byteArrays = [];
    //     for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    //         const slice = byteCharacters.slice(offset, offset + 512);
    //         const byteNumbers = new Array(slice.length);
    //         for (let i = 0; i < slice.length; i++) {
    //             byteNumbers[i] = slice.charCodeAt(i);
    //         }
    //         const byteArray = new Uint8Array(byteNumbers);
    //         byteArrays.push(byteArray);
    //     }
    //     return new Blob(byteArrays, { type: mimeType });
    // }
    function base64ToBlob(base64, mimeType) {
        console.log(base64);

        try {
            // Remove any base64 prefix and whitespace from the string
            base64 = base64.replace(/^data:\w+\/\w+;base64,/, '').replace(/\s/g, '');

            const byteCharacters = atob(base64);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);
                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
            console.log(new Blob(byteArrays, { type: mimeType }));

            return new Blob(byteArrays, { type: mimeType });
        } catch (error) {
            console.error("Invalid base64 string", error);
            return null; // or handle as needed
        }
    }


    const uploadFiles = async () => {

        if (title.length <= 0) {
            setError(true)
        } else {
            try {

                let arr = window.location.href.split('/')
                let projectId = arr[arr.length - 2]
                let tocId = arr[arr.length - 1]
                console.log(1);
                handleDownloadPng()
                console.log(2);
                handleDownloadXml()
                console.log(3);

                const base64Data = pngData.split(',')[1];
                console.log(base64Data, '00000', pngData);

                const blob = base64ToBlob(base64Data, 'image/png');
                console.log(4);


                const formData = new FormData();
                formData.append('xmlfile', `${xmlData}`);
                formData.append('excelfile', blob, 'image.png');
                console.log(5);
                let response = await axios.post(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${projectId}/uploadFile/${tocId}`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                console.log(6);

                if (response.status == 'success') {
                    toast.success('Info grapic saved')
                }
            } catch (error) {
                console.log(error);
                toast.error('Something went wrong')
            }
        }

    }


    const handleDownloadPng = () => {
        // console.log(title);

        if (title.length <= 0) {
            setError(true)
        } else {
            const iframe = drawIoContainerRef.current.querySelector('iframe');
            if (iframe) {
                iframe.contentWindow.postMessage(JSON.stringify({
                    action: 'export',
                    format: 'png',
                    titleName: title
                }), '*');
            }
        }
    };

    const downloadXml = (xmlString, titleName) => {
        const blob = new Blob([xmlString], { type: 'application/xml' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${titleName}.xml`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        setTitle('')
        setDummy(dummy + 1)
        setSaveOpen(false)
    };

    const downloadPng = (dataUrl, titleName) => {
        // console.log(titleName);

        const a = document.createElement('a');
        a.href = dataUrl;
        a.download = `${titleName}.png`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setTitle('')
        setDummy(dummy + 1)
        setSaveOpen(false)


    };
    useEffect(() => {
        const messageListener = (event) => {
            if (event.data && typeof event.data === 'string') {
                try {
                    const message = JSON.parse(event.data);
                    // Listen for save event in Draw.io editor

                    if (message.event === 'save') {
                        console.log('1234');
                        setTitle('')
                        setSaveOpen(true)
                        setError(false)
                        setDummy(dummy + 1)

                    }
                    if (message.event === 'export' && message.data && message?.message?.format == 'png') {
                        setPngData(message.data)
                        downloadPng(message.data, message?.message?.titleName);
                        setDummy(dummy + 1)
                        console.log(message, title);

                    }

                    if (message.event === 'export' && message.data && message?.message?.format == 'xml') {
                        downloadXml(message.xml, message?.message?.titleName);
                        setXmlData(message.xml)
                        setDummy(dummy + 1)
                        console.log(message);

                    }
                    if (message.event === 'exit') {
                        // downloadXml(message.xml, message?.message?.titleName);
                        // setXmlData(message.xml)
                        // setDummy(dummy + 1)
                        // console.log('ee');
                        setExitOpen(true)


                    }

                } catch (error) {
                    console.error("Error parsing message data:", error);
                }
            }
        };

        window.addEventListener('message', messageListener);

        return () => {
            window.removeEventListener('message', messageListener);
        };
    }, []);

    const exitPage = () => {
        sessionStorage.removeItem('xmlInfo')
        window.close();
    }

    return (
        <div ref={drawIoContainerRef} style={{ width: "100%", height: "600px", border: "1px solid black" }}>
            <DrawIoEmbed
                urlParameters={{
                    ui: 'editor;noexit;noSave;noautosave',   // Explicitly remove the exit, save, and autosave buttons
                    embed: 1,
                    proto: 'json',
                    bg: '#FFFFFF',
                }}
                onLoad={handleIframeLoad}  // Detect when the iframe has loaded
            />

            {saveOpen && <Modal
                open={saveOpen}
                onClose={() => { setSaveOpen(false) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style2}>
                    <div class="modal-content">
                        <div class="modal-header py-2 p-2">
                            <div className="w-100 d-flex justify-content-between">
                                <h1 class="modal-title">Save </h1>
                                <button type="button" onClick={() => { setSaveOpen(false) }} class="btn-close" style={{ fontSize: '1.6rem' }}></button>
                            </div>
                        </div>
                        <div className="modal-body py-0 d-flex justify-content-around p-4">

                            <input id="" type="text" placeholder="Enter filename" onChange={inputChange} value={title} style={{ fontSize: '2.6rem' }}
                                class="w-100 gp-input-frost p-4" />
                        </div>
                        {error && <span className="p-4" style={{ color: 'red' }}>Title is required</span>}
                        <div className="modal-footer border-top-0 mt-3 mb-3 me-3">
                            <button type="button" style={{ fontSize: '1.6rem' }} className="btn btn-primary btn-sm" onClick={uploadFiles}><i class="fa fa-download" ></i> Save to S3</button>
                            <button type="button" style={{ fontSize: '1.6rem' }} className="btn btn-danger btn-sm ms-2" onClick={() => { setSaveOpen(false) }} > Cancel</button>
                            {/* <button type="button" style={{ fontSize: '1.6rem' }} className="btn btn-primary btn-sm ms-2" onClick={handleDownloadXml} ><i class="fa fa-download"></i> Download as XML</button> */}
                        </div>
                    </div>
                </Box>
            </Modal>}
            {exitOpen && <Modal
                open={exitOpen}
                onClose={() => { setExitOpen(false) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style2}>
                    <div class="modal-content">
                        <div class="modal-header py-2 p-2">
                            <div className="w-100 d-flex justify-content-between">
                                <h1 class="modal-title">Are you sure want to Exit? </h1>
                                <button type="button" onClick={() => { setExitOpen(false) }} class="btn-close" style={{ fontSize: '1.6rem' }}></button>
                            </div>
                        </div>



                        <div className="modal-footer border-top-0 mt-3 mb-3 me-3">
                            <button type="button" style={{ fontSize: '1.6rem' }} className="btn btn-primary btn-sm" onClick={exitPage}><i class="fa fa-download" ></i> Yes</button>
                            <button type="button" style={{ fontSize: '1.6rem' }} className="btn btn-danger btn-sm ms-2" onClick={() => { setExitOpen(false) }} > No</button>
                            {/* <button type="button" style={{ fontSize: '1.6rem' }} className="btn btn-primary btn-sm ms-2" onClick={handleDownloadXml} ><i class="fa fa-download"></i> Download as XML</button> */}
                        </div>
                    </div>
                </Box>
            </Modal>}
        </div>
    );
};

export default DrawioComponent;
