import React from 'react'
import '../layout/CSS/previewPage.css'
import '../assets/css/style.css'
import img from '../assets/images/slider-preview/image-removebg-preview.png'

function PreviewHeader({sectorName,region,from,to}) {
    // console.log(data);
    
    return (
        <div class="gp-preview-slide-header">
            <div class="gp-preview-slide-header__icon">
                <img src={img} alt="FS"/>
            </div>
            <h2 class="gp-preview-slide-header__heading">{sectorName} Market/Sector, Region, {from}-{to}</h2>
        </div>
    )
}

export default PreviewHeader