
import { routeprefixes } from './RoutePrefixes';
import HomePage from '../Components/HomePage';
import MyProjects from '../Components/MyProjects';

import SampleTable from '../Components/SampleTable';
import Notifications from '../Components/Notifications';
import { Suspense, lazy } from 'react';
import Loading from '../ComponentUtils/Loading';
import ProjectTheme from '../Components/ProjectTheme/ProjectTheme';
import Testing from '../Components/Testing';
import MyChartComponent from '../Components/MyChartComponent';


const ProjectPage=lazy(()=>import ('../Components/ProjectPage'))
const ProjectViewPage=lazy(()=>import ('../Components/Preview'))
const CreateProject=lazy(()=>import ('../Components/CreateProject'))


const {homePage,createproject,
  myprojects,sampletable,project,
  chart,projectView,pptview,tocThemeConfig,mytest}=routeprefixes

console.log(pptview);

export const pages = [
    { 
      path: `${homePage}`, 
      component: <MyProjects/>
    },
    {
      path: `${project}`,
      component: <MyProjects/>,
    },
    {
      path: `${chart}/:projectId/:tocId`,
      component: <MyChartComponent/>,
    },
    {
      path: `${chart}/:projectId/:tocId/:chartId`,
      component: <MyChartComponent/>,
    },
    {
      path: `${createproject}`,
      component: <Suspense fallback={<Loading/>}><CreateProject/></Suspense>,
    },
    {
      path: `${sampletable}`,
      component: <SampleTable/>,
    },
    {
      path:`${project}/:projectId`,
      component:<Suspense fallback={<Loading/>}><ProjectPage/></Suspense>
    },
    {
      path:`${pptview}/:projectId/`,
      component:<Notifications/>
    },
    {
      path:`${projectView}/:projectId`,
      component:<Suspense fallback={<Loading/>}><ProjectViewPage/></Suspense>
    },
    {
      path:`${tocThemeConfig}`,
      component:<ProjectTheme/>
    },
    {
      path:`${mytest}/:projectId/:tocId`,
      component:<Testing/>
    }
    
    
  ];
