import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { getUserDetailsFromLocalStorage } from '../Utils/userUtils';
import { useNavigate } from 'react-router-dom';
import CreateProject from './CreateProject';
import { Box, Modal } from "@mui/material";
import AddContributors from './AddContributors';
import axios from "axios";
import { toast } from "react-toastify";
import CloneProject from './ProjectModals/CloneProject';
import clsx from 'clsx'
import './Styles/createProject.css'
import close from "../images/close.svg";
import addContriImg from "../images/Add contrriors.svg";
import editProImg from "../images/edit.svg";
import deleteImg from '../assets/images/delete.svg'



// Helper function for sorting
const getComparator = (order, orderBy) => {
    return order == 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};


const DataTableReact = ({ data, order, orderBy, onRequestSort, userDetails }) => {
    const [editData, setEditData] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [dummy, setDummy] = useState(0)
    const [contributorsOpen, setContributorsOpen] = useState(false)
    const [currentId, setCurrentId] = useState('')
    const [contributorsList, setContributorsList] = useState([])
    const [confromRepublish, setConfromRepublish] = useState(false);
    const [confromClone, setConfromClone] = useState(false);
    const [loader, setLoader] = useState(false)
    const [cloneId, setCloneId] = useState('');
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [deleteId, setDeleteId] = useState('')
    const [toeDelete, setToeDelete] = useState(false)

    const createSortHandler = (property) => () => {
        onRequestSort(property);
    };
    const closeModal = () => {
        setIsModalVisible(false)
    };
    const handleEditReload = () => {
        // setOpen(false);
        // getTableData();
    }

    const getUserDetails = (id, userArr) => {
        const res = userArr.find(e => e.id == id);
        return res ? `${res.first_name} ${res.last_name}` : '';
    };
    const style2 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        boxShadow: 24,
        bgcolor: 'white', // background-color in CSS
        width: '880px',
        borderRadius: '0.6rem',
    };



    // Define columns with unique IDs
    const columns = [
        { id: 'title_name', label: 'Project Title' },
        { id: 'go_in_type', label: 'Project Type' },
        { id: 'isrepublished', label: 'Project Status' },
        { id: 'created_by', label: 'Created By' },
        { id: 'created_at', label: 'Created Date' },
        { id: 'updated_at', label: 'Modified Date' }
    ];

    // Sort data using the sorting function
    const sortedData = React.useMemo(() => {
        return data.slice().sort(getComparator(order, orderBy));
    }, [data, order, orderBy]);

    const currentUser = getUserDetailsFromLocalStorage();
    const navigate = useNavigate();

    const OpenProject = (id) => {
        navigate(`/project/${id}`, { replace: true });
    }
    const OpenToeProject = (id) => {
        window.open(`${process.env.REACT_APP_TECHVISION_JS}/${id}`, '_blank');
    }

    const editProject = (id) => {
        console.log(id,data)
        setEditData(data?.filter(ele => ele.id == id || ele.project_id == id)?.[0])
        console.log(data?.filter(ele => ele.id == id || ele.project_id == id)?.[0]);
        setIsModalVisible(true);
        setDummy(dummy + 1)
    }
    const addContributors = (id) => {
        setContributorsOpen(true)
        setCurrentId(id)
        setDummy(dummy + 1)
    }

    const republishProject = async () => {
        setLoader(true)

        try {
            let postData = {
                user_id: getUserDetailsFromLocalStorage().user_id,
                status: 'toQc',
                return_status: "",
            }
            let response = await axios.post(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${confromRepublish}/updateStatus`, postData)
            setConfromClone(false)
            setLoader(false)
            toast.success('Republished successfully')
        } catch (error) {
            console.error(error);
            setLoader(false)

        }
    }

    const deleteProject = async () => {
        try {
            if (toeDelete) {
                let project_id = deleteId
                var response = await axios.delete(`${process.env.REACT_APP_TECHVISION_API}/project/deleteproject/${project_id}`)

            } else {
                var response = await axios.delete(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${deleteId}`)

            }
            // console.log(response);
            if (response.status == 200) {
                setDeleteOpen(false)
                toast.success('Project deleted successfully')
            }

            setTimeout(() => {
                window.location.reload();
            }, 1000);


        } catch (error) {
            console.log(error)
            toast.error('Project deletion Failed')

        }
    }

    return (
        <TableContainer sx={{ borderRadius: '6px', border: '1px solid white' }}>
            <Table>
                <TableHead>
                    <TableRow sx={{ backgroundColor: '#172e55' }}>
                        <TableCell style={{ fontSize: '1.6rem', color: 'white', fontWeight: '700' }}>S.NO</TableCell>
                        {columns.map((column, index) => (
                            <TableCell key={column.id} style={{ fontSize: '1.6rem', color: 'white', fontWeight: '700', }}>

                                <TableSortLabel
                                    active={orderBy == column.id}
                                    direction={orderBy == column.id ? order : 'asc'}
                                    onClick={createSortHandler(column.id)}
                                    className={clsx({
                                        activeSortLabel: orderBy === column.id,
                                        sortLabel: true
                                    })}
                                >
                                    {column.label}
                                </TableSortLabel>
                            </TableCell>
                        ))}
                        <TableCell style={{ fontSize: '1.6rem', color: 'white', fontWeight: '700' }}>Action</TableCell>
                    </TableRow>
                </TableHead>
                {console.log(sortedData)
                }
                <TableBody>
                    {sortedData.length === 0 ? (
                        <TableRow sx={{ backgroundColor: 'white' }}>
                            <TableCell colSpan={8} align="center" style={{ fontSize: '1.6rem', color: 'gray' }}>
                                No Data Found
                            </TableCell>
                        </TableRow>
                    ) : (
                        sortedData.map((row, index) => (
                            <TableRow key={index} sx={{ backgroundColor: 'white' }}>
                                <TableCell style={{ fontSize: '1.6rem', width: '10px' }}>{index + 1}</TableCell>
                                <TableCell style={{ color: '#666666', fontSize: '1.6rem', width: '40%', cursor: 'pointer' }} title='Click to open the project' onClick={() => row?.go_in_type != 4 ? OpenProject(row?.id) : OpenToeProject(row?.project_id)}>{row?.title_name || row?.project_name}</TableCell>
                                <TableCell style={{ fontSize: '1.6rem', width: '12%' }}>{row?.go_in_type == '1' ? "GO-IN - Industry" : row?.go_in_type == "2" ? 'GO-3-WEEKS-MAX' : row?.go_in_type == "3" ? 'GO-Outlook' : 'GO-TechVision'}</TableCell>
                                <TableCell style={{ fontSize: '1.6rem', width: '12%' }}>{row?.status && row.status.charAt(0).toUpperCase() + row.status.slice(1) || row?.project_status && row?.project_status}</TableCell>
                                <TableCell style={{ fontSize: '1.6rem', width: '10%' }}>{getUserDetails(row.created_by, userDetails) || getUserDetails(row.user_id, userDetails)}</TableCell>
                                <TableCell style={{ fontSize: '1.6rem', width: '12%' }}>{new Date(row.created_at).toLocaleDateString('en-GB').replace(/\//g, '-')}</TableCell>
                                <TableCell style={{ fontSize: '1.6rem', width: '12%' }}>{row?.updated_at && new Date(row?.updated_at).toLocaleDateString('en-GB').replace(/\//g, '-') || new Date(row.modified_at).toLocaleDateString('en-GB').replace(/\//g, '-')}</TableCell>
                                <TableCell style={{ fontSize: '1.6rem', width: '5%' }}>

                                    {row?.status == 'published' && row?.created_by == currentUser.user_id ? (
                                        <div className="d-flex justify-content-around">
                                            <i style={{ color: 'black' }} className="fa fa-clone clone-project cursor-pointer" title="Clone Project" data-id={row.id} onClick={() => { setConfromClone(true); setCloneId(row?.id) }}></i>
                                            <i style={{ color: 'black' }} className="fa fa-refresh republish-project cursor-pointer" title="Republish Project" data-id={row.id} onClick={() => setConfromRepublish(row.id)}></i>
                                        </div>
                                    ) : row?.status == "published" && (['editor', 'admin', 'qc', 'subeditor'].includes(currentUser.role) || row?.role_type == 'analyst' || row?.role_type == 'pm') ? (
                                        <div className="d-flex justify-content-around">
                                            <i style={{ color: 'black' }} className="fa fa-refresh republish-project cursor-pointer" title="Republish Project" data-id={row.id} onClick={() => setConfromRepublish(row.id)}></i>
                                        </div>
                                    ) : (row?.status == 'draft' || row?.status == 'toAnalyst') && row?.created_by == currentUser.user_id && row?.role_type == 'analyst' ? (
                                        <div className="d-flex justify-content-around">
                                            <img src={addContriImg} className='cursor-pointer me-2' style={{ width: "14px" }} onClick={() => addContributors(row.id)} title='Add contributors' />
                                            <img src={editProImg} className='cursor-pointer me-2' style={{ width: "14px" }} onClick={() => editProject(row.id)} title='Edit project' />
                                            <img src={deleteImg} className='cursor-pointer me-2' style={{ width: "14px" }} onClick={() => { setDeleteId(row?.id); setDeleteOpen(true) }} title='Delete project' />
                                        </div>
                                    ) : row?.status == "toPm" && row?.role_type == 'pm' ? (
                                        <div className="d-flex justify-content-around">
                                            <img src={addContriImg} className='cursor-pointer' style={{ width: "14px" }} onClick={() => addContributors(row.id)} title='Add contributors' />
                                            <img src={editProImg} className='cursor-pointer' style={{ width: "14px" }} onClick={() => editProject(row.id)} title='Edit project' />
                                            <img src={deleteImg} className='cursor-pointer me-2' style={{ width: "14px" }} onClick={() => { setDeleteId(row?.id); setDeleteOpen(true) }} title='Delete project' />

                                        </div>
                                    ) : row?.status == "toEditor" && currentUser.role == 'editor' ? (
                                        <div className="d-flex justify-content-around">
                                            <img src={addContriImg} className='cursor-pointer' style={{ width: "14px" }} onClick={() => addContributors(row.id)} />
                                        </div>
                                    ) : row?.go_in_type == 4 ? <div className="d-flex justify-content-around">
                                        <img src={editProImg} className='cursor-pointer' style={{ width: "14px" }} onClick={() => editProject(row?.project_id)} title='Edit project' />
                                        <img src={deleteImg} className='cursor-pointer me-2' style={{ width: "14px" }} onClick={() => { setDeleteId(row?.project_id); setDeleteOpen(true); setToeDelete(true) }} title='Delete project' />

                                    </div> : (
                                        <div className="d-flex justify-content-center"><span>-</span></div>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>


            <CreateProject editDataSet={editData} isVisible={isModalVisible} onClose={closeModal} reload={() => { handleEditReload() }} />
            {
                contributorsOpen &&
                <Modal
                    open={contributorsOpen}
                    onClose={() => { setContributorsOpen(false) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style2}>
                        <div class="modal-content">
                            <div class="modal-header pb-3">
                                <div className="w-100 d-flex justify-content-between align-items-center gp-contributor-modal-header">
                                    <h1 class="modal-title">Add Contributors</h1>
                                    <button type="button" class="gp-modal-close" onClick={() => { setContributorsOpen(false) }}>
                                        <img src={close} alt="close" />
                                    </button>
                                </div>
                            </div>
                            <div class="modal-body">
                                <AddContributors contributorsList={contributorsList} setContributorsList={setContributorsList} project_Id={currentId} />
                            </div>
                        </div>
                    </Box>
                </Modal>

            }
            {
                deleteOpen &&
                <Modal
                    open={deleteOpen}
                    onClose={() => { setDeleteOpen(false) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style2}>
                        <div class="modal-content">
                            <div class="modal-header pb-1">
                                <div className="w-100 d-flex justify-content-between align-items-center gp-contributor-modal-header">
                                    <h1 class="modal-title">Delete Project</h1>
                                    <button type="button" class="gp-modal-close" onClick={() => { setDeleteOpen(false) }}>
                                        <img src={close} alt="close" />
                                    </button>
                                </div>
                            </div>
                            <div className="modal-body ps-5 ms-5" style={{ fontSize: '2.0rem' }}>
                                Are you sure want to Delete the project?
                            </div>
                            <div className="modal-footer border-top-0 mt-3">
                                <button type="button" style={{ fontSize: '2.0rem' }} className="btn btn-danger btn-sm pb-2 me-2 mb-2" onClick={() => { setDeleteOpen(false) }}><i class="fa fa-close" ></i> Cancel</button>
                                <button type="button" style={{ fontSize: '2.0rem' }} className="btn btn-primary btn-sm pb-2 me-2 mb-2" onClick={() => deleteProject()}><i class="fa fa-check"></i> Yes</button>
                            </div>
                        </div>
                    </Box>
                </Modal>

            }
            {
                confromRepublish &&
                <Modal
                    open={confromRepublish}
                    onClose={() => { setConfromRepublish(false) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style2}>
                        <div class="modal-content">
                            <div class="modal-header py-2">
                                <div className="w-100 d-flex justify-content-between">
                                    <h5 class="modal-title">Republish Project</h5>
                                    <button type="button" onClick={() => { setConfromRepublish(false) }} class="btn-close" ></button>
                                </div>
                            </div>
                            <div className="modal-body py-0">
                                Are you sure want to republish the project?
                            </div>
                            <div className="modal-footer border-top-0 mt-3">
                                <button type="button" className="btn btn-danger btn-sm" onClick={() => { setConfromRepublish(false) }}><i class="fa fa-close" ></i> Cancel</button>
                                <button type="button" className="btn btn-primary btn-sm ms-2" onClick={() => republishProject()}><i class="fa fa-check"></i> Yes</button>
                            </div>
                        </div>
                    </Box>
                </Modal>
            }
            <div className="modal fade" id="cloneModal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header py-2">
                            <h5 className="modal-title">Project Information</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body">
                            <CloneProject
                                cloneId={cloneId}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {
                confromClone &&
                <Modal
                    open={confromClone}
                    onClose={() => { setConfromClone(false) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style2}>
                        <div class="modal-content">
                            <div class="modal-header py-2">
                                <div className="w-100 d-flex justify-content-between">
                                    <h5 class="modal-title">Clone Project</h5>
                                    <button type="button" onClick={() => { setConfromClone(false) }} class="btn-close" ></button>
                                </div>
                            </div>
                            <div className="modal-body py-0">
                                Are you sure want to clone the project?
                            </div>
                            <div className="modal-footer border-top-0 mt-3">
                                <button type="button" className="btn btn-danger btn-sm" onClick={() => { setConfromClone(false) }}><i class="fa fa-close" ></i> Cancel</button>
                                <button type="button" className="btn btn-primary btn-sm ms-2" onClick={() => setConfromClone(false)} data-bs-toggle="modal" data-bs-target="#cloneModal"><i class="fa fa-check"></i> Yes</button>
                            </div>
                        </div>
                    </Box>
                </Modal>
            }


        </TableContainer>
    );
};

export default DataTableReact;

