// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 1600px) {
    html{
        font-size: 55%;
    }
}
@media (max-width: 1400px) {
    html{
        font-size: 45%;
    }
}
/* @media (min-width : 900px ){
    html{
        font-size: 50%;
    }
} */`, "",{"version":3,"sources":["webpack://./src/assets/css/mediaquery.css"],"names":[],"mappings":"AAAA;IACI;QACI,cAAc;IAClB;AACJ;AACA;IACI;QACI,cAAc;IAClB;AACJ;AACA;;;;GAIG","sourcesContent":["@media (max-width: 1600px) {\n    html{\n        font-size: 55%;\n    }\n}\n@media (max-width: 1400px) {\n    html{\n        font-size: 45%;\n    }\n}\n/* @media (min-width : 900px ){\n    html{\n        font-size: 50%;\n    }\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
