import React, { useRef, useEffect, useState } from "react";
import { DrawIoEmbed } from 'react-drawio';
import { Box, Modal } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";

const DrawioComponent = () => {
    const drawIoContainerRef = useRef(null);
    const [isIframeLoaded, setIframeLoaded] = useState(false);
    const [saveOpen, setSaveOpen] = useState(false);
    const [exitOpen, setExitOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [error, setError] = useState(false);
    const exportResolverRef = useRef({ png: null, xml: null });

    // Initialize iframe with saved XML
    useEffect(() => {
        if (isIframeLoaded) {
            const iframe = drawIoContainerRef.current.querySelector('iframe');
            if (iframe) {
                iframe.contentWindow.postMessage(JSON.stringify({
                    action: 'load',
                    autosave: 1,
                    xml: sessionStorage.getItem('xmlInfo')
                }), '*');

                iframe.contentWindow.postMessage(JSON.stringify({
                    action: 'setBackgroundColor',
                    color: '#FFFFFF'
                }), '*');
            }
        }
    }, [isIframeLoaded]);

    const handleIframeLoad = () => setIframeLoaded(true);

    const handleExport = (format) => {
        if (!title) {
            setError(true);
            return Promise.reject('Title required');
        }

        const iframe = drawIoContainerRef.current.querySelector('iframe');
        if (!iframe) return Promise.reject('Iframe not loaded');

        return new Promise((resolve) => {
            exportResolverRef.current[format] = resolve;
            iframe.contentWindow.postMessage(JSON.stringify({
                action: 'export',
                format: format,
                titleName: title
            }), '*');
        });
    };

    const uploadFiles = async () => {
        if (!title) {
            setError(true);
            return;
        }

        try {
            // Trigger both exports and wait for completion
            const [pngData, xmlData] = await Promise.all([
                handleExport('png'),
                handleExport('xml')
            ]);

            // Prepare form data
            const pathSegments = window.location.href.split('/');
            const projectId = pathSegments[pathSegments.length - 2];
            const tocId = pathSegments[pathSegments.length - 1];

            const pngBlob = base64ToBlob(pngData.split(',')[1], 'image/png');

            const formData = new FormData();
            formData.append('xmlfile', `${xmlData}`);
            formData.append('excelfile', pngBlob, 'image.png');

            // Submit to API
            const response = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/project/${projectId}/uploadFile/${tocId}`,
                formData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );

            if (response.status === 200) {
                toast.success('Diagram saved successfully!');
                setSaveOpen(false);
                setTitle('');
            }
        } catch (error) {
            console.error('Upload error:', error);
            toast.error(error.response?.data?.message || 'Failed to save diagram');
        }
    };

    const base64ToBlob = (base64, mimeType) => {
        try {
            const byteCharacters = atob(base64);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);
                const byteNumbers = new Array(slice.length);

                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                byteArrays.push(new Uint8Array(byteNumbers));
            }

            return new Blob(byteArrays, { type: mimeType });
        } catch (error) {
            console.error("Blob conversion error:", error);
            throw new Error('Invalid image data');
        }
    };

    useEffect(() => {
        const handleMessage = (event) => {
            try {
                const message = JSON.parse(event.data);

                if (message.event === 'export') {
                    if (message?.message?.format === 'png') {
                        // Resolve PNG promise
                        if (exportResolverRef.current.png) {
                            exportResolverRef.current.png(message.data);
                            exportResolverRef.current.png = null;
                        }
                    }
                    else if (message?.message?.format === 'xml') {
                        // Resolve XML promise
                        if (exportResolverRef.current.xml) {
                            exportResolverRef.current.xml(message.xml);
                            exportResolverRef.current.xml = null;
                        }
                    }
                }
                else if (message.event === 'save') {
                    setSaveOpen(true);
                    setError(false);
                }
                else if (message.event === 'exit') {
                    setExitOpen(true);
                }
            } catch (error) {
                console.error("Message parsing error:", error);
            }
        };

        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, []);

    const exitPage = () => {
        sessionStorage.removeItem('xmlInfo');
        window.close();
    };

    return (
        <div ref={drawIoContainerRef} style={{ width: "100%", height: "600px", border: "1px solid black" }}>
            <DrawIoEmbed
                urlParameters={{
                    ui: 'editor;noexit;noSave;noautosave',
                    embed: 1,
                    proto: 'json',
                    bg: '#FFFFFF',
                }}
                onLoad={handleIframeLoad}
            />

            <Modal open={saveOpen} onClose={() => setSaveOpen(false)}>
                <Box sx={{
                    position: 'absolute',
                    top: '25%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: 'none',
                    boxShadow: 24,
                    bgcolor: 'white',
                    width: '50%',
                    borderRadius: '0.6rem',
                    p:1
                }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" style={{fontSize:'2.6rem'}}>Save Diagram</h5>
                            <button
                                type="button"
                                className="close"
                                onClick={() => setSaveOpen(false)}
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter file name"
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                    setError(false);
                                }}
                            />
                            {error && <div className="text-danger mt-2" style={{fontSize:'1.6rem'}}>File name is required</div>}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary me-2"
                                onClick={uploadFiles}
                                style={{fontSize:'1.6rem'}}
                            >
                                Save
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => setSaveOpen(false)}
                                style={{fontSize:'1.6rem'}}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal open={exitOpen} onClose={() => setExitOpen(false)}>
                <Box sx={{
                    position: 'absolute',
                    top: '25%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    width: '50%',
                    borderRadius: '8px'
                }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Confirm Exit</h5>
                            <button
                                type="button"
                                className="close"
                                onClick={() => setExitOpen(false)}
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            Are you sure you want to exit without saving?
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-danger"
                                onClick={exitPage}
                            >
                                Exit
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => setExitOpen(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default DrawioComponent;  