import React, { useState } from 'react'
import ThemeTable from './ThemeTable';
import bulb from '../../assets/images/PNG/lightbulb.png'
import './Styles.css'
import DialogueBox from '../../ComponentUtils/DialogBox';

const ProjectTheme = () => {
 const[selectedTheme,setSelectedTheme]=useState('GO-IN')
 const [openDialog,setOpenDialog]=useState(false);

  return (
    <React.Fragment>

      <h1 className='d-flex justify-content-center text-center pt-4'>Admin Config - TOC</h1>
      <div className='d-flex justify-content-between gap-2 px-4 mx-4'>
        <div className='d-flex align-items-center gap-3'> {/* Added d-flex, align-items-center and gap-3 */}
        <div class="w-100">
          <label class="gp-field-label">Select Type</label>
          <select 
            name='go_in_type' 
            value={selectedTheme} onChange={(e)=>setSelectedTheme(e.target.value)}
              class="w-100 gp-select-input gp-select">
              <option value={'GO-IN'} selected>Growth Opportunity Industry</option>
              <option value={'GO-3WEEK'}>Growth Opportunity 3-WEEKS-MAX</option>
              <option value={'GO-OUTLOOK'}>Growth Opportunity- Outlook</option>
          </select>
          </div>
        </div>
        <div 
          onClick={()=>setOpenDialog(true)}
          className="d-flex justify-content-start align-items-center gap-2 mb-3 cursor-pointer">
            <div className="gp-instruction-icon">
                <img src={bulb} alt="instruction icon" />
            </div>
            <p className="gp-instruction-text mb-0">Instructions</p>
        </div>
      </div>
      
      {selectedTheme?<ThemeTable selectedTheme={selectedTheme}/>:null}
      <DialogueBox 
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        dialogTitle={`💡 Table of content instructions`}
        dialogContentText={<div className="w-100">
          <ul className="gp-instruction-content">
              <li>The Checkboxes mark the features which you want to be available for the project based on your project theme selection. (Ticked-{'>'} feature available)</li>
              <li>If you want to reorder TOC items for a particular theme drag and move it to a different row</li>
              <li>Custom TOC item can be added by clicking Plus icon on the parent TOC item's Action Column</li>
              <li>You can delete child items by clicking on delete under Action Column</li>
              <li>Rename a TOC by clicking on the edit icon</li>
          </ul>
      </div>}
        handleClose={()=>setOpenDialog(false)}
        handleConfirmation={()=>{}}
        infoDialog={true}
        />
    </React.Fragment>
    
  )
}

export default ProjectTheme