import React from 'react'
import '../layout/CSS/previewPage.css'
import '../assets/css/style.css'


function PreviewFooter({pageNo,wipCode,updatedAt}) {
    // console.log(data);
  
    return (
        <div class="gp-preview-slide-footer">
            <p>Page {pageNo}</p>
            <p>{wipCode}</p>
            <p>{new Date(updatedAt).toLocaleDateString('en-GB').replace(/\//g, '-')}</p>
        </div>
    )
}

export default PreviewFooter