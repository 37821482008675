import React from 'react'
import DrawioComponent from './DrawioComponent'

function Testing() {
    return (
        <div>

            <div className="App">
                {/* <h1>Diagrams.net (Draw.io) in React</h1> */}
                <DrawioComponent />
            </div>
        </div>
    )
}

export default Testing