import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useCreateCloneTocMutation, useDeleteCloneMutation, useGetGrowthMetricDatabyProjectIdQuery, usePostGrowthMetricsDataMutation, usePostTocDataMutation, useUpdateIsApplicableMutation, useUpdateTocIsSavedMutation } from '../../redux/slices/ProjectPageAPISlice';
import { GrowthMetricsStaticData } from '../../AppStaticData/ProjectPageStaticData';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import SSOLoader from '../SSOLoader';
import GrowthMetricCard from '../GrowthMetrics/GrowthMetricCard';
import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider';
import { ProgressContext } from '../../Providers/ProgressProvider';
import Footer from './Footer';
import high from "../../images/high.png";
import medium from "../../images/medium.png";
import low from "../../images/low.png";
import DialogueBox from '../../ComponentUtils/DialogBox';
import '../../layout/CSS/previewPage.css'
import '../../assets/css/style.css'

const FilterButton = ({ selectedImpact, impact, label, className, onClick }) => (
    <button
        className={`btn btn-sm ${selectedImpact === impact ? className : `btn-outline-${className.split('-')[1]}`} me-2`}
        onClick={() => onClick(impact)}
    >
        {label}
    </button>
);


function GrowthMetrics(props) {
    const { projectId } = useParams();
    const { handleSectionSave } = useContext(ProgressContext)
    const { data,
        isSuccess, isLoading
    } = useGetGrowthMetricDatabyProjectIdQuery({
        projectId: projectId,
        segmentId: props.seg_id,
        tocId: props.id
    });
    const { isProjectEditable } = useContext(ProjectStatusContext)
    const [postTocData] = usePostTocDataMutation();
    const [updateTocIsSaved] = useUpdateTocIsSavedMutation();
    const [postGrowthMetricsData] = usePostGrowthMetricsDataMutation()
    const [growthMetrics, setGrowthMetrics] = useState([]);
    const [selectedImpact, setSelectedImpact] = useState(null);
    const [loader, setLoader] = useState(false);
    const [headerIsEdit, setHeaderIsEdit] = useState(false);
    const [header, setHeader] = useState("");

    useEffect(() => {
        if (isSuccess) {
            setGrowthMetrics(data?.data.length ? data.data : GrowthMetricsStaticData.data);
        }
    }, [data, isSuccess]);

    useEffect(() => {
        setHeader(props?.name);
    }, [props]);

    const addMetric = () => {
        const newId = growthMetrics.length + 1;
        setGrowthMetrics([...growthMetrics, { id: newId, name: 'New Metric' }]);
    };

    const deleteMetric = (id) => {
        setGrowthMetrics(prevMetrics => prevMetrics.filter(metric => metric.id !== id));
    };

    const updateMetricName = (index, field, value) => {

        const newGrowthMetricsData = growthMetrics.map((metric, i) => {
            if (i === index) {
                return { ...metric, [field]: value };
            }
            return metric;
        });
        setGrowthMetrics(newGrowthMetricsData);
    };

    const updateMetricImpact = (id, impact) => {
        setGrowthMetrics(prevMetrics =>
            prevMetrics.map(metric =>
                metric.id === id ? { ...metric, impact: metric.impact === impact ? null : impact } : metric
            )
        );
    };

    const toggleImpactFilter = (impact) => {
        setSelectedImpact(prevImpact => prevImpact === impact ? null : impact);
    };



    const filteredMetrics = useMemo(() =>
        selectedImpact ? growthMetrics.filter(metric => metric.impact === selectedImpact) : growthMetrics,
        [selectedImpact, growthMetrics]);


    const postGrowthMetricData = async () => {
        try {
            setLoader(true);
            await postGrowthMetricsData({
                projectId: projectId,
                body: {
                    "userid": getUserDetailsFromLocalStorage().user_id,
                    "seg_id": props.seg_id,
                    "metrics": growthMetrics,
                    "tocid": props.id
                }
            }).then((res) => {
                if (res.data.message == 'Successfully saved') {
                    toast.success(res.data.message);
                }
            })
            updateTocIsSaved({ projectId: projectId, tocId: props.id })
            handleSectionSave(props.id)

        } catch (error) {
            console.error(error)
        } finally {
            setLoader(false)
        }

    }

    return (
        <>
            
           
            <div class="h-100 d-flex flex-column gp-editor-wrapper w-100">
                <div class="w-100 h-100 flex-grow-1 position-relative gp-editor">
                    <div class="w-100 h-100 gp-competitorSection gp-editing-content">
                        <div class="h-auto gp-competitor-header">
                            <p>{filteredMetrics?.length} lines found</p>
                            {
                                (isProjectEditable && filteredMetrics.length < 10) && (<button type="button" onClick={addMetric}>
                                    <span class="mb-0">+ Add New</span>
                                </button>)
                            }

                        </div>
                        <div class="gp-metric-body my-4" >

                            {filteredMetrics.map((metric, i) => (
                                <GrowthMetricCard
                                    key={i}
                                    index={i}
                                    metric={metric}
                                    updateMetricName={updateMetricName}
                                    deleteMetric={deleteMetric}
                                    updateMetricImpact={updateMetricImpact}
                                />
                            ))}


                        </div>
                        {isProjectEditable &&
                            <div className='d-flex justify-content-end'>
                                <div class="gp-action-btn-part mt-1">
                                    <button type="button">Reset</button>
                                    <button type="button" class="gp-btn-fill" onClick={postGrowthMetricData} >Save</button>
                                </div>
                            </div>
                        }

                    </div>
                </div>

                <Footer />
            </div>

           
        </>
    );
}

export default GrowthMetrics;