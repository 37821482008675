import React, { useEffect, useState } from 'react';
import Sidebar from './SideBar';
import TopBar from './TopBar';
import '../CSS/FrostSkeleton.css'
import { useLocation } from 'react-router-dom';

const FrostSkeleton = ({ children, userDetails }) => {

    const [collapsed, setCollapsed] = useState(true)
    const [isTopBar, setIsTopBar] = useState("")
    const receiveState = (data) => {
        setCollapsed(!collapsed)
    }
    const location = useLocation();

    useEffect(() => {
        const pathSegment = location.pathname.split('/')[1];
        setIsTopBar(pathSegment);
    }, [location])


    return (
        <div className='main_container'>
            <Sidebar data={collapsed} receiveState={receiveState} />
            <div className="main-content">
                {isTopBar == 'pptvi' ? null : <TopBar sendState={receiveState} userData={userDetails} />}

                {children}

            </div>
            <div className={`overlay ${!collapsed ? "active" : ""}`} onClick={() => { setCollapsed(true) }}></div>
        </div>
    );
};

export default FrostSkeleton;
