import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { DriverRestraintAPiSlice } from '../DriversAndRestraints/Store/dataSlices/DriverAndRestraintAPISlice';
import userReducer from './slices/UserSlice';
import {ProjectPageAPISlice} from './slices/ProjectPageAPISlice'
import {UtilityApiSlice} from './slices/UtilityApiSlice'

const rootReducer = combineReducers({
  [DriverRestraintAPiSlice.reducerPath]: DriverRestraintAPiSlice.reducer,
  [ProjectPageAPISlice.reducerPath]: ProjectPageAPISlice.reducer,
  [UtilityApiSlice.reducerPath]:UtilityApiSlice.reducer,
  user: userReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
    .concat(DriverRestraintAPiSlice.middleware)
    .concat(ProjectPageAPISlice.middleware)
    .concat(UtilityApiSlice.middleware),
   
});

export default store;