import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { useCreateCloneTocMutation, useDeleteCloneMutation, usePostTocDataMutation, useUpdateIsApplicableMutation, useUpdateTocIsSavedMutation } from '../../redux/slices/ProjectPageAPISlice';
import SSOLoader from '../SSOLoader';
import CompetitorsBySection from '../KeyCompetitors/CompetitorsBySection';
import { useParams } from 'react-router-dom';
import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider';
import { useGetDNRDataQuery } from '../../DriversAndRestraints/Store/dataSlices/DriverAndRestraintAPISlice';
import Footer from './Footer';
import DialogueBox from '../../ComponentUtils/DialogBox';
function KeyCompetitors(props) {
    const { isProjectEditable } = useContext(ProjectStatusContext)
    const [postTocData] = usePostTocDataMutation();
    const [headerIsEdit, setHeaderIsEdit] = useState(false);
    const [header, setHeader] = useState("");
    const [loader, setLoader] = useState(false)
    const getUserDetailsFromLocalStorage = () => {
        const userDetailsString = sessionStorage.getItem('userDetails');
        if (userDetailsString) {
            return JSON.parse(userDetailsString);
        } else {
            return null;
        }
    }

    const { projectId } = useParams()
    const { data, isLoading, isError, isSuccess } = useGetDNRDataQuery({
        projectId: projectId, userId: getUserDetailsFromLocalStorage().user_id
    });



    const handleSaveToc = async () => {
        try {
            setLoader(true)
            await postTocData({
                projectId: props?.go_in_id,
                propsId: props?.id,
                body: {
                    "userid": getUserDetailsFromLocalStorage()?.user_id,
                    "description": props?.description,
                    "name": header,
                    "isSaved": 1
                }
            })
            setHeaderIsEdit(false)
            setLoader(false)
            toast.success('Saved Successfully')
        } catch (error) {
            console.error(error);
            setLoader(false)
            toast.error("Something went wrong")
        }
    }

    useEffect(() => {
        setHeader(props?.name)
    }, [props])

    const [nodesArray, setNodesArray] = useState([]);
    useEffect(() => {
        const nodes = [];

        function getNodesFromTree(treeData) {
            if (treeData) {
                const { children, ...nodeWithoutChildren } = treeData;
                nodes.push(nodeWithoutChildren);
                if (children) {
                    for (const child of children) {
                        getNodesFromTree(child);
                    }
                }
            }
        }

        if (isSuccess && data?.initialNodes?.[0]) {
            getNodesFromTree(data.initialNodes[0]);
            setNodesArray(nodes?.filter(ele => ele?.id == props?.seg_id));
        }
    }, [isLoading, isSuccess, data, props]);
    const [createCloneToc, {
        isLoading: isCreateCloneLoading,
    }] = useCreateCloneTocMutation()
    const [deleteClone, { isLoading: isCloneDeleteLoading }] = useDeleteCloneMutation();
    const [updateIsApplicable, { isLoading: isUpdateApplicableLoading }] = useUpdateIsApplicableMutation();
    const handleCreateClone = async () => {
        try {
            createCloneToc({ projectId: projectId, tocId: props.id }).then((res) => toast.success("Clone created"))

        } catch (error) {
            console.error(error)
            toast.error("Something went wrong")
        }
    }

    const handleDeleteClone = async () => {
        try {
            deleteClone({
                projectId: projectId,
                tocId: props.id
            })
            toast.success("Clone successfully deleted")
        } catch (error) {
            console.error(error)
            toast.error("Something went wrong")
        }


    }
    const [isApplicable, setIsApplicable] = useState(props?.isApplicable)
    const handleUpdateIsApplicable = async (crtIsApplicable) => {
        try {
            updateIsApplicable({
                projectId: projectId,
                tocId: props.id,
                isApplicable: crtIsApplicable
            })
        } catch (error) {
            console.error(error);
            toast.error("Something went wrong")
        }
    }

    const handleToggle = () => {
        setIsApplicable(1 - isApplicable)
        handleUpdateIsApplicable(1 - isApplicable)
    };

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState({ title: '', text: '', action: null });

    const handleConfirmation = () => {
        if (dialogContent.action === 'delete') {
            handleDeleteClone();
            // Perform the delete action here
        }
        else if (dialogContent.action === 'clone') {
            handleCreateClone()
        }
        setOpenDialog(false);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const onDelete = () => {
        setDialogContent({
            title: 'Confirm Deletion',
            text: 'Are you sure you want to delete this section?',
            action: 'delete',
        });
        setOpenDialog(true);
    }
    const onCreateClone = () => {
        setDialogContent({
            title: 'Confirm Clone creation',
            text: 'Are you sure you want to clone this section?',
            action: 'clone',
        });
        setOpenDialog(true);
    }

    return (
        <>
            {loader ? <SSOLoader /> : null}
            <div class="w-100 h-100 flex-grow-1 position-relative gp-editor">

                {nodesArray.map((item, i) =>
                    <CompetitorsBySection competitorObj={item} projectData={props} tocId={props.id} />
                )}

                <Footer />
            </div>


            <div class="modal fade" id="myModalkeyC">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header py-2">
                            <h5 class="modal-title">Key Competitors</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                        </div>
                        <div class="modal-body">
                            Adjust for number of segments. Logos cannot be used. Should align with segments in the ecosystem
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default KeyCompetitors;
