import React, { useContext, useEffect, useRef, useState } from 'react';
import Node from './Node';
import { useGetSegmentationTypeQuery, 
  // usePostSegmentationTypeMutation 
} from '../../redux/slices/ProjectPageAPISlice';
import { useParams } from 'react-router-dom';
// import DialogTitle from '@mui/material/DialogTitle';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import Button from '@mui/material/Button';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEditSegmentationNodeMutation, useGetDNRDataQuery } from '../Store/dataSlices/DriverAndRestraintAPISlice';
import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider';
import { SegmentationTreeContext } from '../Provider/SegmentationTreeProvider';

const OddLevelNode = ({ node, onAddChild, onDeleteNode, onNameChange, isForecastCompleted }) => {
  const { isProjectEditable } = useContext(ProjectStatusContext)
  const [nodeId, setNodeId] = useState(node.id);
  const { projectId } = useParams();
  const { data, isLoading, isSuccess } = useGetSegmentationTypeQuery({ projectId: projectId })
  const [editSegmentationNode] = useEditSegmentationNodeMutation();

  const { data: DnrData, error, isError, isLoading:isDNRdataLoading, isSuccess:isDNRdataSuccess, } = useGetDNRDataQuery({ projectId, userId: getUserDetailsFromLocalStorage().user_id });
  const {isNodeEditable}=useContext(SegmentationTreeContext)


  const [segmentName,SetSegmentName]=useState(node.name)

  useEffect(()=>{
    SetSegmentName(node.name)
  },[data,DnrData,isLoading,node])

  /* const [customSegment, setCustomSegment] = useState('');
  // const [postSegmentationType] = usePostSegmentationTypeMutation();
  // const [segmentList, setSegmentList] = useState([]);
  // const [selectSegment, setSelectSegment] = useState(node.name === 'Add Segment' ? '' : node.name);
  // const [availableSegments,setAvailableSegments]=useState([]);
  // const [open, setOpen] = useState(false);
  // const handleChange = (event) => {
  //   const siblings = node.parent ? node.parent.children : [node];
  //   const usedSegments = siblings.map(sibling => sibling.name);

  //   if(usedSegments.includes(event.target.value)){
  //     toast.error("Cannot select similar values at same level, Add a new segment");
  //     return;
  //   }
  //   setSelectSegment(event.target.value);
  // };

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // }
  // useEffect(() => {
  //   if (isSuccess) {
  //     const allSegments = data.data.map((item) => ({ id: item.id, name: item.name }));
  //     setSegmentList(allSegments);
  //     setNodeId(node.id);
      
  //     const nodesAtTargetLevel = findNodesAtLevel(DnrData?.initialNodes, node.level, node.source);
      
  
  //     // Filter out segments used at this level, but include the current node's name
  //     const availableSegs = allSegments.filter(segment => 
  //       !nodesAtTargetLevel.includes(segment.name) || segment.name === node.name
  //     );
      
  //     setAvailableSegments(availableSegs);
  //   }
   }, [isLoading, data, isSuccess, node]);
   */

  const findNodesAtLevel = (nodes, targetLevel, targetSource) => {
    const nodesAtLevel = [];

    const traverse = (node) => {
        if (node.level === targetLevel && node.source===targetSource) {
            nodesAtLevel.push(node.name);
        }
        (node.children || []).forEach(child => traverse(child));
    };

    nodes.forEach(node => traverse(node));

    return nodesAtLevel;
};




  const handleEditSegmentName = async () => {
    if (segmentName.length == 0) {
      toast.error("Please write a segment name")
      return
    }
  const nodesAtTargetLevel = findNodesAtLevel(DnrData?.initialNodes, node.level, node.source);

  
  if (nodesAtTargetLevel.includes(segmentName) && segmentName !== node.name) {
    toast.error("This segment is already used at this level. Please choose a different name.")
    return
  }
  if(node.name===segmentName) return
    const reqObject = {
      id: nodeId,
      user_id: getUserDetailsFromLocalStorage().user_id,
      name: segmentName,
      segment_type_id: data?.data.find((item) => item.name === segmentName)?.id,
      source:node.source
    }
    editSegmentationNode({
      projectId: projectId,
      body: reqObject
    }).then(response => {
    })
      .catch(error => {
        console.log(error);
      })
      // .finally(() => {
      //   setOpen(false)
      // })
  }

  const handleAddSegment = () => {
    if (segmentName.length === 0) {
      toast.error("Segment name cannot be empty")
      return
    }
    if (segmentName.length < 2) {
      toast.error("Minimum length of a segment should be 2")
      return
    }
    const nodesAtTargetLevel = findNodesAtLevel(DnrData?.initialNodes, node.level, node.source);
    
    const isDuplicate = nodesAtTargetLevel.includes(segmentName) 
    

    if (isDuplicate && nodeId!=node.id) {
        toast.error("This segment already exists at this level. Please choose a different name.");
        return
    }
    
    // try {
    //   postSegmentationType({
    //     projectId: projectId,
    //     body: {
    //       userid: getUserDetailsFromLocalStorage().user_id,
    //       name: customSegment
    //     }
    //   })
    //   toast.success("Custom segment added")
    // } catch (error) {
    //   console.log(error)
    // } finally {
    //   setCustomSegment('')
    // }

  }
  const inputNodeRef=useRef(null)
  const [openTextfield,setOpenTextField]=useState(false)

  const onFocusOutHandler = async() => {
    setOpenTextField(false)
    handleAddSegment()
    handleEditSegmentName().finally(()=>{
      SetSegmentName(node.name)
    })
    
  }
  return (
    <div className='org-node'>
      <div className='org-details'>
        <div className={((!isForecastCompleted&&isProjectEditable) || isNodeEditable? 'd-flex justify-content-between align-items-center' : 'text-center')}>
          {(!isForecastCompleted&&isProjectEditable) || isNodeEditable? <i onClick={() => onAddChild(node.id)} title='Add Segment' style={{fontSize:"1.6rem"}} className="fa fa-plus-circle me-1"></i>:null}
          <div className=''>
            <span className='nodename'>
            {openTextfield?
              <input 
                ref={inputNodeRef}
                value={segmentName} 
                onBlur={onFocusOutHandler}
                onChange={(e)=>SetSegmentName(e.target.value)}
                className="form-control form-control-sm"
                placeholder="Add a segment"
                autoFocus
              />
              :node.name} 
              {((!isForecastCompleted && isProjectEditable)||isNodeEditable)? 
                !openTextfield?<i title='Edit Segment' className='fa fa-edit ms-1 position-relative' onClick={()=>setOpenTextField(true)} style={{ cursor: 'pointer',top:'1px' }}></i>:null
              :null}</span>
              
            {/* <Dialog
              open={open}
              onClose={handleClose}

            >
              <DialogTitle>Create segment</DialogTitle>
              <DialogContent>
                {segmentList.length ?<DialogContentText>
                  Select Segment
                </DialogContentText>:null}
                <div className='flex-col mt-2'>

                  {segmentList.length ? <select
                    value={selectSegment}
                    onChange={handleChange}
                    className='form-select form-select-sm'

                    required

                  >
                    <option value='' disabled hidden>Select Segmentation</option>
                    {isSuccess && availableSegments.map((item, i) => {
                      return <option key={i} value={item.name}>
                        {item.name}
                      </option>
                    })}

                  </select> : null}
                  <DialogContentText className='mt-2'>
                    Add Segment
                  </DialogContentText>
                  <div className="d-flex align-items-center my-2">
                    <div className="flex-grow-1">
                      <input
                        type="text"
                        value={customSegment}
                        onChange={(e) => setCustomSegment(e.target.value)}
                        className="form-control form-control-sm"
                        placeholder="Add custom segment"
                      />
                    </div>
                    <div className="ms-2"> 
                      <button className="btn btn-primary btn-sm" onClick={handleAddSegment}>
                        Add Segment
                      </button>
                    </div>
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit" onClick={handleEditSegmentName}>Confirm</Button>
              </DialogActions>
            </Dialog> */}
          </div>
          {(!isForecastCompleted&&isProjectEditable) || isNodeEditable ? <i onClick={() => onDeleteNode(node.id)} title='Remove Segment' style={{fontSize:"1.6rem"}} className="fa fa-minus-circle ms-1"></i>:null}
        </div>
      </div>
      {node?.children?.length > 0 && (
        <div className='org-children'>
          {node.children.map((child) => (
            <Node
              key={child.id}
              node={child}
              onAddChild={onAddChild}
              onDeleteNode={onDeleteNode}
              onNameChange={onNameChange}

            />
          ))}
        </div>
      )}

    </div>
  );
};

export default OddLevelNode;
