import React, { useState, useEffect, useRef } from 'react';
import { useGetDNRDataQuery } from '../../DriversAndRestraints/Store/dataSlices/DriverAndRestraintAPISlice';
import DisplayDandR from './Components/DisplayD&R';
import OthersEditableDandR from './Components/OtherEditableD&R';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import Footer from './Footer';
import { useGetDrDataQuery } from '../../redux/slices/ProjectPageAPISlice';
function GrowthPriceRestraints(props) {
    const [priceRestraints, setpriceRestraints] = useState({});
    const [years, setYears] = useState([]);
    const [type, setType] = useState("dr");
    const projectId = props?.go_in_id;
    const { data: DnrData, error, isError, isLoading, isSuccess, } = useGetDNRDataQuery({ projectId, userId: getUserDetailsFromLocalStorage().user_id });
    const { data: DrData, refetch } = useGetDrDataQuery({ id: props?.go_in_id, seg_id: DnrData?.initialNodes[0]?.id, user_id: getUserDetailsFromLocalStorage().user_id });


    useEffect(() => {
        if (typeof (DrData) != "undefined") {
            setType(DrData?.data?.dr_type)
            if (DrData?.drivers_restrainsts_data?.priceRestraints) {
                setpriceRestraints(DrData?.drivers_restrainsts_data?.priceRestraints)
            } else { setpriceRestraints({}) }
        }
    }, [DrData])

    useEffect(() => {
        if (Object.keys(priceRestraints).length !== 0) {
            setYears(Object.keys(Object.values(priceRestraints)[0]))
        }
    }, [priceRestraints])

    return (
        <>
            <div class="h-100 d-flex flex-column gp-editor-wrapper w-100">
                <div class="w-100 h-100 flex-grow-1 position-relative gp-editor">
                    <div class="w-100 h-100 gp-competitorSection gp-editing-content">
                        <div class="gp-metric-body my-4" >

                            {
                                type == "other" ? <OthersEditableDandR allData={DrData} years={years} refetch={() => { refetch() }} toc={props} type="priceRestraints" /> :
                                    <DisplayDandR unitDrivers={priceRestraints} years={years} type="restraints" />
                            }
                        </div>

                    </div>
                </div>

                <Footer />
            </div>
        </>

    )
}

export default GrowthPriceRestraints
