import React,{ useCallback, useEffect,useState }from 'react'
import { useDeleteTocThemeMutation, useGetThemeQuery, useSaveTocThemeMutation, useSortTocThemeMutation } from '../../redux/slices/UtilityApiSlice';
import TableRow from './TableRow';
import './Styles.css'
import { toast } from 'react-toastify';

const ThemeTable = ({selectedTheme}) => {
    const {data,isSuccess,isLoading,refetch}=useGetThemeQuery({selectedTheme:selectedTheme});
    const [saveTocTheme]=useSaveTocThemeMutation();
    const [sortTocTheme]=useSortTocThemeMutation();
  const[tocThemeList,setTocThemeList]=useState(data?.data||[]);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [draggedChildIndex,setDraggedChildIndex]=useState(null);
  const [deleteTocTheme]=useDeleteTocThemeMutation();

  const handleDragStart = (index, childIndex = null) => {
    setDraggedIndex(index);
    setDraggedChildIndex(childIndex);
    
};

const handleDrop = useCallback((index, targetChildIndex = null) => {
  const updatedList = [...tocThemeList];
  if (draggedChildIndex === null && targetChildIndex === null && draggedIndex !==index) {
          const [draggedRow] = updatedList.splice(draggedIndex-1, 1);
          updatedList.splice(index-1, 0, draggedRow);
          setTocThemeList(updatedList);
          tocSortHandler(updatedList);
  }
  
  
  else if (draggedChildIndex !== null && targetChildIndex !== null && parseInt(draggedIndex.split('.')[1]) !== parseInt(index.split('.')[1])) {
      
      const draggedChildIndexN = Number(draggedChildIndex);
      const targetChildIndexN = Number(targetChildIndex);
      
      const deepCloneUpdatedList = JSON.parse(JSON.stringify(updatedList)) ;
      const parentIndex = parseInt(draggedIndex.split('.')[0] - 1);
      const parent = deepCloneUpdatedList[parentIndex];
      const draggedChild=parent.children[draggedChildIndexN]
      if (!parent || !parent.children) {
          console.error('Invalid parent or children array');
          return;
      }
      if (
          draggedChildIndexN < 0 || 
          draggedChildIndexN >= parent.children.length ||
          targetChildIndexN < 0 || 
          targetChildIndexN > parent.children.length
        ) {
          console.error('Child elements out of bound error');
          toast.error('Child elements can be moved only within the same Parent')
          return
        }
        
      const newChildren = [...parent.children];
      newChildren.splice(draggedChildIndexN, 1);
      newChildren.splice(targetChildIndexN, 0, draggedChild);
      parent.children = newChildren;
      setTocThemeList(deepCloneUpdatedList);
      tocSortHandler(deepCloneUpdatedList)
  } 

  setDraggedIndex(null);
  setDraggedChildIndex(null);
},[tocThemeList,draggedIndex,draggedChildIndex]);

  async function tocSortHandler(updatedList) {
    await sortTocTheme({
        theme:selectedTheme,
        body:{data:updatedList}
    }).catch((err)=>console.error(err))
  }

  // Handle drag over event (to allow drop)
  const handleDragOver = (e) => {
    e.preventDefault(); // Allow dropping
  };


 useEffect(()=>{
  if(isSuccess){
    setTocThemeList(data?.data||[]);
    
  }
 
 },[isLoading,isSuccess,data]) 


 const handleAddRow = (index) => {


    const parentItem = tocThemeList[index - 1]; 


    if (!parentItem.children) {
        parentItem.children = [];
    }

    const newRow = {
        id: `temp-${Date.now()}`,
        parent_id: parentItem.id,
        parent: "", // Yes
        title: "New Item",
        subtitle: "",
        enabled: "1",
        sort: parentItem.sort + 1,
        seg_id: "",
        page_type: "index",
        isView: 0,
        isApplicable: 0,
        isClone: 0,
        isMandatory: 0,
        isSegment: 0,
        theme: selectedTheme,
        status: 1,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        children: []
    };

    
    const updatedList = [...tocThemeList];
    updatedList[index - 1] = {
        ...parentItem,
        children: [...parentItem.children, newRow] 
    };

    
    setTocThemeList(updatedList);
    
    
    addNewRow(newRow);
};
  async function addNewRow(newRow){
    
    await saveTocTheme({
        status:'save',
        tocId:newRow.parent_id,
        body:{
          parent_id: newRow.parent_id,
          parent: newRow.parent,
          title: newRow.title,
          subtitle: newRow.subtitle,
          enabled: newRow.enabled,
          sort: newRow.sort,
          seg_id: newRow.seg_id,
          page_type: newRow.page_type,
          isView: newRow.isView,
          isApplicable: newRow.isApplicable,
          isClone: newRow.isClone,
          isMandatory: newRow.isMandatory,
          isSegment: newRow.isSegment,
          theme: newRow.theme,
          status: newRow.status,
          created_at: newRow.created_at,
          updated_at: newRow.updated_at,
          children: newRow.children
      }
    }).then(()=>refetch()).catch((err)=>{
        console.error(err)
    })
  }

  const deleteItemById = (list, id) => {
    return list
        .filter(item => item.id !== id) 
        .map(item => ({
            ...item,
            children: item.children ? deleteItemById(item.children, id) : [] 
        }));
};
  const onDeleteRow=async(id)=>{
    if(!id){
      toast.error('Something went wrong')
      return
    }
    else{
      if(id.startsWith("temp-")){
        setTocThemeList((prev)=>deleteItemById(prev,id))
      }
      else {
        deleteTocTheme({tocId:id}).then((res)=>{
          setTocThemeList((prev)=>deleteItemById(prev,id))
        }).catch((err)=>console.error(err))
        
      }
    }
  }
  return (
    <div >
    <div className='table-wrapper m-3'>
        <table className='table-themeToc table table-border-none table-striped table-lg text-center'>
            <thead >
                
                <tr >
                    <th scope="col" className='align-middle'>#</th>
                    <th scope="col" className='align-middle'>Title</th>
                    <th scope="col" className='align-middle'>Action</th>
                    <th scope="col" className='align-middle'>View</th>
                    <th scope="col" className='align-middle'>Applicable</th>
                    <th scope="col" className='align-middle'>Clone</th>
                    <th scope="col" className='align-middle'>Mandate</th>
                    <th scope="col" className='align-middle'>Status</th>
                </tr>
            </thead>
            <tbody className='firsttable'>
                {tocThemeList.map((item,i)=>{
                
                return <TableRow 
                    key={item.id||`temp-${Date.now()}`} 
                    item={item} 
                    index={i+1} 
                    onDragStart={handleDragStart}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onAddRow={handleAddRow}
                    parentIndex={i}
                    childIndex={null}
                    onDelete={onDeleteRow}
                    />
                })}
            </tbody>
        </table>
    </div>
</div>
  )
}

export default ThemeTable