import React, { useContext, useEffect, useState } from 'react'
import { useGetCriteriaDataQuery, useGetGrowthUniverseDataByIdQuery } from '../../redux/slices/ProjectPageAPISlice';
import { useParams } from 'react-router-dom'
import GouTabsComponent from '../GrowthOppUniverse/GouTabsComponent'
import GouWithOutTabs from '../GrowthOppUniverse/GouWithOutTabs'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { toast } from 'react-toastify'
import { usePostTocDataMutation } from '../../redux/slices/ProjectPageAPISlice';
import SSOLoader from '../SSOLoader'
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import Footer from './Footer';
import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider';
function GrowthOpportunityUniverse(props) {
    const [loader, setLoader] = useState(false)
    const { isProjectEditable } = useContext(ProjectStatusContext)
    const { projectId } = useParams();
    const { data, isError } = useGetCriteriaDataQuery();
    const { data: growthUniverseDatabyId, isLoading, isSuccess } = useGetGrowthUniverseDataByIdQuery({ projectId: projectId, goInId: '' })
    const [gouDatabyProject, setGrowthDataByProject] = useState([])
    const [value, setValue] = useState(0)
    const handleChange = (newValue) => {
        setValue(newValue);
    };
    const handleAddGoin = () => {
        const newTab = { name: 'New Tab', id: '' };
        setGrowthDataByProject([...gouDatabyProject, newTab]);
        setValue('');
    };
    const [postTocData] = usePostTocDataMutation();

    const [headerIsEdit, setHeaderIsEdit] = useState(false);
    const [header, setHeader] = useState("");

    const handleSaveToc = async () => {
        try {
            setLoader(true)
            await postTocData({
                projectId: props?.go_in_id,
                propsId: props?.id,
                body: {
                    "userid": getUserDetailsFromLocalStorage()?.user_id,
                    "description": props?.description,
                    "name": header,
                    "isSaved": 1
                }
            })
            setHeaderIsEdit(false)
            setLoader(false)
            toast.success('Saved Successfully')
        } catch (error) {
            console.log(error);
            setLoader(false)
            toast.error("Something went wrong")
        }
    }


    useEffect(() => {
        setHeader(props?.name)
    }, [props])


    useEffect(() => {
        setGrowthDataByProject(growthUniverseDatabyId?.data ? growthUniverseDatabyId?.data : []);
        setValue(growthUniverseDatabyId?.data?.[0]?.id || 0)
    }, [isLoading, growthUniverseDatabyId, isSuccess]);

    return (
        <>
            {loader ? <SSOLoader /> : null}

            <div class="w-100 h-100 flex-grow-1 position-relative gp-editor">
                <div class="w-100 h-100 gp-formSection gp-editing-content">
                    <div className='d-flex justify-content-between '>
                        <ul class="gp-formula-selector-gou">
                            {gouDatabyProject?.map(e => <li key={e?.id} className={value == e?.id && 'active'} onClick={() => { handleChange(e?.id) }} value={e?.id} >{e?.name} </li>)}
                        </ul>
                        {isProjectEditable ? <div class="gp-action-btn-part mt-1">
                            <button type="button" onClick={handleAddGoin} class="gp-btn-fill">Add</button>
                        </div> : null}
                    </div>
                    {gouDatabyProject?.length ?
                        <>
                            {gouDatabyProject?.map((item, i) => {
                                if (item?.id == value) {
                                    return <GouTabsComponent goInid={item.id}
                                        growthUniverseData={data?.data} tocId={props.id} />
                                }
                            })}</>
                        : <GouWithOutTabs goInid='' growthUniverseData={data?.data} tocId={props.id} />}


                </div>
            </div>
            <Footer />

        </>
    )
}

export default GrowthOpportunityUniverse