import { Box, Link, Button, Modal, Typography } from '@mui/material';
import React, { useRef } from 'react'
import axios from 'axios';
import { useState, useEffect } from 'react'
import './Styles/dashboard.css'
import '../layout/CSS/TopBar.css'
import '../assets/css/bootstrap.min.css'
import '../assets/css/style.css'
import '../assets/css/../../assets/css/mediaquery.css'
import CreateProject from './CreateProject';
import { getUserDetailsFromLocalStorage } from '../Utils/userUtils';
import search from '../assets/images/search.svg'
import create from '../assets/images/create.svg'
//For DataTable
import DataTableReact from './DataTableReact';
import PaginationComponent from './PaginationComponent';



function MyProjects() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState('draft');
  const [practiceArea, setPracticeArea] = useState([])
  const [programArea, setProgramArea] = useState([])



  const [filters, setFilters] = useState({
    GO_IN_Type: [{ id: "All", name: "All" }, { id: "1", name: "Growth Opportunity Industry" },
    { id: "2", name: "Growth Opportunity 3-WEEKS-MAX" },{ id: "3", name: "Growth Opportunity- Outlook" }
    ],
    Practice_Area: [],
    Program_Area: [],
  });

  const [appliedFilters, setAppliedFilters] = useState({
    GO_IN_Type: '',
    Status: '',
    Practice_Area: '',
    Program_Area: '',
  });
  const fetchData = async (path, reqStatus, role_type, user_id) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/${path}`, {
        params: {
          status: reqStatus,
          role_type: role_type,
          user_id: user_id
        },
        headers: {
          "Content-Type": "application/json"
        }
      });

      if (response.status === 200) {
        const result = response.data;
        console.log(response.data);

        return result;
      } else {
        return {};
      }
    } catch (error) {
      console.log("There was an error!", error);
      return {}
    }
  };

  const [sectionData, setSectionData] = useState([
    { name: 'All', value: '-', slug: 'All', projects: [] },
    { name: 'Draft', value: '-', slug: 'draft', projects: [] },
    { name: "Returned To Analyst", value: '-', slug: 'toAnalyst', projects: [] },
    { name: "Project Manager", value: '-', slug: 'toPm', projects: [] },
    { name: "Quality Control", value: '-', slug: 'toEditor', projects: [] },
    { name: "Electronic Publishing", value: '-', slug: 'toQc', projects: [] },
    { name: "Published", value: '-', slug: 'published', projects: [] },
  ])
  const [isModalVisible, setIsModalVisible] = useState(false);
  const openModal = () => {
    setIsModalVisible(true);
  }
  const closeModal = () => {
    setIsModalVisible(false)
  };
  const handleClick = (event) => {
    event.preventDefault();
    const slug = event.target.value;
    const index = sectionData.findIndex((item) => item.slug === slug);
    setTableData(sectionData[index]?.projects)
    setSelectedStatus(slug);
    setActiveIndex(index);
  }
  const getPracticeArea = async () => {
    try {
      let data = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/getindustry`)
      setFilters(prevFilters => ({
        ...prevFilters,
        Practice_Area: data?.data?.data
      }));
      setPracticeArea(data?.data?.data)
    } catch (error) {
      console.log(error)
    }
  }
  const getProgramArea = async (id, name) => {
    try {
      let data = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/getsector/${id}/`)
      setFilters(prevFilters => ({
        ...prevFilters,
        Program_Area: data?.data?.data
      }));
      setProgramArea(data?.data?.data)
    } catch (error) {
      console.log(error)
    }
  }
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name == 'Practice_Area') {
      getProgramArea(value)
      setAppliedFilters({
        ...appliedFilters,
        [name]: value,
        Program_Area: ''

      });
    } else {
      setAppliedFilters({
        ...appliedFilters,
        [name]: value,
      });
    }

  };

  const clearFilters = () => {
    setAppliedFilters({
      GO_IN_Type: '',
      Status: '',
      Practice_Area: '',
      Program_Area: '',
    })
    setSearchQuery('')
    setDrop(false)
  };
  useEffect(() => {
    if (practiceArea.length == 0) {
      getPracticeArea()
    }
  }, [practiceArea])

  //For DataTable

  const [drop, setDrop] = useState(false)
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [tableData, setTableData] = useState([])
  const [userDetails, setUserDetails] = useState([])

  const data = tableData

  const categorizeProjects = (data) => {
    const slugToIndex = sectionData.reduce((acc, section, index) => {
      acc[section.slug] = index;
      return acc;
    }, {});

    const updatedSectionData = sectionData.map(section => ({ ...section, projects: [] }));

    data.forEach(project => {
      updatedSectionData[0].slug == 'All' && updatedSectionData[0].projects.push(project)
      const status = project.status || 'draft'; // Default to 'draft' if status is missing
      const sectionIndex = slugToIndex[status] !== undefined ? slugToIndex[status] : slugToIndex['All'];

      updatedSectionData[sectionIndex].projects.push(project);

    });

    return updatedSectionData;
  };


  const fullTableData = async () => {
    try {
      let resp = await fetchData('listproject', 'All', getUserDetailsFromLocalStorage()?.role, getUserDetailsFromLocalStorage()?.user_id);
      setSectionData(categorizeProjects(resp.data))
      setTableData(categorizeProjects(resp.data)[0].projects)
      setUserDetails(resp.userdetails)

    } catch (error) {
      console.log(error);

    }
  }

  useEffect(() => {
    fullTableData()
  }, [])

  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortData = (array, comparator) => {

    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getUserDetails = (id, userArr) => {
    const res = userArr.find(e => e.id === id);
    return res ? `${res.first_name} ${res.last_name}` : '';
  };


  let filteredData = data


  if (appliedFilters.GO_IN_Type.length > 0) {
    if (appliedFilters.GO_IN_Type == 'All') {
      filteredData = data
    } else {
      filteredData = data.filter((e) => e.go_in_type == appliedFilters.GO_IN_Type)

    }
  }
  if (appliedFilters.Practice_Area.length > 0) {
    filteredData = data.filter((e) => e.industry == appliedFilters.Practice_Area)
  }
  if (appliedFilters.Program_Area.length > 0) {
    filteredData = data.filter((e) => e.sector_id == appliedFilters.Program_Area)
  }

  if (searchQuery) {
    filteredData = filteredData.filter((row) => {
      const titleName = row?.title_name?.toLowerCase() || '';
      const republishStatus = (row?.isrepublished == '0' ? "New" : "Republished").toLowerCase();
      const goInType = (row?.go_in_type == '1' ? "GO-IN - Industry" : row?.go_in_type == "2"? 'GO-3-WEEKS-MAX':'GO-Outlook').toLowerCase();
      const createdBy = getUserDetails(row.created_by, userDetails).toLowerCase();
      const createdAt = new Date(row.created_at).toLocaleDateString('en-GB').replace(/\//g, '-').toString();
      const updatedAt = new Date(row.updated_at).toLocaleDateString('en-GB').replace(/\//g, '-').toString();

      const query = searchQuery.toLowerCase().trim();

      return (
        titleName.includes(query) ||
        republishStatus.includes(query) ||
        goInType.includes(query) ||
        createdBy.includes(query) ||
        createdAt.includes(query) ||
        updatedAt.includes(query)
      );
    });
  }



  const sortedData = sortData(filteredData, getComparator(order, orderBy));
  const paginatedData = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const dropdownRef = useRef(null);

  useEffect(() => {
      // Function to close dropdown if clicked outside
      const handleClickOutside = (event) => {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
              setDrop(false);
          }
      };

      // Attach event listener to detect outside clicks
      document.addEventListener('mousedown', handleClickOutside);
      
      // Cleanup listener on component unmount
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
  }, [drop]);

  ////////////////////////////

  return (
    <div className='container-fluid'>
      <div class="w-100 h-100 flex-grow-1">
        <div class="w-100 h-100 d-flex flex-column">
          <div class="w-100 h-auto">
            <div class="w-100 h-auto d-flex justify-content-between gp-tabBar">
              <div class="h-auto gp-select ">
                <select class="w-100 go-prj-search" onChange={(e) => handleClick(e)} >

                  {sectionData.map(({ name, projects, slug }, index) => (
                    <option key={index} value={slug} >{name}&nbsp;({projects?.length ? projects?.length : 0})</option>
                  ))}
                </select>
              </div>
              <div class="h-auto position-relative gp-search">
                <div class="w-100 d-flex justify-content-between">
                  <input type="text" placeholder="Search" class="flex-grow-1 border-0 bg-transparent gp-search-input " value={searchQuery}
                    onChange={handleSearch} onFocus={() => setDrop(true)} />
                  <button class="gp-search-btn" type="button">
                    <img class="img-fluid" src={search} alt="search" />
                  </button>
                </div>
                <div ref={dropdownRef} class={`position-absolute w-100 top-100 flex-column gp-searchOption ${drop ? 'show' : ''} `}>
                  <div class="flex-grow-1 overflow-y-auto gp-select-wrapper ">
                    <div class="h-100 d-flex flex-wrap">

                      {Object.keys(filters).map((filterKey, index) => (

                        <select
                          key={filterKey}
                          className="w-100 gp-select go-prj-search"
                          name={filterKey}
                          value={appliedFilters[filterKey]}
                          onChange={handleFilterChange}
                          disabled={(filterKey === "Program_Area" && appliedFilters?.Practice_Area == '')}
                        >
                          {filterKey != 'GO_IN_Type' ?
                            <option  >
                              {filterKey.charAt(0).toUpperCase() + filterKey.slice(1)}
                            </option> : null}

                          {filters[filterKey]?.map((e, index) => <option key={e?.id} value={e?.id}>{e?.name}</option>)}

                        </select>

                      ))}

                    </div>
                  </div>
                  <div class="d-flex align-items-center justify-content-end gp-search-action">
                    <button type="button" class='gp-btn-active' onClick={() => clearFilters()}>Reset</button>
                    <button class="button btn-danger" onClick={() => setDrop(false)} type="button">Close</button>
                  </div>
                </div>
              </div>
              <div class="h-auto gp-create-project-btn">
                <button class="w-100 h-100 d-flex align-items-center create-go-button" onClick={openModal}>
                  <div class="gp-create-img">
                    <img class="img-fluid" src={create} alt="create project" />
                  </div>
                  Create Project
                </button>
              </div>
            </div>
          </div>
          {/* <div class="w-100 flex-grow-1 gp-project-table-section">
            <div class="w-100 h-100 d-flex flex-column position-relative">

            </div>
          </div> */}
          <div class="w-full flex-grow-1 overflow-y-auto dataTableProject">
            <DataTableReact
              data={paginatedData}
              userDetails={userDetails}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <PaginationComponent
              count={filteredData.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

          </div>
        </div>
      </div>

      <CreateProject reload={() => { clearFilters() }} isVisible={isModalVisible} onClose={closeModal} />
    </div>


  )
}

export default MyProjects

