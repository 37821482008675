import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'
import "./Styles/OrgChart.css"
import SSOLoader from './SSOLoader';
import OtherSplitModel from '../OtherSplitModel';
import ExcelUploader from '../ExcelUploader';
import '../assets/css/style.css'
import '../assets/css/bootstrap.min.css'
import '../assets/css/mediaquery.css'
import '../../src/layout/CSS/TotalMarketAnalysis.css'
import instructionImg from '../assets/images/instruction.svg'

function OtherModels({ parentNode_Id, other_data, dnrDataSet, reload }) {

    const [textareaValue, setTextareaValue] = useState("");
    const [loader, setLoader] = useState(false)
    const [tableData, setTableData] = useState([]);
    const [isTableView, setIsTableView] = useState(false)
    const [noOfRows, setNoOfRows] = useState(0)
    const [baseYear, setBaseYear] = useState(0)
    const [fromYear, setFromYear] = useState(0)
    const [toYear, setToYear] = useState(0)
    const [splitOpen, setSplitOpen] = useState(false)
    const [splitHeadings, setSplitHeadings] = useState([])
    const id = window.location.href.split('/').pop()
    const findMaxLength = (arrayOfArrays) => {
        let maxLength = 0;

        arrayOfArrays.forEach(subArray => {
            if (subArray.length > maxLength) {
                maxLength = subArray.length;
            }
        });

        return maxLength;
    };
    const getUserDetailsFromLocalStorage = () => {
        const userDetailsString = sessionStorage.getItem('userDetails')
        if (userDetailsString) {
            return JSON.parse(userDetailsString)
        } else {
            return null
        }
    }
    const fillExtraArray = (arr, len) => {
        // Check the current length of the array
        const currentLength = arr.length;

        // If the array has fewer than 6 elements, add placeholder elements
        if (currentLength <= noOfRows + 1) {
            for (let i = currentLength; i < noOfRows + 1; i++) {
                arr.push(new Array(len).fill(""));
            }
        }
        for (let i = 0; i < arr.length; i++) {
            while (arr[i].length < len) {
                arr[i].push('');
            }
        }
        return arr;
    };
    const generateTable = () => {
        if (textareaValue.split("\n").length > noOfRows + 1) {
            return toast.error(`maximum ${noOfRows + 1}  rows`)
        }
        let tempArr = textareaValue.split("\n").map((row, index) => row.split("\t"))
        setTableData(fillExtraArray(tempArr, findMaxLength(tempArr)))
        setIsTableView(true)
    }

    const generateString = () => {
        setTextareaValue(tableData.map(row => row.join("\t")).join("\n"))
        setIsTableView(false)
    }
    const getProjectDetails = async () => {
        const url = window.location.href
        const segments = url.split('/').pop()
        const id = segments
        try {
            let data = await axios.get(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/project/${id}`
            )
            setBaseYear(parseInt(data?.data?.data?.base_year))
            setFromYear(parseInt(data?.data?.data?.study_from))
            setToYear(parseInt(data?.data?.data?.study_to))
            setNoOfRows((data?.data?.data?.study_to - data?.data?.data?.study_from) + 1)
        } catch (error) {
            console.log(error)
        }
    }

    const saveData = async () => {
        try {
            setLoader(true)
            const years = Array.from({ length: noOfRows }, (_, i) => fromYear + i);
            const fields = tableData[0];
            const result = [];

            for (let i = 0; i < years.length; i++) {
                const yearData = {};
                for (let j = 0; j < fields.length; j++) {
                    let value = parseFloat(tableData[i + 1][j]);
                    if (!isNaN(value)) {
                        yearData[fields[j]] = value;
                    } else {
                        yearData[fields[j]] = "";
                    }

                }
                result.push({ [years[i]]: yearData });
            }

            let postData = {
                user_id: getUserDetailsFromLocalStorage()?.user_id,
                seg_id: parentNode_Id,
                dr_type: 'other',
                other_data: result,
                fields: fields
            }
            let response = await axios.post(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${id}/saveOthermodel/`, postData)
            reload()
            setLoader(false)
            if (response?.status == 200) {
                toast.success('External Model data saved')
            } else {
                toast.error('Something went wrong')
            }
        } catch (error) {
            console.log(error);
            setLoader(false)
        }
    }

    const handleTableChanges = (e, pindex, chlidIndex) => {
        let tempTable = [...tableData];
        tempTable[pindex][chlidIndex] = e.target.value;
        setTableData(tempTable)
    }
    useEffect(() => {
        getProjectDetails()
    }, [])
    useEffect(() => {
        if (typeof other_data != 'undefined') {
            let otherData = other_data?.data?.other_data || []
            const firstYearData = otherData?.[0]?.[Object.keys(otherData?.[0] || {})?.[0]] || [];
            const headers = Object.keys(firstYearData);
            let headArr = headers.map((e) => ({ name: e, value: e }))
            setSplitHeadings(headArr)
            const result = [];
            result.push(headers);

            otherData.forEach(obj => {
                const yearData = Object.values(obj)[0];
                const row = headers.map(header => yearData[header]);
                result.push(row.map(val => val));
            });

            setTableData(result)
            if (otherData.length) {
                setIsTableView(true)
            }
            if (other_data?.data?.split_model == 1) {
                setSplitOpen(true)
            }
        }
    }, [other_data])
    return (
        <>
            {loader && <SSOLoader />}
            <div className='gp-external-models gp-tab-segmentContent'>
                <div className='gp-external-models-section gp-heading-generate'>
                    <div class="header">
                        <button type="button" data-bs-toggle="modal" data-bs-target="#myModalOther">
                            <img src={instructionImg}
                                alt="instruction" />
                        </button>
                    </div>
                    {
                        !isTableView && <div className='gp-table-wrapper'><table >
                            <tbody>
                                <tr>
                                    <td><span>Heading</span></td>
                                    <td rowSpan={noOfRows + 1} colSpan={2} >
                                        <textarea className='form-control form-control-sm ' value={textareaValue}
                                            onChange={(e) => setTextareaValue(e.target.value)} rows={noOfRows + 4} >
                                        </textarea>
                                    </td>
                                </tr>
                                {Array.from({ length: noOfRows }, (_, i) => fromYear + i).map((e) => <tr>
                                    <td><span>{e}</span></td>
                                </tr>)}

                            </tbody>
                        </table></div>
                    }

                    {
                        (isTableView && tableData.length) && (
                            <>
                                <div className='table-responsive'>
                                    <table className='table table-sm table-bordered table-striped text-center otherTable-excelToHtml'>
                                        <thead>
                                            {tableData?.map((ele, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <tr>
                                                            <th className='align-middle' style={{ minWidth: "80px" }}>Years</th>
                                                            {ele.map((data) => (
                                                                <th>{data}</th>
                                                            ))}
                                                        </tr>
                                                    )
                                                }
                                            })}
                                        </thead>
                                        <tbody>
                                            {tableData?.map((ele, index) => {
                                                if (index !== 0) {
                                                    return (
                                                        <tr>
                                                            <td className='align-middle' style={{ minWidth: "80px" }}>{Array.from({ length: noOfRows }, (_, i) => fromYear + i)?.[index - 1]}</td>
                                                            {ele.map((data, index2) => (
                                                                <td ><input type='text' onInput={(e) => { handleTableChanges(e, index, index2) }} className='form-control form-control-sm other-models-table' style={{ fontSize: '1.6rem' }} value={data} /></td>
                                                            ))}
                                                        </tr>
                                                    )
                                                }
                                            })}
                                        </tbody>
                                    </table>

                                </div>
                                {
                                    isTableView && (< div class="gp-action-btn-part float-end mt-5">
                                        <button type="button">Reset</button>
                                        <button type="button" class="gp-btn-fill" onClick={saveData}>Save</button>
                                    </div>)

                                }
                            </>
                        )
                    }

                    <div className='text-center '>
                        {
                            !isTableView ?

                                <div class="gp-bottom-btn-part">
                                    <button type="button" onClick={generateTable} class="gp-generate-btn">Generate</button>
                                </div>
                                : <div class="gp-bottom-btn-part">
                                    <button type="button" onClick={generateString} class="gp-generate-btn">Edit Mode</button>
                                </div>}
                    </div>
                </div>

                <ExcelUploader />

            </div >
            <div class="modal fade" id="myModalOther">
                <div class="modal-dialog  modal-lg">
                    <div class="modal-content">
                        <div class="modal-header py-2">
                            <h5 class="modal-title">External Models</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                        </div>
                        <div class="modal-body font14">
                            For the external model, please copy and paste your values from Excel.
                        </div>
                    </div>
                </div>
            </div>
            {splitOpen && <OtherSplitModel dnrDataSet={dnrDataSet} dummmy='' headings={splitHeadings} noOfRows={noOfRows} fromYear={fromYear} />}
        </>
    )
}

export default OtherModels
