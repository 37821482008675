export const routeprefixes={
    homePage:'/',
    myprojects:'/myprojects',
    createproject:'/createproject',
    sampletable:'/sampletable',
    authenticateuser:'/authenticateuser',
    project:'/project',
    projectView:"/projectView",
    chart:'/Chart',
    pptview:'/pptview',
    tocThemeConfig:'/tocThemeConfig',
    mytest:'/mytest'
}
export const routeSuffixes={
    driversAndRestraints:'driversAndRestraints'
}
