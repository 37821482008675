import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom';
import { useGetKeyCompetitorsQuery, usePostKeyCompetitorMutation, useUpdateTocIsSavedMutation } from '../../redux/slices/ProjectPageAPISlice';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider';
import { toast } from 'react-toastify';
import { ProgressContext } from '../../Providers/ProgressProvider';

const CompetitorsBySection = ({ competitorObj, projectData, tocId }) => {
  const { handleSectionSave } = useContext(ProgressContext)
  const { isProjectEditable } = useContext(ProjectStatusContext)
  const [inputValue, setInputValue] = useState('');
  const [tableData, setTableData] = useState([]);
  const { projectId } = useParams();
  const { data: keyCompetitorData, isLoading, isSuccess, isError, error } = useGetKeyCompetitorsQuery({ projectId: projectId, nodeId: competitorObj.id, tocId: tocId })

  useEffect(() => {
    if (isSuccess) {
      setTableData(keyCompetitorData?.data?.map(ele => ele?.desc))
    }
  }, [keyCompetitorData, isLoading, isSuccess, isError])

  const handleSaveKeyCompetitor = async () => {

    try {
      postKeyCompetitor({
        projectId: projectId,
        body: {
          userid: getUserDetailsFromLocalStorage().user_id,
          seg_id: competitorObj.id,
          competitors: tableData.flat(),
          tocid: tocId,
        }
      }).then((res) => toast.success("Saved Successfully"))
      handleSectionSave(tocId)
      updateTocIsSaved({ projectId: projectId, tocId: tocId })

    } catch (error) {
      console.log(error)
      toast.error("Something went wrong")
    }
  };


  const [postKeyCompetitor] = usePostKeyCompetitorMutation();
  const [updateTocIsSaved] = useUpdateTocIsSavedMutation()
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    const inputValue1 = e.target.value;
    const items = inputValue1.split('\n').map((item) => item.trim()).filter(Boolean);
    setTableData(items?.map(item => item.split('\t')[0]));
  };



  const handlePaste = (e) => {
    const pastedData = e.clipboardData.getData('text/plain');
    const rows = pastedData.split('\n');
    setTableData(rows?.map(item => item.split('\t')[0]));
  };

  return (
    <>
      <div class="w-100 h-100 gp-key-competitorSection gp-editing-content">
        <div class="gp-key-competitor-body">
          <div class="gp-add-segment">
            <div class="header">
              <h3>{competitorObj.name}</h3>
              <button type="button">
                {/* <img src="../../assets/images/instruction.svg"
                                        alt="instruction"> */}
              </button>
            </div>
            <div class="gp-input-area">
              <textarea
                disabled={!isProjectEditable}
                onPaste={handlePaste}
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Paste from Excel or Enter items separated by Enter"
              />
            </div>
          </div>
          <div class="gp-competitors-table-wrapper">
            {tableData.length > 0 && (
              <table class="gp-competitors-table">
                <thead>
                  <tr>
                    <th colspan="2">Competitors</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td key={`${rowIndex}`}>{row}</td>
                      <td>
                        <div class="gp-action-btn">
                          <button type="button">
                            {/* <img src="../../assets/images/edit.svg"
                                                        alt="edit"> */}
                          </button>
                          <button type="button">
                            {/* <img src="../../assets/images/delete.svg"
                                                        alt="delete"> */}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>

        {isProjectEditable &&
          <div className='d-flex justify-content-end'>
            <div class="gp-action-btn-part mt-2 me-4">

              <button onClick={handleSaveKeyCompetitor} className="gp-btn-fill" disabled={!isProjectEditable} type="button" >Save</button>
            </div>
          </div>
        }
      </div>

    </>
  )
}

export default CompetitorsBySection



