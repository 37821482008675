import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


export const UtilityApiSlice = createApi({
    reducerPath: 'utilityAPI',
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_GO_IN_BASE_URL }),
    tagTypes: [],
    endpoints: (builder) => ({
        getTheme: builder.query({
            query: ({selectedTheme}) => {
                return ({
                    url: `projectdet/get/defaulttoc/${selectedTheme}`,
                    method: "GET",
                })
            },
        }),
        saveTocTheme: builder.mutation({
            query:({status,tocId, body})=>{
                
                return ({
                    url:`/project/defaulttoc/${status}/${tocId}`,
                    method:"POST",
                    body:body
                })
            }
        }),
        sortTocTheme:builder.mutation({
            query:({theme,body})=>{
                return ({
                    url:`project/sortdefaulttoc/${theme}`,
                    method:"POST",
                    body:body
                })
            }
        }),
        deleteTocTheme:builder.mutation({
            query:({tocId})=>{
                return ({
                    url:`project/defaulttocdelete/${tocId}`,
                    method:"DELETE",

                })
            }
        })
       
    })
})
export const {useGetThemeQuery,useSaveTocThemeMutation,
    useSortTocThemeMutation,useDeleteTocThemeMutation}=UtilityApiSlice;