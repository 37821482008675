import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal, Box, Typography } from '@mui/material';
import '../CSS/TopBar.css'
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/style.css'
import '../../assets/css/../../assets/css/mediaquery.css'
import { useNavigate } from 'react-router-dom';
import frost_logo from '../../images/frost_logo.png'
import hamburger from '../../assets/images/haburger.svg'
import logo from '../../assets/images/logo.svg'
import homeIcon from "../../assets/images/PNG/home.png"
import profile from "../../assets/images/PNG/profile.png"
import dropDownNew from '../../assets/images/PNG/drop down.png'
import settings from '../../assets/images/PNG/settings.png'
import logout from "../../assets/images/PNG/logout.png"

function TopBar({ sendState, userData }) {

    const [isOpen, setIsOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false)
    const [active, setActive] = useState(false)
    const navigate = useNavigate()
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
        sendState(!isOpen)
    };
    const openNotifications = () => {
        navigate('/notifications')
    }
    const handleModalClose = () => {
        setModalOpen(false)
    }
    const goHome = () => {
        window.open('https://growthuat.frost.com/', '_self');
    }
    const handleLogout = () => {
        const cookies = document.cookie.split("; ");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        }
        sessionStorage.clear()
        window.location.href = "https://growthuat.frost.com"

    }
    const goHomePage = () => {
        window.location.href = 'https://growthuat.frost.com'
    }

    const dropdownRef = useRef(null);

    useEffect(() => {
        // Function to close dropdown if clicked outside
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setActive(false);
            }
        };
  
        // Attach event listener to detect outside clicks
        document.addEventListener('mousedown', handleClickOutside);
        
        // Cleanup listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [active]);
    return (
        <>

            <header className={`container-fluid d-flex align-items-center justify-content-between gp-header ${window.location.href.split('/').includes('projectview') && 'sticky-top'}`}>
                <div className="d-flex align-items-center w-50 gp-header-left">
                    <button className="border-0 bg-transparent pointer-event gp-hamburger" onClick={toggleSidebar}>
                        <img className="img-fluid" src={hamburger} alt="hamburger" />
                    </button>
                    <div className="frost-and-sullivan-logo">
                        <img className="img-fluid" src={logo} alt="logo" />
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-end w-50 gp-header-right">
                    <button type="button" className="gp-home-icon">
                        <img className="img-fluid" src={homeIcon} alt="home" title='https://growthuat.frost.com' onClick={goHomePage} />
                    </button>
                    <div className="d-flex align-items-center position-relative gp-profile-det" onClick={() => setActive(!active)}>
                        <div className="gp-profile-icon">
                            <div className="gp-profile-icon-sub">
                                <img className="img-fluid" src={userData?.image} alt="profile" />
                            </div>
                        </div>
                        <span className="gp-profile-name">{userData?.name}</span>
                        <button type="button" className="gp-dropdown-icon">
                            <img className="img-fluid" src={dropDownNew} alt="drop down" />
                        </button>
                        <div ref={dropdownRef} className={`position-absolute overflow-auto gp-profile-action ${active ? 'active' : ''}`} >
                            <ul className="w-100 h-100 ps-0">
                                <li className="w-100 d-flex align-items-center gp-option-btn">
                                    <div className="gp-option-logo">
                                        <img src={settings} alt="settings" />
                                    </div>
                                    <span>Settings</span>
                                </li>
                                <li className="w-100 d-flex align-items-center gp-option-btn" onClick={handleLogout}>
                                    <div className="gp-option-logo">
                                        <img src={logout} alt="logout" />
                                    </div>
                                    <span >Logout</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>


            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                style={{
                    position: 'absolute',
                    top: 70,
                    left: 950,
                }}
            >
                <Box sx={{

                    width: 300,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Modal Title
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        This is the content of the modal. You can put any component here.
                    </Typography>
                    <Button onClick={handleModalClose} sx={{ mt: 2 }}>Close</Button>
                </Box>
            </Modal>
        </>
    )
}

export default TopBar



