import React, { useEffect } from 'react'
import logo from "../../images/frost_logo.svg";
import { useParams } from 'react-router-dom';
import { useGetProjectDetailsQuery } from '../../redux/slices/ProjectPageAPISlice';
function Footer() {
  const { projectId } = useParams();
  const { data: ProjectData, isLoading: isProjectDataLoading, isSuccess: isProjectDataSuccess } = useGetProjectDetailsQuery({ projectId: projectId });

  return (
    // <div className='d-flex justify-content-between text-dark'>
    //   <img src={logo} style={{width:"270px"}}  />
    //   <p style={{left:'-40px'}} className='mb-0 font12 position-relative'>{ProjectData?.data?.wipcode}</p>
    //   <div className='font12'>Source: Frost & Sullivan</div>
    // </div>
    <div class="w-100 h-auto gp-editor-bottomSection">
      <p>{ProjectData?.data?.wipcode}</p>
      <p class="gp-editor-sourceText">Source: Frost & Sullivan</p>
    </div>
  )
}

export default Footer