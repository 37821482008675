import React, { useState, useEffect, useCallback, useRef } from 'react';
import plotly from 'plotly.js/dist/plotly';
import PlotlyEditor from 'react-chart-editor';
import 'react-chart-editor/lib/react-chart-editor.css';
// import Nav from './Nav';
import * as XLSX from 'xlsx';
import { options } from 'fusioncharts';
import { getUserDetailsFromLocalStorage } from '../Utils/userUtils';
import axios from 'axios';
import { useParams } from 'react-router-dom';
// import Plotly from 'plotly.js';



const config = { editable: true };

const MyChartComponent = () => {
  const [data, setData] = useState([]);
  const [layout, setLayout] = useState({});
  const [frames, setFrames] = useState([]);
  const [dataOne, setDataOne] = useState(null);
  const [dataSourceOptions, setDataSourceOptions] = useState({});
  const { projectId, tocId, chartId } = useParams();
  const plotlyRef = useRef();
  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const ab = e.target.result;
        const workbook = XLSX.read(ab, { type: 'array' });

        const sheetData = {};

        workbook.SheetNames.forEach((sheetName) => {
          const sheet = workbook.Sheets[sheetName];
          const sheetJson = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          // Convert sheet data into the desired format
          sheetJson[0].forEach((colName, index) => {
            const key = `${sheetName}.${colName}`;
            sheetData[key] = sheetJson.slice(1).map(row => row[index]);
          });
        });

        setDataOne(sheetData); // Set the data state to display
        var option = Object.keys(sheetData).map(name => ({
          value: name,
          label: name,
        }))
        setDataSourceOptions(option)
      };

      reader.readAsArrayBuffer(file);
    }
  };

  //  const chartState = { data: newData, layout: newLayout, frames: newFrames };
  //             localStorage.setItem('chartState', JSON.stringify(chartState));

  const sendData = async (imageData) => {

    const requestBody = {
      userid: getUserDetailsFromLocalStorage()?.user_id,
      image: imageData,
      chart_json: dataOne,
      custom_chart_json: { data: data, layout: layout, frames: frames }
    };

    try {
      if (chartId == undefined) {
        const response = await axios.post(`${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/customchart/${projectId}/${tocId}`, requestBody, {
          headers: {
            'Content-Type': 'application/json',
          }
        });
        window.close();
        return response.data;
      } else {
        const response = await axios.post(`${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/editcustomchart/${chartId} `, requestBody, {
          headers: {
            'Content-Type': 'application/json',
          }
        });
        window.close();
        return response.data;
      }

    } catch (error) {
      console.log(error);
    }
  };
  // Save the chart as an SVG and JSON data
  const handleSave = async () => {
    const plotlyDiv = plotlyRef.current;
    if (plotlyDiv) {
      const graphDiv = plotlyDiv.state.graphDiv;
      if (graphDiv) {
          const base64String = await plotly.toImage(graphDiv, {
            format: 'png',  // Image format: png, svg, jpeg, pdf
            width: 800,     // Image width
            height: 600,    // Image height
          });
          sendData(base64String)
          // link.click();
          alert(`Chart saved! Please click the 'Refetch' button next to the 'Create Chart' button.`);
      } else {
        alert('Could not access graphDiv.');
      }
    }
  };

  const getChart = async () => {
    try {
      let response = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/customchart/${projectId}/${tocId}`)
      let chartData = response.data.chartlist?.filter((ele) => ele?.id == chartId);
      if (!chartData.length) {
        return false;
      }
      setData(chartData[0]?.custom_chart_json?.data)
      var option = Object.keys(chartData[0]?.chart_json).map(name => ({
        value: name,
        label: name,
      }))
      setDataSourceOptions(option)
      setDataOne(chartData[0]?.chart_json)
      setLayout(chartData[0]?.custom_chart_json?.layout);
      setFrames(chartData[0]?.custom_chart_json?.frames);

    } catch (error) {
      console.log(error);

    }
  }

  useEffect(() => {
    if (chartId != undefined) {
      getChart(chartId)
    }
  }, [chartId])

  return (
    <div className="app">
      {
        Object.keys(dataSourceOptions).length == 0 && <div className='custum-file'> <label class="custum-file-upload" for="file">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" fill="" viewBox="0 0 24 24"><g stroke-width="0" id="SVGRepo_bgCarrier"></g><g stroke-linejoin="round" stroke-linecap="round" id="SVGRepo_tracerCarrier"></g><g id="SVGRepo_iconCarrier"> <path fill="" d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z" clip-rule="evenodd" fill-rule="evenodd"></path> </g></svg>
          </div>
          <div class="text">
            <span>Click to upload xlsx file</span>
          </div>
          <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} id="file" />
        </label>
        </div>
      }

      {
        Object.keys(dataSourceOptions).length > 0 && (
          <>
            <div className='d-flex justify-content-end me-3 mt-3'>
              <div class="gp-action-btn-part mt-1">
                <button type="button" class="gp-btn-fill" onClick={handleSave} >Save</button>
              </div>
            </div>
            <PlotlyEditor
              divId="plotly-editor"
              ref={plotlyRef}
              data={data}
              layout={layout}
              config={config}
              frames={frames}
              dataSources={dataOne}
              dataSourceOptions={dataSourceOptions}
              plotly={plotly}
              onUpdate={(newData, newLayout, newFrames) => {
                setData(newData);
                setLayout(newLayout);
                setFrames(newFrames);
              }}
              useResizeHandler
              debug
              advancedTraceTypeSelector
            />
          </>)
      }
    </div>
  );
};

export default MyChartComponent;
