// import React from 'react';
// import { TablePagination } from '@mui/material';

// const PaginationComponent = ({ count, page, rowsPerPage, onPageChange, onRowsPerPageChange }) => {
//     return (
//         <TablePagination

//             component="div"
//             count={count}
//             page={page}
//             onPageChange={onPageChange}
//             rowsPerPage={rowsPerPage}
//             onRowsPerPageChange={onRowsPerPageChange}

//         />
//     );
// };

// export default PaginationComponent;


import React from 'react';
import { TablePagination } from '@mui/material';

const PaginationComponent = ({ count, page, rowsPerPage, onPageChange, onRowsPerPageChange }) => {
    return (
        <TablePagination
            component="div"
            count={count}
            page={page}
            onPageChange={onPageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={onRowsPerPageChange}
            sx={{
                '.MuiTablePagination-toolbar': {
                    fontSize: '1.6rem', // Overall font size
                },
                '.MuiTablePagination-selectLabel': {
                    fontSize: '1.6rem', // "Rows per page" label
                },
                '.MuiTablePagination-displayedRows': {
                    fontSize: '1.6rem', // Text showing "1-10 of X"
                },
                '.MuiSelect-select': {
                    fontSize: '1.6rem', // The rows-per-page number dropdown
                },
                '.MuiTablePagination-actions': {
                    fontSize: '1.6rem', // Font size for the pagination buttons (next, previous)
                }
            }}
        />
    );
};

export default PaginationComponent;

