import React, { useEffect, useRef, useState, useCallback } from "react";
import $ from 'jquery';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-bs5';
import { Link, useNavigate } from 'react-router-dom';
import './Styles/CustomDatatable.css';
import axios from "axios";
import './MyProjects/project.css';
import './Styles/add-contributor.css'
import close from "../images/close.svg";
import { Box, Modal } from "@mui/material";
import CreateProject from "./CreateProject";
import AddContributors from "./AddContributors";
import SSOLoader from "./SSOLoader";
import { toast } from "react-toastify";
import CloneProject from "./ProjectModals/CloneProject";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "800px",
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
};
const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    boxShadow: 24,
    bgcolor: 'white', // background-color in CSS
    width: '880px',
    borderRadius: '0.6rem',
};
function SampleTable(status) {
    console.log(status);
    const [tableData, setTableData] = useState([]);
    const [open, setOpen] = useState(false);
    const [contributorsOpen, setContributorsOpen] = useState(false)
    const [editData, setEditData] = useState({});
    const [fullData, setFullData] = useState([]);
    const [dummy, setDummy] = useState(0)
    const [contributorsList, setContributorsList] = useState([])
    const [currentId, setCurrentId] = useState('')
    const navigate = useNavigate();
    const [confromClone, setConfromClone] = useState(false);
    const [confromRepublish, setConfromRepublish] = useState(false);
    const getUserDetailsFromLocalStorage = () => {
        const userDetailsString = sessionStorage.getItem('userDetails');
        return userDetailsString ? JSON.parse(userDetailsString) : null;
    };
    const [loader, setLoader] = useState(false)

    const goInType = (data) => {
        const types = [
            { name: 'Industry', id: 1 },
            { name: 'Region', id: 2 },
            { name: 'Application', id: 3 },
            { name: 'End-User', id: 4 },
        ];
        const res = types.find(e => e.id === data);
        return res ? res.name : null;
    };

    const getUserDetails = (id, userArr) => {
        const res = userArr.find(e => e.id === id);
        return res ? `${res.first_name} ${res.last_name}` : '';
    };
    const [isModalVisible, setIsModalVisible] = useState(false);
    const openModal = () => {
        setIsModalVisible(true);
    }
    const closeModal = () => {
        setIsModalVisible(false)
    };
    const handleEditReload = () => {
        setOpen(false);
        getTableData();
    }

    const getTableData = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/listproject/`, {
                params: {
                    status: status?.data,
                    role_type: getUserDetailsFromLocalStorage()?.role,
                    user_id: getUserDetailsFromLocalStorage()?.user_id,
                },
            });
            const { data, userdetails } = response.data;
            console.log(data)
            let filteredDataSet = data

            if (status?.filterData?.GO_IN_Type.length > 0) {
                filteredDataSet = filteredDataSet.filter((e) => e.go_in_type == status.filterData.GO_IN_Type)
            }
            if (status?.filterData?.Status.length > 0) {
                filteredDataSet = filteredDataSet.filter((e) => e.status == status.filterData.Status)
            }
            if (status?.filterData?.Practice_Area.length > 0) {
                filteredDataSet = filteredDataSet.filter((e) => e.industry == status.filterData.Practice_Area)
            }
            if (status?.filterData?.Program_Area.length > 0) {
                filteredDataSet = filteredDataSet.filter((e) => e.sector_id == status.filterData.Program_Area)
            }
            filteredDataSet.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at); // For descending order
            });
            setFullData(filteredDataSet)
            const transformedDataSet = filteredDataSet.map((e, index) => [
                index + 1,
                e.title_name,
                e.go_in_type == '1' ? "GO-IN - Industry" : 'GO-3-WEEK-MAX',
                e?.isrepublished == '0' ? "New" : " Republished",
                getUserDetails(e.created_by, userdetails),
                new Date(e.created_at).toLocaleDateString('en-GB').replace(/\//g, '-'),
                new Date(e?.updated_at).toLocaleDateString('en-GB').replace(/\//g, '-'),
                e.id,
                e.status,
                e.role_type
            ]);
            setTableData(transformedDataSet);
        } catch (error) {
            console.error(error);
        }
    }, [status]);


    useEffect(() => {
        getTableData();
    }, [getTableData]);



    $.DataTable = require('datatables.net');
    const tableRef = useRef();
    const tableName = "table1";
    const [cloneId, setCloneId] = useState('');
    useEffect(() => {
        if (tableData.length > 0) {
            const table = $(`#${tableName}`).DataTable({
                data: tableData,
                columns: [
                    { title: "S.NO", width: '5%', className: 'text-center' },
                    { title: "Project Title", width: '30%' },
                    { title: "Project Type", width: '20%', className: 'text-center' },
                    { title: "Status", width: '5%', className: 'text-center' },
                    { title: "Created by", width: '10%', className: 'text-center' },
                    { title: "Created&nbsp;Date", width: '10%', className: 'text-center' },
                    { title: "Modified&nbsp;Date", width: '10%', className: 'text-center' },
                    {
                        title: 'Actions', width: '5%',
                        data: null,
                        render: (data, type, row) => {
                            // console.log(row);

                            return row[8] == "published" && row[4] == getUserDetailsFromLocalStorage()?.name
                                ? `
                            <div class="d-flex justify-content-around">
                                <i style='color:#0D6EFD' class="fa fa-clone clone-project cursor-pointer" title="Clone Project" data-id="${row[7]}"></i>
                                <i style='color:#0D6EFD' class="fa fa-refresh republish-project cursor-pointer" title="Republish Project" data-id="${row[7]}"></i> 
                             </div>
                             
                           `
                                : row[8] == "published" && (getUserDetailsFromLocalStorage()?.role == 'editor' || getUserDetailsFromLocalStorage()?.role == 'admin' || getUserDetailsFromLocalStorage()?.role == 'qc' || row[9] == 'analyst' || row[9] == 'pm' || getUserDetailsFromLocalStorage()?.role == 'subeditor') ? `
                           <div class="d-flex justify-content-around">
                                <i style='color:#0D6EFD' class="fa fa-refresh republish-project cursor-pointer" title="Republish Project" data-id="${row[7]}"></i>                                
                            </div> 
                                `: ((row[8] == 'draft' || row[8] == 'toAnalyst') && row[4] == getUserDetailsFromLocalStorage()?.name && row[9] == 'analyst') ? `
                                      
                                      <div class="d-flex justify-content-around">
                                          <i style='color:#0D6EFD' class="fa fa-pencil-square-o action-icon cursor-pointer" title="Edit Project" data-id="${row[7]}"></i>
                                          <i style='color:#0D6EFD' class="fa fa-plus-square contrib-icon cursor-pointer" title="Add Contributors" data-id="${row[7]}"></i>
                                      </div>
                                `: (row[8] == "toPm" && row[9] == 'pm') ? `
                                      
                                      <div class="d-flex justify-content-around">
                                          <i style='color:#0D6EFD' class="fa fa-pencil-square-o action-icon cursor-pointer" title="Edit Project" data-id="${row[7]}"></i>
                                          <i style='color:#0D6EFD' class="fa fa-plus-square contrib-icon cursor-pointer" title="Add Contributors" data-id="${row[7]}"></i>
                                      </div>
                                `: (row[8] == "toEditor" && (getUserDetailsFromLocalStorage()?.role == 'editor')) ? `
                                      
                                <div class="d-flex justify-content-around">
                                    <i style='color:#0D6EFD' class="fa fa-plus-square contrib-icon cursor-pointer" title="Add Contributors" data-id="${row[7]}"></i>
                                </div>
                          `: `<div class="d-flex justify-content-center"><span>-</span></div>`
                        }

                    },
                ],
                destroy: true,
                searching: true,
                // pageLength:50
            });

            $(`#${tableName}`).on('click', 'td:nth-child(2)', function () {
                const rowData = table.row($(this).parents('tr')).data();
                if (rowData) {
                    const projectTitle = rowData[7];
                    navigate(`/project/${projectTitle}`, { replace: true });
                }
            });

            $(tableRef.current).on('click', '.action-icon', function () {
                const id = $(this).data('id');
                setEditData(fullData?.filter(ele => ele.id == id)?.[0])
                // console.log(fullData?.filter(ele => ele.id == id)?.[0]);
                setIsModalVisible(true);
                // setOpen(true)
                setDummy(dummy + 1)

            });
            $(tableRef.current).on('click', '.contrib-icon', function () {
                setContributorsList([])
                setContributorsOpen(true)
                const id = $(this).data('id');
                setCurrentId(id)
                setDummy(dummy + 1)
            });


            $('#table1').on('draw.dt', function () {
                $(`#${tableName} tbody td:nth-child(2)`).addClass('LinkColumn');
            });


            return () => {
                table.off('click'); // Remove all click event listeners
                table.destroy(); // Destroy the DataTable instance

            };
        } else {
            const table = $(`#${tableName}`).DataTable({
                data: tableData,
                columns: [
                    { title: "ID", width: '5%', className: 'text-center' },
                    { title: "Project Title", width: '30%' },
                    { title: "Project Type", width: '20%' },
                    { title: "Status", width: '20%' },
                    { title: "Created by", width: '15%', className: 'text-center' },
                    { title: "Created Date", width: '90px', className: 'text-center' },
                    { title: "Modified Date", width: '90px', className: 'text-center' },
                    {
                        title: 'Actions', width: '5%',
                        data: null,
                        render: (data, type, row) => {
                            return row[8] == "published" && row[4] == getUserDetailsFromLocalStorage()?.name
                                ? `
                                 <div class="d-flex justify-content-center">
                                     <i style='color:#0D6EFD' class="fa fa-clone clone-project cursor-pointer" title="Clone Project" data-id="${row[7]}"></i>
                                     <i style='color:#0D6EFD' class="fa fa-refresh republish-project cursor-pointer" title="Republish Project" data-id="${row[7]}"></i> 
                                  </div>
                                  
                                `
                                : row[8] == "published" && (getUserDetailsFromLocalStorage()?.role == 'editor' || getUserDetailsFromLocalStorage()?.role == 'admin' || getUserDetailsFromLocalStorage()?.role == 'qc' || row[9] == 'analyst' || row[9] == 'pm' || getUserDetailsFromLocalStorage()?.role == 'subeditor') ? `
                                <div class="d-flex justify-content-around">
                                     <i style='color:#0D6EFD' class="fa fa-refresh republish-project cursor-pointer" title="Republish Project" data-id="${row[7]}"></i>                                
                                 </div> 
                               `: ((row[8] == 'draft' || row[8] == 'toAnalyst') && row[4] == getUserDetailsFromLocalStorage()?.name && row[9] == 'analyst') ? `
                                 
                                 <div class="d-flex justify-content-around">
                                     <i style='color:#0D6EFD' class="fa fa-pencil-square-o action-icon cursor-pointer" title="Edit Project" data-id="${row[7]}"></i>
                                     <i style='color:#0D6EFD' class="fa fa-plus-square contrib-icon cursor-pointer" title="Add Contributors" data-id="${row[7]}"></i>
                                 </div>
                               `: (row[8] == "toPm" && row[9] == 'pm') ? `
                                      
                               <div class="d-flex justify-content-around">
                                   <i style='color:#0D6EFD' class="fa fa-pencil-square-o action-icon cursor-pointer" title="Edit Project" data-id="${row[7]}"></i>
                                   <i style='color:#0D6EFD' class="fa fa-plus-square contrib-icon cursor-pointer" title="Add Contributors" data-id="${row[7]}"></i>
                               </div>
                         `: `<div class="d-flex justify-content-center"><span>-</span></div>`

                        }
                    },
                ],
                destroy: true,
                searching: true,
                // pageLength:50
            });
            $(tableRef.current).on('click', '.clone-project', function () {
                const id = $(this).data('id');
                setConfromClone(true)
                setCloneId(id)
            });
            $(tableRef.current).on('click', '.republish-project', function () {

                const id = $(this).data('id');
                setConfromRepublish(id)
            });
            return () => {
                table.off('click'); // Remove all click event listeners
                table.destroy(); // Destroy the DataTable instance
            };
        }
    }, [tableData, navigate]);
    useEffect(() => {
        getTableData()
    }, [status.filterData])



    const republishProject = async () => {
        setLoader(true)

        try {
            let postData = {
                user_id: getUserDetailsFromLocalStorage().user_id,
                status: 'toQc',
                return_status: "",
            }
            let response = await axios.post(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${confromRepublish}/updateStatus`, postData)
            setConfromClone(false)
            setLoader(false)
            toast.success('Republished successfully')
        } catch (error) {
            console.error(error);
            setLoader(false)

        }
    }
    return (
        <div className="mt-3 mb-5 card shadow-sm">
            {loader ? <SSOLoader /> : null}
            <div className="card-body">
                <table className="table table-bordered custom-datatable" width="100%" id={tableName} ref={tableRef}></table>
            </div>
            <CreateProject editDataSet={editData} isVisible={isModalVisible} onClose={closeModal} reload={() => { handleEditReload() }} />

            {
                // open &&
                // <Modal
                //     open={open}
                //     onClose={() => { setOpen(false) }}
                //     aria-labelledby="modal-modal-title"
                //     aria-describedby="modal-modal-description"
                // >
                //     <Box sx={style}>
                //         <div class="modal-content">
                //             <div class="modal-header">
                //                 <div className="w-100 d-flex justify-content-between">
                //                     <h5 style={{ color: '#0e4c8b' }} class="modal-title">Edit Project</h5>
                //                     <button type="button" onClick={() => { setOpen(false) }} class="btn-close" ></button>
                //                 </div>
                //             </div>
                //             <div class="modal-body pt-0">
                //                 <CreateProject editData={editData} reload={() => { handleEditReload() }} />
                //             </div>
                //         </div>
                //     </Box>
                // </Modal>
            }
            {
                contributorsOpen &&
                <Modal
                    open={contributorsOpen}
                    onClose={() => { setContributorsOpen(false) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style2}>
                        <div class="modal-content">
                            <div class="modal-header pb-3">
                                {/* <div className="w-100 d-flex justify-content-between align-items-center">
                                    <h5 class="modal-title">Add Contributors</h5>
                                    <button type="button" onClick={() => { setContributorsOpen(false) }} class="btn-close" ></button>
                                </div> */}
                                <div class="d-flex align-items-center w-100 justify-content-between gp-contributor-modal-header">
                                    <h1>Add Contributors for <span>"GOI"</span></h1>
                                    <button type="button" class="gp-modal-close"  onClick={() => { setContributorsOpen(false) }}>
                                        <img src={close}  alt="close" />
                                    </button>
                                </div>
                            </div>
                            <div class="modal-body">
                                <AddContributors contributorsList={contributorsList} setContributorsList={setContributorsList} project_Id={currentId} />
                                
                            </div>
                            
                        </div>
                    </Box>
                </Modal>

            }
            {
                confromClone &&
                <Modal
                    open={confromClone}
                    onClose={() => { setConfromClone(false) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style2}>
                        <div class="modal-content">
                            <div class="modal-header py-2">
                                <div className="w-100 d-flex justify-content-between">
                                    <h5 class="modal-title">Clone Project</h5>
                                    <button type="button" onClick={() => { setConfromClone(false) }} class="btn-close" ></button>
                                </div>
                            </div>
                            <div className="modal-body py-0">
                                Are you sure want to clone the project?
                            </div>
                            <div className="modal-footer border-top-0 mt-3">
                                <button type="button" className="btn btn-danger btn-sm" onClick={() => { setConfromClone(false) }}><i class="fa fa-close" ></i> Cancel</button>
                                <button type="button" className="btn btn-primary btn-sm ms-2" onClick={() => setConfromClone(false)} data-bs-toggle="modal" data-bs-target="#cloneModal"><i class="fa fa-check"></i> Yes</button>
                            </div>
                        </div>
                    </Box>
                </Modal>
            }
            <div className="modal fade" id="cloneModal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header py-2">
                            <h5 className="modal-title">Project Information</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body">
                            <CloneProject
                                cloneId={cloneId}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {
                confromRepublish &&
                <Modal
                    open={confromRepublish}
                    onClose={() => { setConfromRepublish(false) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style2}>
                        <div class="modal-content">
                            <div class="modal-header py-2">
                                <div className="w-100 d-flex justify-content-between">
                                    <h5 class="modal-title">Republish Project</h5>
                                    <button type="button" onClick={() => { setConfromRepublish(false) }} class="btn-close" ></button>
                                </div>
                            </div>
                            <div className="modal-body py-0">
                                Are you sure want to republish the project?
                            </div>
                            <div className="modal-footer border-top-0 mt-3">
                                <button type="button" className="btn btn-danger btn-sm" onClick={() => { setConfromRepublish(false) }}><i class="fa fa-close" ></i> Cancel</button>
                                <button type="button" className="btn btn-primary btn-sm ms-2" onClick={() => republishProject()}><i class="fa fa-check"></i> Yes</button>
                            </div>
                        </div>
                    </Box>
                </Modal>
            }

        </div>
    );
}

export default SampleTable;