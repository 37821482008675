import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ProjectStatusContext } from './Providers/ProjectStatusProvider';
import pdfIcon from './assets/images/PNG/pdf_icon.png'
import closeIcon from './assets/images/close.svg'
import '../src/layout/CSS/TotalMarketAnalysis.css'


function ExcelUploader() {
    const [files, setFiles] = useState([]);
    const [allFiles, setAllFiles] = useState([])
    const project_id = window.location.href.split('/').pop();
    const { setFileUploaded } = useContext(ProjectStatusContext)
    const handleFileUpload = (event) => {
        setFiles(event.target.files);
    };
    const handleFileDelete = async (name) => {
        try {
            let response = await axios.delete(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_id}/deluploadFile/${name}`)
            getFiles()
            if (response?.status == 200) {
                toast.success('File deleted successfully')
            } else {
                toast.error('Something went wrong')
            }
        } catch (error) {
            console.log(error);
        }
    };

    const uploadFile = async () => {
        const formData = new FormData();
        formData.append("excelfile", files[0]);
        console.log(formData);
        console.log(files[0]);
        try {
            let response = await axios.post(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_id}/uploadFile/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
            if (response?.status == 200) {
                setFileUploaded(true);
                toast.success('File uploaded successfully')
            } else {
                toast.error('Something went wrong')
            }
            getFiles()
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong')
        }
    }

    const downloadFile = async (name) => {
        try {
            let response = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/download/${project_id}/${name}`)
            window.open(response?.data?.data, '_blank')
        } catch (error) {
            console.log(error);
        }
    }

    const getFiles = async () => {
        try {
            let data = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_id}/getuploadFile`)

            setFileUploaded(data?.data?.data.length > 0)
            setAllFiles(data?.data?.data)
            console.log(data);
            
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div class="gp-external-models-section gp-upload-excel">
            <div class="gp-header">
                <h2 class="mb-0">Upload Excel Files</h2>
            </div>
            <div class="body">

                <div class="input-group input-group-sm " >
                    <input type="file" multiple style={{ fontSize: '1.6rem' }}
                        accept=".xlsx, .xls"
                        onChange={handleFileUpload}
                        className='form-control' />
                    <button className='btn btn-secondary' style={{ fontSize: '1.6rem' }} onClick={uploadFile}><i class="fa fa-upload" ></i> Upload</button>
                </div>

                <div class="gp-uploaded-files">
                    <h3 class="mb-0">Uploaded Files ({allFiles?.length >0 ? allFiles?.length : 0})</h3>
                    <ul class="gp-files-wrapper">
                        {allFiles.length > 0 ? allFiles?.map((e) => <li>
                            <div class="gp-icon">
                                <img src={pdfIcon} onClick={() => downloadFile(e?.file_name)}
                                    alt="pdf" />
                            </div>
                            <span>{e?.file_name}</span>
                            <button type="button">
                                <img src={closeIcon}  onClick={() => handleFileDelete(e?.file_name)}
                                    alt="close" />
                            </button>
                        </li>) : <span style={{fontSize:'1.6rem'}}>No files Uploaded yet</span>}
                        


                    </ul>
                </div>
             
            </div>

        </div>
    );
}

export default ExcelUploader;
