import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const WritingIssues = ({ Score, data, removeUnderlines, acceptUnderlines }) => {
    const {
        Grammar_errors,
        Spelling_errors,
        Punctuation_errors,
        Readability_errors,
        Conciseness_errors,
        wrong_correct_pairs
    } = data;

    const [openIndex, setOpenIndex] = useState(null);
    const [issueData, setIssueData] = useState({
        Grammar_errors,
        Spelling_errors,
        Punctuation_errors,
        Readability_errors,
        Conciseness_errors,
        wrong_correct_pairs
    });
    const [totalIssues, setTotalIssues] = useState(0);
    // const [score, setScore] = useState(100);
    // useEffect to calculate totalIssues whenever issueData changes
    useEffect(() => {
        const issues = [
            { label: 'Grammar', count: issueData.Grammar_errors, type: 'Grammar Error' },
            { label: 'Spelling', count: issueData.Spelling_errors, type: 'Spelling Error' },
            { label: 'Punctuation', count: issueData.Punctuation_errors, type: 'Punctuation Error' },
            { label: 'Conciseness', count: issueData.Conciseness_errors, type: 'Conciseness Error' },
            { label: 'Readability', count: issueData.Readability_errors, type: 'Readability Error' },
        ];

        const total = issues.reduce((acc, issue) => acc + issue.count, 0);
        setTotalIssues(total); // Update the totalIssues state
        console.log(data);

    }, [issueData]);

    // Toggle accordion
    const toggleAccordion = (index, count) => {
        // if (count > 0) {
        setOpenIndex(openIndex === index ? null : index);
        // }
    };

    const remove = (type, incorrectWord) => {
        removeUnderlines(incorrectWord);

        setIssueData((prevData) => {
            const updatedPairs = {
                ...prevData.wrong_correct_pairs,
                [type]: prevData.wrong_correct_pairs[type].filter(pair => pair.Incorrect !== incorrectWord)
            };

            // Update the relevant count based on the type
            let updatedCounts = { ...prevData };
            if (type === 'Grammar Error') updatedCounts.Grammar_errors -= 1;
            if (type === 'Spelling Error') updatedCounts.Spelling_errors -= 1;
            if (type === 'Punctuation Error') updatedCounts.Punctuation_errors -= 1;
            if (type === 'Readability Error') updatedCounts.Readability_errors -= 1;
            if (type === 'Conciseness Error') updatedCounts.Conciseness_errors -= 1;

            return {
                ...updatedCounts,
                wrong_correct_pairs: updatedPairs
            };
        });
    };

    const Accept = (type, incorrectWord, crtWord) => {
        acceptUnderlines(incorrectWord, crtWord);

        setIssueData((prevData) => {
            const updatedPairs = {
                ...prevData.wrong_correct_pairs,
                [type]: prevData.wrong_correct_pairs[type].filter(pair => pair.Incorrect !== incorrectWord)
            };

            // Update the relevant count based on the type
            let updatedCounts = { ...prevData };
            if (type === 'Grammar Error') updatedCounts.Grammar_errors -= 1;
            if (type === 'Spelling Error') updatedCounts.Spelling_errors -= 1;
            if (type === 'Punctuation Error') updatedCounts.Punctuation_errors -= 1;
            if (type === 'Readability Error') updatedCounts.Readability_errors -= 1;
            if (type === 'Conciseness Error') updatedCounts.Conciseness_errors -= 1;

            return {
                ...updatedCounts,
                wrong_correct_pairs: updatedPairs
            };
        });
    };
    return (
        <div className="p-4 border rounded shadow" style={{ maxWidth: '400px' }}>
            <div class="score-card mb-5">
                <div className='d-flex align-items-center justify-content-between'>
                    <div class="score-label">Editor Score</div>
                    <div class="score">98<span>%</span></div>
                </div>
                <div class="progress-bar-container">
                    <div class="progress-bar" style={{ width: `${Score}%`, backgroundColor: Score < 50 ? 'red' : Score <= 75 ? 'orange' : 'lightgreen' }}></div>
                </div>
            </div>
            {/* <h6 className="d-flex align-items-center mt-3" style={{ fontSize: "12px" }}>
                We’ve found {totalIssues} writing {totalIssues > 1 ? 'issues' : 'issue'} in your text.
            </h6> */}

            {/* {Object.entries(issueData.wrong_correct_pairs).map(([type, pairs], index) => (
                    <li key={type} className="mb-2">
                        <div className="d-flex align-items-center" style={{ cursor: "pointer" }} onClick={() => toggleAccordion(index, issueData[`${type.replace(' Error', '_errors')}`])}>
                            <span className="flex-grow-1">{type.split(' ')[0]}</span>
                            <span className="badge bg-warning" style={{ fontSize: "8px" }}>{issueData[`${type.replace(' Error', '_errors')}`]}</span>
                        </div>
                        {openIndex === index && (
                            <div className="accordion mt-2">


                                {pairs.length === 0 ? (
                                    <div className="border p-2 mb-2">
                                        <p>No mistakes</p>
                                    </div>
                                ) : (pairs.map((pair, idx) => (
                                    <div key={idx} className="border p-2 mb-2">
                                        <p>
                                            <strong>Incorrect:</strong> {pair.Incorrect} <br />
                                            <strong>Correct:</strong> {pair.Correct}
                                        </p>
                                        <button className="btn btn-success me-2" onClick={() => Accept(type, pair.Incorrect, pair.Correct)}>Accept</button>
                                        <button className="btn btn-danger" onClick={() => remove(type, pair.Incorrect)}>
                                            Cancel
                                        </button>
                                    </div>
                                ))
                                )}
                            </div>
                        )}
                    </li>
                ))} */}
            <>
                {/* First div for the first two items */}
                <h3 style={{fontWeight:"600"}}>Corrections</h3>
                <div class="ms-5 mb-5">
                    <ul className="list-unstyled">
                        {Object.entries(issueData.wrong_correct_pairs).slice(0, 2).map(([type, pairs], index) => (
                            <li key={type} className="mb-2">
                                <div
                                    className="d-flex align-items-center"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => toggleAccordion(index, issueData[`${type.replace(' Error', '_errors')}`])}
                                >
                                    <span className="flex-grow-1" style={{fontSize:"1.8rem"}}>{type.split(' ')[0]}</span>
                                    <span className="badge bg-warning" style={{ fontSize: "8px" }}>
                                        {issueData[`${type.replace(' Error', '_errors')}`]}
                                    </span>
                                </div>
                                {openIndex === index && (
                                    <div className="accordion mt-2">
                                        {pairs.length === 0 ? (
                                            <div className="border p-2 mb-2">
                                                <p>No mistakes</p>
                                            </div>
                                        ) : (
                                            pairs.map((pair, idx) => (
                                                <div key={idx} className="border p-2 mb-2">
                                                    <p>
                                                        <strong>Incorrect:</strong> {pair.Incorrect} <br />
                                                        <strong>Correct:</strong> {pair.Correct}
                                                    </p>
                                                    <button
                                                        className="btn btn-success me-2"
                                                        onClick={() => Accept(type, pair.Incorrect, pair.Correct)}
                                                    >
                                                        Accept
                                                    </button>
                                                    <button className="btn btn-danger" onClick={() => remove(type, pair.Incorrect)}>
                                                        Cancel
                                                    </button>
                                                </div>
                                            ))
                                        )}
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Second div for the remaining items */}
                <h3>Refinements</h3>
                <div class="ms-5 mb-3">
                    <ul className="list-unstyled">
                        {Object.entries(issueData.wrong_correct_pairs).slice(2).map(([type, pairs], index) => (
                            <li key={type} className="mb-2">
                                <div
                                    className="d-flex align-items-center"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => toggleAccordion(index + 2, issueData[`${type.replace(' Error', '_errors')}`])}
                                >
                                    <span className="flex-grow-1" style={{fontSize:"1.8rem"}}>{type.split(' ')[0]}</span>
                                    <span className="badge bg-warning" style={{ fontSize: "8px" }}>
                                        {issueData[`${type.replace(' Error', '_errors')}`]}
                                    </span>
                                </div>
                                {openIndex === index + 2 && (
                                    <div className="accordion mt-2">
                                        {pairs.length === 0 ? (
                                            <div className="border p-2 mb-2">
                                                <p>No mistakes</p>
                                            </div>
                                        ) : (
                                            pairs.map((pair, idx) => (
                                                <div key={idx} className="border p-2 mb-2">
                                                    <p>
                                                        <strong>Incorrect:</strong> {pair.Incorrect} <br />
                                                        <strong>Correct:</strong> {pair.Correct}
                                                    </p>
                                                    <button
                                                        className="btn btn-success me-2"
                                                        onClick={() => Accept(type, pair.Incorrect, pair.Correct)}
                                                    >
                                                        Accept
                                                    </button>
                                                    <button className="btn btn-danger" onClick={() => remove(type, pair.Incorrect)}>
                                                        Cancel
                                                    </button>
                                                </div>
                                            ))
                                        )}
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </>
        </div >
    );
};

export default WritingIssues;
