import React from 'react'
import { Tree, TreeNode } from 'react-organizational-chart';
function DynamicTree({ data }) {

    const DynamicTreeNode = ({ node }) => {
        if (node.children.length > 0) {
            return (
                <TreeNode label={<div className="styled-node">{node.name}</div>}>
                    {node.children && node.children.length > 0 && node.children.map((child) => (
                        <DynamicTreeNode key={child.id} node={child} />
                    ))}
                </TreeNode>
            );
        }else{
            return (
                <TreeNode label={<div className="styled-node">{node.name}</div>} />
            );
        }
    };
    return (
        <Tree
            lineWidth={'2px'}
            lineColor={'black'}
            lineBorderRadius={'0px'}
            label={<div className="styled-node ">{data.name}</div>}
        >
            {data.children && data.children.length > 0 && data.children.map((child) => (
                <DynamicTreeNode key={child.id} node={child} />
            ))}
        </Tree>
    );
}

export default DynamicTree