import React, { useContext, useEffect, useState } from 'react'
import { useGetProjectDetailsQuery, usePostTocDataMutation, useUpdateTocIsSavedMutation, useReCallMetricMutation } from '../redux/slices/ProjectPageAPISlice';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ProjectStatusContext } from '../Providers/ProjectStatusProvider';
import { ProgressContext } from '../Providers/ProgressProvider';
import { getUserDetailsFromLocalStorage } from '../Utils/userUtils';
import { useParams } from 'react-router-dom';
import CkEditorBase from '../CkEditorReact/CkEditorBase';
import Footer from './TocChildren/Footer';
import tick from "../images/tick.svg";
import instruction from "../images/instruction.svg";
import '../../src/assets/css/Index.css'



function IndexSlide(props) {
    const { projectId } = useParams()
    const { isProjectEditable } = useContext(ProjectStatusContext)
    const { handleSectionSave } = useContext(ProgressContext)
    const { data: ProjectData, isLoading: isProjectDataLoading, isSuccess: isProjectDataSuccess } = useGetProjectDetailsQuery({ projectId: projectId });
    const [sector, setSector] = useState({})
    const [ReCallMetric] = useReCallMetricMutation();
    const getSectorData = async (id) => {
        try {
            var res = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/getsectorbyid/${id}`);
            setSector(res?.data?.data?.[0] || {})
        } catch (error) {
            console.log(error);
        }
    }

    const [text, setText] = useState('');
    const [ckId, setCkId] = useState(null);
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const [postTocData] = usePostTocDataMutation();
    const [updateTocIsSaved] = useUpdateTocIsSavedMutation()



    const handleTextChange = (e) => {
        let strippedText = e.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ');
        const characterCount = strippedText.length;
        // const wordCount = strippedText.split(/\s+/).filter(word => word.length > 0).length;



        if (characterCount <= 300) {
            setText(e);
            if (debounceTimeout) clearTimeout(debounceTimeout);
            const timeout = setTimeout(() => {
                handleSave(e);
            }, 1000);
            setDebounceTimeout(timeout);

        } else {
            toast.error('Only 300 characters allowed!')
        }

    }

    const handleSave = async (inputText) => {
        try {
            await postTocData({
                projectId: props?.go_in_id,
                propsId: props?.id,
                body: {
                    "userid": getUserDetailsFromLocalStorage()?.user_id,
                    "description": inputText,
                    "name": props?.name,
                    "isSaved": 1
                }
            }).then((res) => console.log(res))
            toast.success('Auto Saved Successfully')
            let saved = inputText.length > 0 ? 1 : 0
            updateTocIsSaved({ projectId: projectId, tocId: props.id, isSaved: saved })
            ReCallMetric()
            handleSectionSave(props.id)
        } catch (error) {
            console.log("There was an error!", error);
            toast.error("Something went wrong")
        }
    }
    useEffect(() => {
        if (ProjectData?.data) {
            getSectorData(ProjectData?.data?.sector_id)
        }
    }, [ProjectData])

    useEffect(() => {
        return () => {
            if (debounceTimeout) clearTimeout(debounceTimeout);
        };
    }, [debounceTimeout]);


    useEffect(() => {
        setText(props?.description)
        setCkId(props?.id)
    }, [props])


    return (
        <>

            <div className='d-flex justify-content-start ' >
                <button type="button" className='infoButton m-1'>
                    <img src={instruction} className='go-img' alt="instruction" />
                    <div class="gp-popover-infos">
                        <ul style={{ listStyleType: 'circle' }}>
                            <li>
                                Add the Title info as per the project plan.
                            </li>
                            <li>
                                This section is auto-saved
                            </li>
                            <li>
                                Character limit is 300
                            </li>
                        </ul>
                    </div>
                </button>
                <p className='m-2 d-flex justify-content-between' style={{ color: 'red', backgroundColor: 'yellow' }}>Auto&nbsp;save <img className='ms-2' style={{ height: '15px' }} src={tick} /></p>

            </div>

            <div class="gp-preview-slide gp-preview-slide-banner">

                <img class="gp-slider-banner-img" src={props?.slide}
                    alt="banner" />
                <h2 class="gp-slide-banner-heading">{sector?.name} 
                    {/* Market/Sector, Region, {ProjectData?.data?.study_from}-{ProjectData?.data?.study_to} */}
                    </h2>
                {/* <p class="gp-slide-banner-desc">{props?.ProjectName} is Driving/Experiencing Transformational Growth (By, Due to,
                    etc) {sector?.name}</p> */}
                <ul class="gp-slide-banner-contributor">
                    <li>Lead Analyst Name | Contributors: Analyst Name</li>
                    <li>{ProjectData?.data?.study_from}-{ProjectData?.data?.study_to} | Publication Date</li>
                </ul>
                <p class="gp-banner-rights mt-4" style={{ top: "75%" }}>
                    {
                        ckId ? <CkEditorBase onChange={handleTextChange} data={text} tocId={ckId} editorId={`toc-${ckId}`} Limits={[50, 300, true]} /> : null
                    }
                </p>

            </div>
            {/* <p className='m-2 d-flex justify-content-between' style={{ position: 'fixed', color: 'red' }}>Auto&nbsp;save <img className='ms-2' style={{ height: '15px' }} src={tick} /></p> */}

        </>
    )
}

export default IndexSlide

