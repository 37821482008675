import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import axios from "axios";
import { toast } from 'react-toastify';
import { useCreateCloneTocMutation, useDeleteCloneMutation, useGetProjectDetailsQuery, usePostTocDataMutation, useUpdateIsApplicableMutation, useUpdateTocIsSavedMutation, useReCallMetricMutation } from '../../redux/slices/ProjectPageAPISlice';
import SSOLoader from '../SSOLoader';
import CkEditorBase from '../../CkEditorReact/CkEditorBase';
import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import { ProgressContext } from '../../Providers/ProgressProvider';
import { useParams } from 'react-router-dom';
import Footer from './Footer';
import instruction from "../../images/instruction.svg";
import useHideUnimportantErrors from '../../hooks/useHideUnimportantError';
import tick from '../../images/tick.svg';
import noTick from '../../images/no.png';

function StrategicImperative(props) {
    const { isProjectEditable } = useContext(ProjectStatusContext)
    const { handleSectionSave } = useContext(ProgressContext)
    const [ReCallMetric] = useReCallMetricMutation();
    const { projectId } = useParams()
    const [loader, setLoader] = useState(false)
    const [postTocData] = usePostTocDataMutation();
    const [updateTocIsSaved] = useUpdateTocIsSavedMutation()
    const [headerIsEdit, setHeaderIsEdit] = useState(false);
    const [header, setHeader] = useState("");
    const [ckId, setCkId] = useState(null);
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const { data: ProjectData, isLoading: isProjectDataLoading, isSuccess: isProjectDataSuccess } = useGetProjectDetailsQuery({ projectId: props?.go_in_id });
    const [Industry, setIndustry] = useState('')
    const getIndustryData = async (id) => {
        try {
            var res = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/getindustry/`);
            setIndustry(res?.data?.data?.filter(ele => ele.id == id)[0]?.name || "")
        } catch (error) {
            console.log(error);
        }
    }
    const [sector, setSector] = useState({})
    const getSectorData = async (id) => {
        try {
            var res = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/getsectorbyid/${id}`);
            setSector(res?.data?.data?.[0] || {})
        } catch (error) {
            console.log(error);
        }
    }
    const handleSaveToc = async () => {
        try {
            setLoader(true)
            await postTocData({
                projectId: props?.go_in_id,
                propsId: props?.id,
                body: {
                    "userid": getUserDetailsFromLocalStorage()?.user_id,
                    "description": props?.description,
                    "name": header,
                }
            })
            setHeaderIsEdit(false)
            setLoader(false)
            toast.success('Saved Successfully')
        } catch (error) {
            console.log(error);
            setLoader(false)
            toast.error("Something went wrong")
        }
    }

    useEffect(() => {
        if (props?.go_in_id) {
            setHeader(props?.name)
            getStrategicImperative()
        }
    }, [props])
    const rowPositions = useMemo(() => {
        return [
            { isFirst: true, isMiddle: false, isLast: false },
            { isFirst: false, isMiddle: true, isLast: false },
            { isFirst: false, isMiddle: false, isLast: true }
        ]
    }, [])
    const initialState = {
        userid: getUserDetailsFromLocalStorage()?.user_id,
        industryid: getUserDetailsFromLocalStorage()?.user_id,
        strategic: [
            { name: "", why: "", perspective: "", sort_by: 1 },
            { name: "", why: "", perspective: "", sort_by: 2 },
            { name: "", why: "", perspective: "", sort_by: 3 }
        ],
        tocid: props.id
    }
    const [formData, setFormData] = useState(initialState)
    const [reqErrMsg, setReqErrMsg] = useState(false)

    const handleInputChange = (event, index) => {
        setReqErrMsg(false)
        const { name, value } = event.target;
        const updatedStrategic = [...formData.strategic];
        updatedStrategic[index][name] = value;
        setFormData({
            ...formData,
            strategic: updatedStrategic
        });
    };

    const handleCKEditorChange = (name, value, index) => {
        console.log(name, value);

        setReqErrMsg(false)
        let strippedText = value.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ');
        const characterCount = strippedText.length;
        // const wordCount = strippedText.split(/\s+/).filter(word => word.length > 0).length;


        if (characterCount < 500) {
            const updatedStrategic = [...formData.strategic];
            updatedStrategic[index][name] = value;
            setFormData({
                ...formData,
                strategic: updatedStrategic
            });
            if (debounceTimeout) clearTimeout(debounceTimeout);
            const timeout = setTimeout(() => {

                let secondaryPostData = {
                    type: 'strategic_imperative',
                    column:
                        name == 'why'
                            ? 'strategic_imperative_why'
                            : 'strategic_imperative_perspective',
                    description: value,
                }
                try {
                    console.log(formData);

                    if (formData.strategic[index]?.id) {
                        async function apiCall() {
                            try {
                                let response2 = await axios.post(
                                    `${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/${window?.location?.href.split('/').pop()}/ckeditorupdate/${formData.strategic[index]?.id}`,
                                    secondaryPostData
                                )
                                toast.success('Successfully Saved')
                                console.log(response2);
                            } catch (error) {
                                console.log(error);

                            }
                        }
                        apiCall()
                    }
                } catch (error) {
                    console.log(error)
                }

            }, 10000);

            setDebounceTimeout(timeout);
        } else {
            toast.error("Only 500 Characters allowed")
        }

    };

    const getStrategicImperative = async () => {
        try {
            var res = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${projectId}/strategicimperative/${props.id}`)
            if (Object.keys(res?.data?.data).length != 0) {
                const transformedData = transformData(res?.data?.data);
                setFormData({
                    ...formData,
                    strategic: transformedData?.strategic
                });

            }
            setCkId(props.id)

        } catch (error) {
            console.log(error);
        }
    }



    const transformData = (input) => {
        return {
            strategic: input.map(item => ({
                name: item.name,
                why: item.strategic_imperative_why,
                perspective: item.strategic_imperative_perspective,
                id: item.id,
                sort_by: item.sort_by
            })).sort((a, b) => a.sort_by - b.sort_by)
        };
    };



    const handleSave = async () => {
        const projectid = props?.go_in_id
        function checkLength(arr) {
            for (let i = 0; i < formData.strategic.length; i++) {
                if (arr[i].name.length > 1 && arr[i].why.length > 1 && arr[i].perspective.length > 1) {
                    return true
                } else {
                    return false
                }
            }
        }

        try {
            if (checkLength(formData.strategic)) {
                let response = await axios.post(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${projectid}/strategicimperative`, formData,)
                if (response?.data?.msg == "Successfully Saved") {
                    getStrategicImperative()

                    handleSectionSave(props.id)
                    updateTocIsSaved({ projectId: projectid, tocId: props.id, isSaved: 1 })
                    toast.success('Successfully Saved')
                    ReCallMetric()

                } else {
                    toast.error('Something went wrong!!Please try again later')
                }

            } else {
                setReqErrMsg(true)
            }
        } catch (error) {
            console.log(error)
            toast.error('Something went wrong!!Please try again later')

        }
    }

    useEffect(() => {
        if (ProjectData?.data) {
            getIndustryData(ProjectData?.data?.industry)
            getSectorData(ProjectData?.data?.sector_id)
        }
    }, [ProjectData])


    const [createCloneToc, {
        isLoading: isCreateCloneLoading,
    }] = useCreateCloneTocMutation()
    const [deleteClone, { isLoading: isCloneDeleteLoading }] = useDeleteCloneMutation();
    const [updateIsApplicable, { isLoading: isUpdateApplicableLoading }] = useUpdateIsApplicableMutation()



    const handleCreateClone = async () => {
        try {
            createCloneToc({ projectId: projectId, tocId: props.id }).then((res) => toast.success("Clone created"))

        } catch (error) {
            console.log(error)
            toast.error("Something went wrong")
        }
    }

    const handleDeleteClone = async () => {
        try {
            deleteClone({
                projectId: projectId,
                tocId: props.id
            })
            toast.success("Clone successfully deleted")
        } catch (error) {
            console.log(error)
            toast.error("Something went wrong")
        }


    }
    const [isApplicable, setIsApplicable] = useState(props?.isApplicable)
    const handleUpdateIsApplicable = async (crtIsApplicable) => {
        try {
            updateIsApplicable({
                projectId: projectId,
                tocId: props.id,
                isApplicable: crtIsApplicable
            })
        } catch (error) {
            console.log(error);
            toast.error("Something went wrong")
        }
    }

    const handleToggle = () => {
        setIsApplicable(1 - isApplicable)
        handleUpdateIsApplicable(1 - isApplicable)
    };

    useEffect(() => {
        return () => {
            if (debounceTimeout) clearTimeout(debounceTimeout);
        };
    }, [debounceTimeout]);

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState({ title: '', text: '', action: null });

    const handleConfirmation = () => {
        if (dialogContent.action === 'delete') {
            handleDeleteClone();
            // Perform the delete action here
        }
        else if (dialogContent.action === 'clone') {
            handleCreateClone()
        }
        setOpenDialog(false);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const onDelete = () => {
        setDialogContent({
            title: 'Confirm Deletion',
            text: 'Are you sure you want to delete this section?',
            action: 'delete',
        });
        setOpenDialog(true);
    }
    const onCreateClone = () => {
        setDialogContent({
            title: 'Confirm Clone creation',
            text: 'Are you sure you want to clone this section?',
            action: 'clone',
        });
        setOpenDialog(true);
    }
    useHideUnimportantErrors();
    return (
        <>
            {loader ? <SSOLoader /> : null}
            <div class="w-100 h-100 flex-grow-1 position-relative gp-editor">
                <div class="w-100 h-100 gp-imparativesSection gp-editing-content">
                    <div class="h-auto gp-imparatives-header">
                        <p> The Impact of the Top 3 Strategic Imperatives on the {sector?.name} Industry</p>
                        <div style={{ width: '40%' }}></div>

                        <div style={{ backgroundColor: 'yellow' }}>
                            <p className='m-2 d-flex justify-content-end ' style={{ color: 'red' }}>Auto&nbsp;save <img className='ms-2 mt-2' style={{ height: '15px' }} src={ formData.strategic[1]?.id ? tick:noTick} /></p>
                        </div>



                        <button type="button">
                            <img src={instruction} className='go-img' alt="instruction" />
                            <div class="gp-popover-info">
                                <ul style={{ listStyleType: 'circle' }}>
                                    <li>
                                        Analyst picks the top 3 strategic imperatives from the 8 imperatives.
                                    </li>
                                    <li>
                                        The imperatives will be prepopulated from which the analyst can chose the top 3.In future the 3 imperatives can expand, but for now it will be 3
                                    </li>
                                    <li>
                                        Auto save is not available,After completing click on save to prevent data-loss
                                    </li>
                                </ul>


                            </div>
                        </button>
                    </div>
                    <div class="gp-imparatives-body">
                        <div class="gp-strategy">
                            <div class="gp-label">
                                <span>SI8</span>
                            </div>
                            <div class="wrapper">
                                <div class="body">
                                    <input type="text" data-lpignore="true" autoComplete='off' disabled={!isProjectEditable} value={formData.strategic[0]?.name} name="name" onInput={(event) => handleInputChange(event, 0)} placeholder="Enter Top 1 Strategic Imperatives" />
                                </div>
                                <div class="body">
                                    <input type="text" data-lpignore="true" autoComplete='off' disabled={!isProjectEditable} placeholder="Enter Top 2 Strategic Imperatives" value={formData.strategic[1]?.name} name="name" onInput={(event) => handleInputChange(event, 1)} />
                                </div>
                                <div class="body">
                                    <input type="text" data-lpignore="true" autoComplete='off' disabled={!isProjectEditable} placeholder="Enter Top 3 Strategic Imperatives" value={formData.strategic[2]?.name} name="name" onInput={(event) => handleInputChange(event, 2)} />
                                </div>
                            </div>
                        </div>
                        <div class="gp-strategy">
                            <div class="gp-label">
                                <span>WHY</span>
                            </div>
                            <div class="wrapper">
                                <div class="body">
                                    {
                                        ckId ? <CkEditorBase
                                            editorId={`siWhy-1-${ckId}`}
                                            onChange={(data) => handleCKEditorChange("why", data, 0)}
                                            data={formData.strategic[0]?.why}
                                            tocId={formData.strategic[0]?.id}
                                            Limits={[100, 500, true]}
                                            rowPositions={rowPositions[0]}
                                        /> : null
                                    }
                                </div>
                                <div class="body">
                                    {
                                        ckId ? <CkEditorBase
                                            editorId={`siWhy-2-${ckId}`}
                                            onChange={(data) => handleCKEditorChange("why", data, 1)}
                                            data={formData.strategic[1]?.why}
                                            tocId={formData.strategic[1]?.id}
                                            Limits={[100, 500, true]}
                                            rowPositions={rowPositions[1]}
                                        /> : null
                                    }
                                </div>
                                <div class="body">
                                    {
                                        ckId ? <CkEditorBase
                                            editorId={`siWhy-3-${ckId}`}
                                            onChange={(data) => handleCKEditorChange("why", data, 2)}
                                            data={formData.strategic[2]?.why}
                                            tocId={formData.strategic[2]?.id}
                                            Limits={[100, 500, true]}
                                            rowPositions={rowPositions[2]}
                                        /> : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div class="gp-strategy">
                            <div class="gp-label">
                                <span>FROST PERSPECTIVE</span>
                            </div>
                            <div class="wrapper">
                                <div class="body">
                                    {
                                        ckId ? <CkEditorBase
                                            editorId={`siPer-1-${ckId}`}
                                            className=""
                                            onChange={(data) => handleCKEditorChange("perspective", data, 0)}
                                            data={formData.strategic[0]?.perspective}
                                            tocId={formData.strategic[0]?.id}
                                            Limits={[100, 500, true]}
                                            rowPositions={rowPositions[0]}
                                        /> : null
                                    }
                                </div>
                                <div class="body">
                                    {
                                        ckId ? <CkEditorBase
                                            editorId={`siPer-2-${ckId}`}
                                            className=""
                                            onChange={(data) => handleCKEditorChange("perspective", data, 1)}
                                            data={formData.strategic[1]?.perspective}
                                            tocId={formData.strategic[1]?.id}
                                            Limits={[100, 500, true]}
                                            rowPositions={rowPositions[1]}
                                        /> : null
                                    }
                                </div>
                                <div class="body">
                                    {
                                        ckId ? <CkEditorBase
                                            editorId={`siPer-3-${ckId}`}
                                            className=""
                                            onChange={(data) => handleCKEditorChange("perspective", data, 2)}
                                            data={formData.strategic[2]?.perspective}
                                            tocId={formData.strategic[2]?.id}
                                            Limits={[100, 500, true]}
                                            rowPositions={rowPositions[2]} /> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {isProjectEditable && <div className="d-flex justify-content-between">
                        <span style={{ color: 'red' }}> {reqErrMsg ? "All fields are required" : null}</span>
                        <div class="gp-action-btn-part mt-1 me-4">
                            <button type="button" class="gp-btn-fill" onClick={handleSave} >Save</button>
                        </div>
                    </div>}

                </div >
            </div >
            <Footer />

        </>

    )
}

export default StrategicImperative