import React, { useState } from 'react';
import { useSaveTocThemeMutation } from '../../redux/slices/UtilityApiSlice';
import { toast } from 'react-toastify';
import './Styles.css';
import { faPenToSquare, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import editProImg from "../../images/edit.svg";
import deleteProImg from "../../images/delete.svg";
import plusProImg from "../../images/plus.svg"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TableRow = ({ item, index, onDragStart, onDragOver, onDrop, onAddRow, childIndex = null,parentIndex,onDelete }) => {
    const [saveTocTheme] = useSaveTocThemeMutation();
    const [isEditing, setIsEditing] = useState(false);
    const [rowData, setRowData] = useState(item);
    const [title, setTitle] = useState(item.title);
    const [isHovered, setIsHovered] = useState(false);
    
    const isParent = item.children && item.parent === "Yes";

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = async(tocId) => {
        if(tocId === null) return;
        setIsEditing(false);
        await saveTocTheme({
            status: 'edit',
            tocId: tocId,
            body: {
                title: title
            }
        }).catch((err) => {
            console.error(err);
        });
    };

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

    const onChangeHandler = async(e) => {
        const { name, checked } = e.target;
        if (!item.id) {
            toast.error('Something went wrong');
            return;
        }

        setRowData((prevData) => ({
            ...prevData,
            [name]: checked ? 1 : 0,
        }));
        
        await saveTocTheme({
            status: 'edit',
            tocId: rowData.id,
            body: {
                [name]: checked ? '1' : '0',
            }
        }).catch((err) => {
            console.error(err);
        });
    };
    const [isDisabled,setIsDisabled]=useState(true)
    return (
        <>
            <tr
                draggable
                onDragStart={() => onDragStart(index, childIndex)}
                onDragOver={onDragOver}
                onDrop={() => onDrop(index, childIndex)}
                className='table-row grabbable'
                
            >
                <td 
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    className={isParent ? 'parent-row' : 'child-row'}
                    style={!isParent ? { paddingLeft: '20px' } : {}}
                >
                    {index}
                
                </td>
                <td>
                    {isEditing ? (
                        <input
                            type="text"
                            value={title}
                            onChange={handleTitleChange}
                            onBlur={() => handleSaveClick(item.id)}
                            autoFocus
                        />
                    ) : (
                        <div className='d-flex justify-content-between'>
                            {title}
                        </div>
                    )}
                </td>
                <td>
                    <div className='d-flex justify-content-between'>
                    <img src={editProImg} className='cursor-pointer' style={{ width: "14px" }} onClick={handleEditClick}/>  
                    <img src={plusProImg} className='cursor-pointer' style={{ width: "14px" }} onClick={() => onAddRow(index)}/>
                    <img src={deleteProImg} className='cursor-pointer' style={{ width: "14px" }} onClick={() =>isDisabled?undefined: onDelete(item.id)}/>

                  </div>
                </td>
                <td>
                    <input
                        type='checkbox'
                        name="isView"
                        checked={rowData.isView}
                        onChange={onChangeHandler}
                    />
                </td>
                <td>
                    <input
                        type='checkbox'
                        name="isApplicable"
                        checked={rowData.isApplicable}
                        onChange={onChangeHandler}
                    />
                </td>
                <td>
                    <input
                        type='checkbox'
                        name="isClone"
                        checked={rowData.isClone}
                        onChange={onChangeHandler}
                    />
                </td>
                <td>
                    <input
                        type='checkbox'
                        name="isMandatory"
                        checked={rowData.isMandatory}
                        onChange={onChangeHandler}
                    />
                </td>
                <td>
                    <input
                        type='checkbox'
                        name="status"
                        checked={rowData.status}
                        onChange={onChangeHandler}
                    />
                </td>
            </tr>
            {item.children && item.children.map((child, idx) => (
                <TableRow
                    key={child.id || `temp-${Date.now()}`}
                    item={child}
                    index={`${index}.${idx + 1}`}
                    onDragStart={onDragStart}
                    onDragOver={onDragOver}
                    onDrop={onDrop}
                    onAddRow={onAddRow}
                    childIndex={idx}
                    parentIndex={typeof index === 'number' ? index - 1 : parseInt(index.split('.')[0]) - 1}
                    onDelete={onDelete}
                />
            ))}
        </>
    );
};

export default TableRow;