import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImpactButton from './ImpactButton';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useContext, useState } from 'react';
import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider';
import deleteImg from "../../images/delete.svg";
import high from "../../images/high.png";
import med from "../../images/medium.png";
import low from "../../images/low.png";

const GrowthMetricCard = ({ metric, updateMetricName, deleteMetric, updateMetricImpact, index }) => {
    const { isProjectEditable } = useContext(ProjectStatusContext)
    const [editMod, setEditMod] = useState(false);
    return <>
        <div class="gp-metric-card">
            <div class="header">

                <input
                    disabled={!isProjectEditable}
                    type="text"
                    style={{ width: "90%" }}
                    value={metric.field}
                    onChange={(e) => updateMetricName(index, 'field', e.target.value)}
                />
                {isProjectEditable && (
                    <button type="button" className="mx-2" onClick={() => deleteMetric(metric.id)}>
                        <img src={deleteImg} alt="delete" className="go-img" />
                    </button>
                )}
            </div>
            <div class="body">
                <div class="gp-metric-row">
                    <input
                        disabled={!isProjectEditable}
                        type="text"
                        value={metric.field_value}
                        onChange={(e) => updateMetricName(index, 'field_value', e.target.value)}
                    />
                </div>
                <div class="gp-metric-row">
                    <input
                        disabled={!isProjectEditable}
                        type="text"
                        value={metric.deafultMap}
                        onChange={(e) => updateMetricName(index, 'deafultMap', e.target.value)}
                    />
                </div>
                <div class="gp-metric-row">
                    {/* <button type="button" class="gp-high">High</button>
                    <button type="button" class="gp-med">Medium</button> */}
                    <button disabled={!isProjectEditable} type="button" className={`gp-high ${metric.impact === "high" ? 'active' : ''}`} onClick={() => updateMetricImpact(metric.id, "high")} title="High">
                        <img className="highcls" src={high} />  
                    </button>
                    <button disabled={!isProjectEditable} type="button" className={`gp-med ${metric.impact === "medium" ? 'active' : ''}`} onClick={() => updateMetricImpact(metric.id, "medium")} title="Medium">
                        <img className="medcls" src={med} />  
                    </button>
                    <button disabled={!isProjectEditable} type="button" className={`gp-low ${metric.impact === "low" ? 'active' : ''}`} onClick={() => updateMetricImpact(metric.id, "low")} title="Low">
                        <img className="lowcls" src={low} />  
                    </button>
                </div>
            </div>
        </div>

    </>
};

export default GrowthMetricCard